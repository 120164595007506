import { Box, Button, Checkbox, Grid } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "../../../../table/Table";
import { useDispatch, useSelector } from "react-redux";
import { Ring } from "@uiball/loaders";
import allData from "../../../../../../../Services/Data";
import { setUser } from "../../../../../../../Services/redux/reducers/userMenuReducer";

const SubscriptionShow = (props) => {
  const userDetails = props.userDetails;
  const setIsAdding = props.setIsAdding;
  const userAll = useSelector((state) => state.menu.userAll);
  const dispatch = useDispatch();

  function onRemoveSubs() {
    const par = {
      subid: _subscriptions
        .filter((x) => x.confirmed === true)
        .map((id) => id.addressNb)?.[0],
      enduserid: props.rowDetails.id,
    };
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "userupdate",
        {
          type: "removesubscription",
          removesubscription: par,
        },
        "adminendusr/v1/enduser"
      )
      .then(
        (response) => {
          setTimeout(() => {
            props.setOpenSnackBar({
              open: true,
              message: "Subscription removed successfully!",
              type: "success",
            });
            props.getdetails(props.rowDetails.id);
          }, 2000);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          props.setOpenSnackBar({
            open: true,
            message: error,
            type: "error",
          });
        }
      );
  }

  let i;
  let _subscriptions = [];

  for (i = 0; i < userDetails?.length; i++) {
    _subscriptions[i] = {
      id: Math.random(),
      addressNb: userDetails?.[i]?.[0]?.ABAN8,
      fullName: userDetails?.[i]?.[0]?.ABALPH,
      installation: userDetails?.[i]?.[0]?.ABALKY,
      branch: userDetails?.[i]?.[0]?.ABTXCT,
      meter: userDetails?.[i]?.[0]?.ABTX2,
      address: userDetails?.[i]?.[0]?.ALADD4,
      confirmed: false,
    };
  }

  function handleConfirmChange(clickedRow) {
    const updatedData = _subscriptions.map((x) => {
      if (x.addressNb === clickedRow.addressNb) {
        x.confirmed = !x.confirmed;
      }
      return x;
    });
    _subscriptions = updatedData;
  }

  const detailsDataColumns = [
    {
      field: "confirmed",
      headerName: "Confirmed",
      renderCell: (params) => (
        <Grid
          sx={{
            ".css-112ysrj-MuiButtonBase-root-MuiCheckbox-root": {
              color: "black !important",
            },
          }}
        >
          <Checkbox
            checked={params.rows?.confirmed}
            onChange={() => handleConfirmChange(params.row)}
          />
        </Grid>
      ),
    },
    { field: "addressNb", headerName: "Address Number", width: 100 },
    { field: "fullName", headerName: "Full Name", width: 100 },
    { field: "installation", headerName: "Installation", width: 100 },
    { field: "branch", headerName: "Branch", width: 100 },
    { field: "meter", headerName: "Meter", width: 100 },
    { field: "address", headerName: "Address", width: 125 },
  ];

  return (
    <Box>
      {!props.isLoadingDetails &&
        !props.httpDetailsError &&
        userDetails?.length > 0 && (
          <Box>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item xs={1}>
                <Button onClick={() => setIsAdding(true)}>
                  <AddIcon sx={{ color: "#064987" }} />
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  onClick={() => {
                    onRemoveSubs();
                  }}
                >
                  <DeleteIcon sx={{ color: "#064987" }} />
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Table columns={detailsDataColumns} _newData={_subscriptions} />
            </Grid>
          </Box>
        )}
      {props.isLoadingDetails && !props.httpDetailsError && (
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Ring size={40} lineWeight={5} speed={2} color="#064987" />
        </Grid>
      )}
      {!props.isLoadingDetails &&
        !props.httpDetailsError &&
        userDetails?.length <= 0 && (
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
                fontSize: "1.5rem",
                marginY: "3%",
              }}
            >
              No Records Yet!
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                xs={4}
                sx={{
                  backgroundColor: "#064987",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => setIsAdding(true)}
                  sx={{ color: "white" }}
                >
                  ADD New Subscription
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      {!props.isLoadingDetails && props.httpDetailsError && (
        <Grid>{props.httpDetailsError}</Grid>
      )}
    </Box>
  );
};

export default SubscriptionShow;
