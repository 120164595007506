import { Box, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import Table from "../../../table/Table";

const DetailsPopUp = ({ detailsData }) => {
  // console.log(detailsData);
  var i;
  var _detailsData = [];

  const detailsDataColumns = [
    {
      field: "text",
      headerName: "Avatar",
      width: 60,
      renderCell: (params) => (
        <img
          src={params.value ? params.value : "./avatar.jpg"}
          style={{ width: "100%" }}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => (
        <span
          style={{
            color:
              (params.value === "New" && "green") ||
              (params.value === "Under Process" && "#939300") ||
              (params.value === "Closed" && "red"),
          }}
        >
          {params.value}
        </span>
      ),
    },
    { field: "assigned", headerName: "Assigned", width: 100 },
    { field: "assignee", headerName: "Assignee", width: 100 },
    { field: "id", headerName: "Ticket ID", width: 100 },
    { field: "creatingdate", headerName: "Creating Date", width: 150 },
    { field: "creatingtime", headerName: "Creating Time", width: 150 },
    { field: "subid", headerName: "Sub ID", width: 100 },
    { field: "issue", headerName: "Issue", width: 200 },
    { field: "jdecaseid", headerName: "JDE Case ID", width: 100 },
    { field: "lastupdate", headerName: "Last Update", width: 200 },
    { field: "review", headerName: "Review", width: 100 },

    // { field: "userid", headerName: "User ID", width: 100 },
    { field: "title", headerName: "Title", width: 100 },
    { field: "fullname", headerName: "Full Name", width: 200 },
    // { field: "username", headerName: "Username", width: 150 },
    { field: "address1", headerName: "Address 1", width: 150 },
    { field: "address2", headerName: "Address 2", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phonecode", headerName: "Phone Code", width: 100 },
    { field: "phonepref", headerName: "Phone Prefix", width: 100 },
    { field: "phonenb", headerName: "Phone Number", width: 200 },
  ];

  for (i = 0; i < detailsData?.length; i++) {
    const numAscending = [...detailsData].sort(
      (a, b) => b.AHLINEID - a.AHLINEID
    );

    const list = numAscending?.[i];
    const status =
      (list?.AHSTATUS === "N" && "New") ||
      (list?.AHSTATUS === "U" && "Under Process") ||
      (list?.AHSTATUS === "C" && "Closed");

    const review =
      (list?.AHREVIEW === "N" && "No") || (list?.AHREVIEW === "Y" && "Yes");

    const assigned =
      (list?.AHASSIGNED === "N" && "No") || (list?.AHASSIGNED === "Y" && "Yes");

    _detailsData[i] = {
      id: list?.AHLINEID,
      creatingdate: moment(list?.AHCREATEDATE).format("DD-MM-YYYY"),
      creatingtime: list?.AHCREATETIME,
      subid: list?.AHSUBID,
      issue: list?.AHISSUE,
      jdecaseid: list?.AHJDECASEID > 0 ? list?.AHJDECASEID : "---",
      lastupdate: moment(list?.AHLASTUPDATE).format("DD-MM-YYYY hh:mm a"),
      review: review,
      assigned: assigned,
      assignee: list?.AHASSIGNEE > 0 ? list?.AHASSIGNEE : "---",
      status: status,

      //   userid: list?.AAUSERID,
      //   username: list?.AAUSERNAME,
      address1: list?.ABADDRESS1,
      address2: list?.ABADDRESS2,
      email: list?.ABEMAIL,
      fullname: list?.ABFULLNAME,
      phonecode: list?.ABPHONECODE,
      phonenb: list?.ABPHONENBR,
      phonepref: list?.ABPHONEPREFIX,
      text: list?.ABTEXT1,
      title: list?.ABTITLE,
    };
  }

  return (
    <Box sx={{ color: "black" }}>
      <Grid>
        <Table columns={detailsDataColumns} _newData={_detailsData} />
      </Grid>
    </Box>
  );
};

export default DetailsPopUp;
