import React, { useState } from "react";
import "../../Message.scss";
import { Grid } from "@mui/material";
import AlertDialog from "../../../../../components/AlertDialog";

const MediaImageType = ({ message }) => {
  // state to open and close the image
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const alertSx = {
    backgroundColor: "#bfbfbf !important",
    minWidth: "auto !important",
    maxWidth: "auto !important",
    width: "auto",
    height: "auto",
  };

  const sx = {
    display: "flex",
    justifyContent: "center",
  };

  return (
    <Grid>
      <img
        src={message?.message?.media?.link}
        alt={message?.message?.media?.link}
        style={{
          width: "auto",
          height: "300px",
          borderRadius: "14px 14px 14px 0px",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      />
      {open && (
        <AlertDialog
          open={open}
          sx={sx}
          alertSx={alertSx}
          handleClose={handleClose}
          buttonTitle={""}
          content={
            <img
              src={message?.message?.media?.link}
              alt={message?.message?.media?.link}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "14px 14px 14px 0px",
              }}
              onClick={() => setOpen(true)}
            />
          }
        />
      )}
    </Grid>
  );
};

export default MediaImageType;
