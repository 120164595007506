import React from "react";
import classNames from "classnames";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import "./Message.scss";
import TypeText from "./types/Text";
import TypeComposite from "./types/Composite";
import TypeMedia from "./types/Media";
import TypeLocation from "./types/Location";

const Message = ({ userAll, message }) => {
  // check if its my message
  const isMyMessage = message?.sender?.senderId === userAll.userid && true;
  // for styling each type of messages
  const messageClass = classNames("message-row", {
    "you-message": isMyMessage,
    "other-message": !isMyMessage,
    "info-message": message?.message?.type === "chatinfo",
    "feedback-message": message?.message?.type === "feedback",
  });
  // to get the image avatar for each message
  const imageThumbnail = isMyMessage ? null : (
    <img src={"/avatar.jpg"} alt={message?.imageAlt} />
  );
  // set the status of the message
  const status =
    (message?.message?.status === "sent" && <DoneIcon />) ||
    (message?.message?.status === "delivered" && <DoneAllIcon />) ||
    (message?.message?.status === "seen" && (
      <CheckCircleIcon sx={{ color: "green" }} />
    ));
  // get the time of each message
  const messageTime = moment(
    moment(new Date()).format("YYYY-MM-DD") +
      " " +
      message?.message?.messageTime
  ).format("HH:mm A");

  return (
    <div className={messageClass} style={{ marginTop: "1%" }}>
      {message?.message?.type === "chatinfo" ||
      message?.message?.type === "chatstart" ? (
        <div>{message?.message?.chatinfo}</div>
      ) : (
        <div className="message-content">
          {imageThumbnail}
          {message?.message.type === "text" && <TypeText message={message} />}
          {(message?.message?.type === "composite" ||
            message?.message?.type === "composite1") && (
            <TypeComposite message={message} type={message?.message?.type} />
          )}
          {message?.message?.type === "media" && (
            <TypeMedia message={message} />
          )}
          {message?.message?.type === "location" && (
            <div
              style={{
                backgroundColor: "#d1d1d1",
                borderRadius: "7.5px 7.5px 0px 7.5px",
                padding: "4%",
              }}
            >
              <TypeLocation message={message} />
            </div>
          )}
          <div
            className="message-time"
            style={{ display: "flex", alignItems: "center" }}
          >
            {messageTime} {!isMyMessage ? "" : status}
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
