/*global google*/
import React from "react";

import {
  GoogleMap,
  DirectionsRenderer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Grid } from "@mui/material";

//use routes3.json for multiple coordinates inarray
//use routes2.json for only 2 coordinates in array
// import routes from "./routes3.json";

function useLoading() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAxn4MYHO8iJSrFdWS8Fdx_tLp1VRrjmnw",
  });
  return isLoaded;
}

const defaultLocation = { lat: 0, lng: 0 };
let directionsService;

class MapPath extends React.Component {
  state = {
    directions: null,
    bounds: null,
  };

  onMapLoad = (map) => {
    directionsService = new google.maps.DirectionsService();

    const routesCopy = this.props?.data?.map((route) => {
      return {
        location: { lat: route?.location?.lat, lng: route?.location?.lng },
        stopover: true,
      };
    });

    const origin =
      routesCopy?.length === 1
        ? new google.maps.LatLng(
            routesCopy[0].location.lat,
            routesCopy[0].location.lng
          )
        : routesCopy.shift().location;
    const destination =
      routesCopy?.length === 1
        ? new google.maps.LatLng(
            routesCopy[0].location.lat,
            routesCopy[0].location.lng
          )
        : routesCopy.pop().location;
    //put all the remaining coordinates in waypoints after(pop and shift)
    const waypoints = routesCopy;

    //call getDirection function
    this.getDirection(origin, destination, waypoints);
  };

  //function that is calling the directions service
  getDirection = (origin, destination, waypoints) => {
    //this will check if there is a waypoint meaning the array  has 3 or more coordinates
    waypoints?.length >= 1
      ? directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            waypoints: waypoints,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              //changing the state of directions to the result of direction service
              this.setState({
                directions: result,
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        )
      : directionsService.route(
          {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              //changing the state of directions to the result of direction service
              this.setState({
                directions: result,
              });
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
  };

  render() {
    return useLoading ? (
      <Grid>
        <GoogleMap
          center={defaultLocation}
          zoom={3}
          onLoad={(map) => this.onMapLoad(map)}
          mapContainerStyle={{ height: "400px", width: "100%" }}
        >
          {this.state.directions !== null && (
            <DirectionsRenderer directions={this.state.directions} />
          )}
        </GoogleMap>
      </Grid>
    ) : (
      <></>
    );
  }
}

export default MapPath;
