import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { LoadScript } from "@react-google-maps/api";
import MapPath from "../../../components/LocationMap/MapPath";

const GeneralPath = (props) => {
  const pathData = props.pathData?.data?.crpath;

  var i;
  var _pathData = [];

  for (i = 0; i < pathData?.length; i++) {
    _pathData[i] = {
      location: {
        id: Math.random(),
        lat: Number(pathData?.[i]?.lat),
        lng: Number(pathData?.[i]?.lon),
      },
    };
  }

  return (
    <Box>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-tu3qo6-MuiModal-root-MuiDialog-root, .css-1qxadfk-MuiPaper-root-MuiDialog-paper, .css-sd33hq":
            {
              backgroundColor: "#bfbfbf !important",
              minWidth: "200px !important",
              maxWidth: "675px !important",
              width: "100% !important",
            },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          display={"flex"}
          justifyContent={"center"}
        >
          General Path
        </DialogTitle>
        <DialogContent>
          <Grid id="alert-dialog-description">
            {pathData?.length > 0 ? (
              <Grid>
                {window.google === undefined ? (
                  <LoadScript
                    googleMapsApiKey={"AIzaSyAxn4MYHO8iJSrFdWS8Fdx_tLp1VRrjmnw"}
                  >
                    <MapPath data={_pathData} />
                  </LoadScript>
                ) : (
                  <MapPath data={_pathData} />
                )}
              </Grid>
            ) : (
              <Grid>No Path To Show!</Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* <MIPopUp
        open={props.openPop}
        detailsData={props.moreDetailsData}
        handleClose={props.handleMIClose}
        isMap={props.isMap}
        openPath={props.openPath}
        readingData={props._newData}
      /> */}
    </Box>
  );
};

export default GeneralPath;
