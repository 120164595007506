import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, TextField } from "@mui/material";
import Table from "../../table/Table";
import MIPopUp from "../MrbcItems/map&imagePopUp/MIPopup";

function AddUserDialog(props) {
  const data = props.detailsData;

  return (
    <Box>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          ".css-tu3qo6-MuiModal-root-MuiDialog-root, .css-1qxadfk-MuiPaper-root-MuiDialog-paper, .css-sd33hq":
            {
              backgroundColor: "#bfbfbf !important",
              minWidth: "200px !important",
              maxWidth: "675px !important",
            },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          display={"flex"}
          justifyContent={"center"}
        >
          {!props.openGeneralPath ? "More Details" : "General Path"}
        </DialogTitle>
        <DialogContent>
          <Grid id="alert-dialog-description">
            <Grid
              margin={"2%"}
              sx={{
                ".MuiInputLabel-root": { color: "#064987 !important" },
                ".css-9425fu-MuiOutlinedInput-notchedOutline,.css-izq3bt.Mui-disabled, .MuiOutlinedInput-notchedOutline":
                  {
                    borderColor: "#6d6d6d !important",
                  },
                ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled, .css-izq3bt, .css-h4os0j.Mui-disabled":
                  {
                    WebkitTextFillColor: "#064987 !important",
                    fontWeight: "bold",
                    fontFamily: "inherit",
                    fontSize: "1.2em",
                  },
              }}
            >
              <Grid container>
                <Grid item xs={5.5}>
                  <TextField
                    fullWidth
                    id="customer"
                    label="Customer"
                    value={data.customer ? data.customer : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5.5}>
                  <TextField
                    fullWidth
                    id="village"
                    label="Village"
                    value={data.villageName}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container marginY={"3%"}>
                <Grid item xs={11 / 4}>
                  <TextField
                    fullWidth
                    id="emission"
                    label="Emission"
                    value={
                      data.period + "-" + data.year
                        ? data.period + "-" + data.year
                        : ""
                    }
                    disabled
                  />
                </Grid>
                <Grid item xs={1 / 3}></Grid>
                <Grid item xs={11 / 4}>
                  <TextField
                    fullWidth
                    id="installation"
                    label="Installation"
                    value={data.installation ? data.installation : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={1 / 3}></Grid>
                <Grid item xs={11 / 4}>
                  <TextField
                    fullWidth
                    id="branch"
                    label="Branch"
                    value={data.branch ? data.branch : ""}
                    disabled
                  />
                </Grid>
                <Grid item xs={1 / 3}></Grid>
                <Grid item xs={11 / 4}>
                  <TextField
                    fullWidth
                    id="meter"
                    label="Meter SN"
                    value={data.meter ? data.meter : ""}
                    disabled
                  />
                </Grid>
              </Grid>
              {props.type === "mr" && (
                <Grid container>
                  <Grid item xs={11 / 4}>
                    <TextField
                      fullWidth
                      id="obs"
                      label="OBS"
                      value={data.obs ? data.obs : ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={1 / 3}></Grid>
                  <Grid item xs={11 / 4}>
                    <TextField
                      fullWidth
                      id="obsdesc"
                      label="OBS Description"
                      value={data.obsdesc ? data.obsdesc : ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={1 / 3}></Grid>
                  <Grid item xs={11 / 4}>
                    <TextField
                      fullWidth
                      id="currentbas"
                      label="Current Bas"
                      value={data.currentbas ? data.currentbas : ""}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={1 / 3}></Grid>
                  <Grid item xs={11 / 4}>
                    <TextField
                      fullWidth
                      id="newbas"
                      label="New Bas"
                      value={data.newbas ? data.newbas : ""}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
              {props.type === "bc" && (
                <Grid>
                  <Grid container>
                    <Grid item xs={11 / 3}>
                      <TextField
                        fullWidth
                        id="sayrafaDate"
                        label="Sayrafa Date"
                        value={data.sayrafaDate ? data.sayrafaDate : ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1 / 2}></Grid>
                    <Grid item xs={11 / 3}>
                      <TextField
                        fullWidth
                        id="sayrafaRate"
                        label="Sayrafa Rate"
                        value={data.sayrafaRate ? data.sayrafaRate : ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1 / 2}></Grid>
                    <Grid item xs={11 / 3}>
                      <TextField
                        fullWidth
                        id="corrected"
                        label="Corrected"
                        value={data.corrected ? data.corrected : ""}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container marginY={"3%"}>
                    <Grid item xs={11 / 3}>
                      <TextField
                        fullWidth
                        id="collected"
                        label="Collected"
                        value={data.collected ? data.collected : ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1 / 2}></Grid>
                    <Grid item xs={11 / 3}>
                      <TextField
                        fullWidth
                        id="paymentType"
                        label="Payment Type"
                        value={data.paymentType ? data.paymentType : ""}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={1 / 2}></Grid>
                    <Grid item xs={11 / 3}>
                      <TextField
                        fullWidth
                        id="payment"
                        label="Payment"
                        value={data.paymentUSD ? data.paymentUSD : ""}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid sx={{ marginTop: "3%" }}>
                <Table columns={props.columns} _newData={props._newData} />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.onPathClick()}>
            Open Path
          </Button>
          <Button autoFocus onClick={props.handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <MIPopUp
        open={props.openPop}
        detailsData={props.moreDetailsData}
        handleClose={props.handleMIClose}
        isMap={props.isMap}
        openPath={props.openPath}
        readingData={props._newData}
      />
    </Box>
  );
}

export default AddUserDialog;
