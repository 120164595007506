import { Box, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MQTT from "../../../../../Services/MQTT";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import FilterTicketsComponent from "../Filter";
import BodyTicketsComponent from "./Body";
import allData from "../../../../../Services/Data";
import ChatTicketsComponent from "../chat";
import BreakEndShiftComponent from "./BreakEndShift";
import { Ring } from "@uiball/loaders";
import {
  setBreakDetails,
  setConversationNewMessages,
  setConversationHistory,
  setNewUnreadedMessages,
  setMessageList,
  setNewMessageList,
  setTicketdetails,
  setTicketReview,
  setAssignedToMe,
  setSelectedConversation,
  setCheckCase,
  setTicketHeader,
  setNotificationMessages,
} from "../../../../../Services/redux/reducers/ticketsReducer";
import moment from "moment";
import { MQTT_URL } from "../../../../../Services/GlobalVar/commonVar";
import useMqttConnect from "../../../../../Services/MQTT/MQTTConnect";
import { setUser } from "../../../../../Services/redux/reducers/userMenuReducer";

const TicketListComponent = ({ client }) => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const [isChatting, setIsChatting] = useState({
    boolean: false,
    state: {},
  });
  const dispatch = useDispatch();

  // Get message list (tickets)
  const messageList = useSelector((state) => state.ticket.messageList);
  const messageListRef = useRef();
  messageListRef.current = messageList;

  // Get Selected Conversation (selected ticket)
  const selectedConversation = useSelector(
    (state) => state.ticket.selectedConversation
  );
  const selectedConversationRef = useRef();
  selectedConversationRef.current = selectedConversation;

  // Get Unreaded Messages (unreaded messages for each ticket)
  const unreadedMessages = useSelector(
    (state) => state.ticket.unreadedMessages
  );
  const unreadedMessagesRef = useRef();
  unreadedMessagesRef.current = unreadedMessages;

  // Get Conversation messages
  const conversationMessages = useSelector(
    (state) => state.ticket.conversationMessages
  );
  const conversationMessagesRef = useRef();
  conversationMessagesRef.current = conversationMessages;

  // Get ticket details
  const ticketdetails = useSelector((state) => state.ticket.ticketdetails);
  const ticketdetailsRef = useRef();
  ticketdetailsRef.current = ticketdetails;

  // Get tickets assigned to me
  const assignedToMe = useSelector((state) => state.ticket.assignedToMe);
  const assignedToMeRef = useRef();
  assignedToMeRef.current = assignedToMe;

  var today = new Date();
  today?.setDate(today.getDate() - 14);

  const [filterTickets, setFilterTickets] = useState({
    AHLINEID: "",
    AHUSERID: "",
    AHJDECASEID: "",
    AHASSIGNED: "",
    AHASSIGNEE: "",
    AHSTATUS: "",
    fromdate: moment(today).format("YYYY-MM-DD"),
    todate: moment(new Date()).format("YYYY-MM-DD"),
  });

  function getData(x) {
    setIsLoading(true);
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "usertickets",
        "ticketlist",
        filterTickets,
        "adminendusr/v1/enduser"
      )
      .then(
        (response) => {
          dispatch(setMessageList(response?.data?.usertickets));
          setIsLoading(false);
          setHttpError("");
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
        }
      );
  }

  const messageSentPub = (ticketid, messageid, status) => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets", // /ticketsdash
        qos: 0,
        payload: JSON.stringify({
          request: "msgupdate", // spvdash001
          userid: userAll?.userid,
          ticketid: ticketid,
          msgupdate: {
            //spvdash001
            type: "statusupdate",
            messageId: messageid,
            status: status,
          },
        }),
      },
      client
    );
  };

  const newmsg = (json) => {
    if (Object.keys(selectedConversationRef.current)?.length > 0) {
      if (json?.ticketid === selectedConversationRef.current?.AHLINEID) {
        dispatch(setConversationNewMessages(json));
        if (json?.message?.type !== "chatinfo") {
          if (json?.sender?.senderId !== userAll?.userid) {
            messageSentPub(json?.ticketid, [json?.message?.messageId], "seen");
          } else {
            messageSentPub(json?.ticketid, [json?.message?.messageId], "sent");
          }
        }
      } else {
        messageSentPub(json?.ticketid, [json?.message?.messageId], "delivered");
        dispatch(
          setNewUnreadedMessages({
            ticketid: json?.ticketid,
            messages: { messageID: json?.message?.messageId },
          })
        );
      }
    }
  };

  const updateList = (json) => {
    const updateState = () => {
      const newState = messageListRef.current?.map((obj) => {
        // 👇️ if id equals 2, update country property
        if (obj?.AHLINEID === json?.AHLINEID) {
          return {
            ...obj,
            AHASSIGNED: json?.AHASSIGNED,
            AHASSIGNEE: json?.AHASSIGNEE,
            AHJDECASEID: json?.AHJDECASEID,
            AHSTATUS: json?.AHSTATUS,
          };
        }

        // 👇️ otherwise return the object as is
        return obj;
      });
      dispatch(setMessageList(newState));
    };
    updateState();
  };

  const updateTicketDetails = (json) => {
    const updateState = () => {
      const newState = {
        ...ticketdetailsRef.current,
        ticketInfo: {
          ...ticketdetailsRef.current?.ticketInfo,
          AHSTATUS: json?.AHSTATUS,
        },
      };
      dispatch(setTicketdetails(newState));
    };
    updateState();
  };

  const messagesUpdate = () => {
    client.on("message", (topic, message) => {
      var string = new TextDecoder().decode(message);
      const json = JSON.parse(string);

      switch (json?.request) {
        // // Ticket list
        // case "ticketlist":
        //   dispatch(setMessageList(json?.reply));
        //   break;

        // Ticket history
        case "tickethistory":
          dispatch(setConversationHistory(json?.reply));
          break;

        // Ticket new message
        case "newmsg":
          newmsg(json?.reply);
          if (
            json?.reply?.sender?.senderId !== userAll?.userid &&
            json?.reply?.ticketid !== selectedConversationRef?.current?.AHLINEID
          ) {
            dispatch(setNotificationMessages(json?.reply));
          }
          break;

        case "ticketmedia":
          newmsg(json?.reply);
          if (
            json?.reply?.sender?.senderId !== userAll?.userid &&
            json?.reply?.ticketid !== selectedConversationRef?.current?.AHLINEID
          ) {
            dispatch(setNotificationMessages(json?.reply));
          }
          break;

        // Message update
        case "msgupdate":
          if (
            conversationMessagesRef.current?.filter(
              (msg) => msg?.message?.type !== "chatinfo"
            )
          ) {
            if (Object.keys(selectedConversationRef.current)?.length > 0) {
              const updateState = () => {
                const newState = conversationMessagesRef.current?.map((obj) => {
                  // 👇️ if id equals 2, update country property
                  if (
                    obj.message?.messageId ===
                    json?.reply?.msgupdate?.messageId?.[0]
                  ) {
                    return {
                      ...obj,
                      message: {
                        ...obj?.message,
                        status: json?.reply?.msgupdate?.status,
                      },
                    };
                  }

                  // 👇️ otherwise return the object as is
                  return obj;
                });

                dispatch(setConversationHistory(newState));
              };

              updateState();
            }
          }

          break;

        // Ticket details
        case "ticketdetails":
          dispatch(setTicketdetails(json?.reply));
          break;

        // Ticket Review
        case "ticketreview":
          dispatch(setTicketReview(json?.reply));
          break;

        // Ticket Assign
        case "ticketassign":
          dispatch(setConversationNewMessages(json?.reply));
          break;

        case "ticketunassign":
          // dispatch(setConversationNewMessages(json?.reply));
          const removeItem = () => {
            dispatch(
              setAssignedToMe(
                assignedToMeRef.current?.filter(
                  (item) => item !== json.ticketid
                )
              )
            );
          };
          MQTT.mqttUnSub(
            {
              topic: `dspenduser/v1/tickets/chat/${json.ticketid}`,
              qos: 0,
            },
            client
          );
          removeItem();
          break;

        // Ticket Attach
        case "attachcase":
          dispatch(setConversationNewMessages(json?.reply));
          break;

        case "checkcase":
          dispatch(setCheckCase(json));
          break;

        // Ticket Leave
        case "ticketleave":
          dispatch(setConversationNewMessages(json?.reply));
          break;

        case "ticketclose":
          updateList(json?.reply?.[0]);
          break;

        case "botChat":
          dispatch(setConversationNewMessages(json?.reply));
          break;

        default:
          break;
      }

      if (topic === `${userAll?.userid}/dspenduser/v1/tickets`) {
        switch (json?.request) {
          case "assignedTicketsList":
            if (json?.reply?.length > 0) {
              json?.reply?.map((ticket) => {
                MQTT.mqttSub(
                  `dspenduser/v1/tickets/chat/${ticket?.AHLINEID}`,
                  client
                );
              });
            }
            break;
          case "ticketheader":
            dispatch(setTicketHeader(json?.reply?.[0]));
            setIsChatting({
              boolean: true,
              state: json?.reply?.[0],
            });
            break;
          default:
            break;
        }
      }

      // Ticket Update
      if (topic === "dspenduser/v1/tickets/ccagent") {
        switch (json?.request) {
          case "ticketupdate":
            switch (json?.type) {
              case "ticketnew":
                dispatch(setNewMessageList(json?.reply?.[0]));
                break;

              case "ticketassign":
                dispatch(setSelectedConversation(json?.reply?.[0]));
                if (json?.reply?.[0]?.AHASSIGNEE === userAll?.userid) {
                  MQTT.mqttSub(
                    `dspenduser/v1/tickets/chat/${json?.reply?.[0].AHLINEID}`,
                    client
                  );
                  dispatch(
                    setAssignedToMe([
                      ...assignedToMeRef.current,
                      json?.reply?.[0].AHLINEID,
                    ])
                  );
                }
                updateList(json?.reply?.[0]);
                break;

              case "ticketunassign":
                dispatch(setSelectedConversation(json?.reply?.[0]));
                updateList(json?.reply?.[0]);
                break;

              case "ticketclose":
                updateTicketDetails(json?.reply?.[0]);
                dispatch(setSelectedConversation(json?.reply?.[0]));
                break;

              case "attachcase":
                updateList(json?.reply?.[0]);
                dispatch(setSelectedConversation(json?.reply?.[0]));
                break;

              case "ticketupdatesubscription":
                dispatch(setSelectedConversation(json?.reply?.[0]));
                break;

              default:
                break;
            }
            break;
          default:
            break;
        }
      }

      switch (json?.request) {
        case "agentbreak":
          dispatch(setBreakDetails(json?.agentbreak));
          break;

        default:
          break;
      }
    });
  };

  const mqttConnect = useMqttConnect();

  // Connecting & Subscribe on first open page
  useEffect(() => {
    // if (client) {
    //   client.setMaxListeners(100);
    //   client.on("connect", () => {
    //     MQTT.mqttSub(`${userAll?.userid}/dspenduser/v1/tickets`, client);
    //     MQTT.mqttSub(`dspenduser/v1/tickets/ccagent`, client);
    //   });
    //   client.on("error", (err) => {
    //     console.error("Connection error: ", err);
    //     client.end();
    //   });
    //   client.on("reconnect", () => {
    //     console.log("Reconnecting");
    //   });
    //   // ws://vpn02.mrad-services.com:8885/mqtt
    //   messagesUpdate();
    // } else {
    //   MQTT.mqttConnect(
    //     MQTT_URL,
    //     {
    //       clientId: `CCAGENT_${userAll?.userid}_${uuid()}`,
    //       username: "test",
    //       password: userAll?.token,
    //     },
    //     setClient
    //   );
    // }
    MQTT.MqttConnection(client, userAll, null, messagesUpdate, mqttConnect);
    return () => {};
  }, [client, dispatch]);

  return (
    <Box>
      {!isChatting?.boolean ? (
        <Grid>
          <FilterTicketsComponent
            getData={(x) => getData(x)}
            filterTickets={filterTickets}
            setFilterTickets={setFilterTickets}
          />
          <BreakEndShiftComponent client={client} />
          {messageListRef.current?.length > 0 && !isLoading && !httpError && (
            <BodyTicketsComponent
              ticketList={messageListRef.current}
              setIsChatting={setIsChatting}
              isLoading={isLoading}
            />
          )}
          {isLoading && !httpError && (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "12%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
        </Grid>
      ) : (
        <Grid>
          <ChatTicketsComponent
            client={client}
            state={isChatting?.state}
            setIsChatting={setIsChatting}
            messageSentPub={messageSentPub}
          />
        </Grid>
      )}
    </Box>
  );
};

export default TicketListComponent;
