import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { StatusJSON } from "../JSONFiles/status";
import SearchIcon from "@mui/icons-material/Search";

const FilterUsersComponent = (props) => {
  const getUsersData = props.getUsersData;
  const setOpen = props.setOpen;
  const [filter, setFilter] = useState({
    status: "",
    search: "",
  });

  const handleChange = (e, item) => {
    setFilter({
      ...filter,
      [item]: e,
    });
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              width="100%"
              value={filter.status}
              onChange={(e) => handleChange(e.target.value, "status")}
            >
              {StatusJSON.map((data) => (
                <MenuItem key={data.value} value={data.value}>
                  {data.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={6.5}>
          <TextField
            fullWidth
            id="search"
            value={filter.search}
            onChange={(e) => handleChange(e.target.value, "search")}
            label="Search"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Search Text.."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => {
              getUsersData({
                status: filter.status,
                search: filter.search,
              });
            }}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => setOpen(true)}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterUsersComponent;
