import { Box } from "@mui/material";
import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  setAttendanceBreakData,
  setAttendanceData,
  setAttendanceDaysData,
  setAttendanceDetails,
  setFilterAttendance,
} from "../../../../../../Services/redux/reducers/HRReducer";
import {
  getBarColumnsOptions,
  getPizzaPieOptions,
} from "../../../chatTickets/dashboard/ChartsOptions";
import FilterReturn from "./FilterReturn";

const FilterHRComponent = ({ socket }) => {
  const allEmployees = useSelector((state) => state.hr.allEmployees);
  const filterAttendance = useSelector((state) => state.hr.filterAttendance);
  const attendanceData = useSelector((state) => state.hr.attendanceData);
  const attendanceBreakData = useSelector(
    (state) => state.hr.attendanceBreakData
  );
  const attendanceDaysData = useSelector(
    (state) => state.hr.attendanceDaysData
  );
  const dispatch = useDispatch();
  //item.SHIFTHOURS
  function convertData(response, type) {
    const convertedData = response?.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        // Check if the property value is a string
        if (type === "shift" ? key === "SHIFTHOURS" : key === "BREAKHOURS") {
          newItem[key] = [item[key]];
        } else {
          newItem[key] = item[key];
        }
      });
      return newItem;
    });
    const date = response?.map((resp) =>
      moment(resp?.BGDATE)?.format("YYYY-MM-DD")
    );
    const arrayHashmap = convertedData?.reduce((obj, item) => {
      if (obj[item.ABLINEID]) {
        if (
          date?.includes(moment(item.BGDATE)?.format("YYYY-MM-DD")) &&
          Math.sign(type === "shift" ? item.SHIFTHOURS : item.BREAKHOURS) > 0
        ) {
          type === "shift"
            ? obj[item.ABLINEID].SHIFTHOURS.push(...item.SHIFTHOURS)
            : obj[item.ABLINEID].BREAKHOURS.push(...item.BREAKHOURS);
        } else {
          type === "shift"
            ? obj[item.ABLINEID].SHIFTHOURS.push("0")
            : obj[item.ABLINEID].BREAKHOURS.push("0");
        }
      } else {
        obj[item.ABLINEID] = { ...item };
      }
      return obj;
    }, {});
    const mergedArray = Object.values(arrayHashmap);
    return mergedArray;
  }

  function getData(x) {
    dispatch(setAttendanceData({ ...attendanceData, loading: true }));
    dispatch(setAttendanceBreakData({ ...attendanceBreakData, loading: true }));
    dispatch(setAttendanceDaysData({ ...attendanceDaysData, loading: true }));
    const employeesID = x?.employees?.map((id) => id?.split(" ")?.[0]);
    const filter = {
      checkType: x?.check,
      breakType: x?.break,
      users: employeesID,
      fromDate: x?.fromdate,
      toDate: x?.todate,
    };
    socket.emit(
      "dspinternal:hr",
      {
        request: "attendanceUsersLogs",
        attendanceUsersLogs: filter,
      },
      (response) => {
        console.log(response);
        dispatch(setAttendanceDetails(response));
        const shiftArray = convertData(response?.[1], "shift");
        const breakArray = convertData(response?.[3], "break");
        const avgShift =
          response?.[1].reduce((accumulator, object) => {
            const condition =
              Math.sign(object.SHIFTHOURS) > 0 &&
              Number(object.SHIFTHOURS) < 24;
            return accumulator + (condition ? Number(object.SHIFTHOURS) : 0);
          }, 0) / response?.[1].length;
        const avgBreak =
          response?.[3].reduce((accumulator, object) => {
            return (
              accumulator +
              (Math.sign(object.BREAKHOURS) > 0 &&
              Math.sign(object.BREAKHOURS) < 24
                ? Number(object.BREAKHOURS)
                : 0)
            );
          }, 0) / response?.[3].length;

        const dateRemoveDupl = response?.[1]?.filter(
          (ele, ind) =>
            ind ===
            response?.[1].findIndex(
              (resp) =>
                moment(resp?.BGDATE)?.format("YYYY-MM-DD") ===
                moment(ele?.BGDATE)?.format("YYYY-MM-DD")
            )
        );

        const breakDateRemoveDupl = response?.[3]?.filter(
          (ele, ind) =>
            ind ===
            response?.[1].findIndex(
              (resp) =>
                moment(resp?.BGDATE)?.format("YYYY-MM-DD") ===
                moment(ele?.BGDATE)?.format("YYYY-MM-DD")
            )
        );

        const namesRemoveDupl = response?.[2]?.filter(
          (ele, ind) =>
            ind ===
            response?.[2].findIndex(
              (resp) => resp?.ABFULLNAME === ele?.ABFULLNAME
            )
        );

        const attendanceOptions = getBarColumnsOptions(
          shiftArray?.map((resp) => {
            return {
              name: `${resp?.ABFULLNAME}`,
              type: "bar",
              data: resp?.SHIFTHOURS?.map((data) =>
                data >= 0 && data < 24 ? data : 0
              ).reverse(),
              barMaxWidth: "10%",
            };
          }),
          dateRemoveDupl
            ?.map((resp) => moment(resp?.BGDATE)?.format("YYYY-MM-DD"))
            .reverse(),
          "Attendance Hours Per Shift",
          `Average Hours = ${avgShift}`
        );
        const attendanceBreakOptions = getBarColumnsOptions(
          breakArray?.map((resp) => {
            return {
              name: `${resp?.ABFULLNAME}`,
              type: "bar",
              data: resp?.BREAKHOURS?.map((data) =>
                data >= 0 && data < 24 ? data : 0
              ).reverse(),
              barMaxWidth: "10%",
            };
          }),
          breakDateRemoveDupl
            ?.map((resp) => moment(resp?.BGDATE)?.format("YYYY-MM-DD"))
            .reverse(),
          "Attendance Hours Per Break",
          `Average Hours = ${avgBreak}`
        );

        const attendanceDaysPizzaPieOptions = getPizzaPieOptions(
          namesRemoveDupl?.map((resp) => {
            return { value: resp?.DAYSCOUNT, name: `${resp?.ABFULLNAME}` };
          }),
          "Attendance Days"
        );

        dispatch(
          setAttendanceData({
            data: attendanceOptions,
            error: false,
            loading: false,
          })
        );
        dispatch(
          setAttendanceBreakData({
            data: attendanceBreakOptions,
            error: false,
            loading: false,
          })
        );
        dispatch(
          setAttendanceDaysData({
            data: attendanceDaysPizzaPieOptions,
            error: false,
            loading: false,
          })
        );
        // socket.disconnect();
      }
    );
  }

  // remove duplicates from all business units
  const businessUnit = allEmployees
    ?.filter(
      (ele, ind) =>
        ind ===
        allEmployees.findIndex(
          (elem) => elem.BFBU === ele.BFBU && elem.BUDESC === ele.BUDESC
        )
    )
    ?.sort((a, b) => parseFloat(a.BFBU) - parseFloat(b.BFBU));
  // remove duplicates from all departments
  const departmentFilter = allEmployees?.filter(
    (ele, ind) =>
      ind === allEmployees.findIndex((elem) => elem.BFDPT === ele.BFDPT)
  );
  // get departments without duplicates with same business unit chosed
  const department = filterAttendance?.businessUnit?.length
    ? departmentFilter?.filter((dep) =>
        filterAttendance?.businessUnit?.includes(dep?.BFBU)
      )
    : departmentFilter;
  // remove duplicates from all positions
  const positionFilter = allEmployees?.filter(
    (ele, ind) =>
      ind === allEmployees.findIndex((elem) => elem.BFPOST === ele.BFPOST)
  );
  // get positions without duplicates with same departments chosed
  const position = filterAttendance?.department
    ? positionFilter?.filter((pos) =>
        filterAttendance?.department?.includes(pos?.BFDPT)
      )
    : positionFilter;
  // remove duplicates from all employees
  const employeesFilter = allEmployees?.filter(
    (ele, ind) =>
      ind === allEmployees.findIndex((elem) => elem.ABLINEID === ele.ABLINEID)
  );
  // get employees without duplicates with same positions chosed
  const employees = filterAttendance?.position
    ? employeesFilter?.filter((dep) =>
        filterAttendance?.position?.includes(dep?.BFPOST)
      )
    : employeesFilter;
  // handle change values for check, and the dates
  const handleChange = (e, item) => {
    dispatch(
      setFilterAttendance({
        ...filterAttendance,
        [item]: e,
      })
    );
  };
  // handle change for the value of business unit
  const handleBUChange = (e) => {
    const department = departmentFilter
      ?.filter((dep) => e?.includes(dep?.BFBU))
      ?.map((f) => f?.BFDPT);
    dispatch(
      setFilterAttendance({
        ...filterAttendance,
        businessUnit: e,
        department: department,
        position: departmentFilter
          ?.filter((pos) => department?.includes(pos?.BFDPT))
          ?.map((f) => f?.BFPOST),
        employees: [],
      })
    );
  };
  // handle change for the value of department
  const handleDepartmentChange = (e) => {
    dispatch(
      setFilterAttendance({
        ...filterAttendance,
        department: e,
        position: departmentFilter
          ?.filter((pos) => e?.includes(pos?.BFDPT))
          ?.map((f) => f?.BFPOST),
        employees: [],
      })
    );
  };
  // handle change for the value of position
  const handlePositionChange = (e) => {
    dispatch(
      setFilterAttendance({
        ...filterAttendance,
        position: e,
        employees: [],
      })
    );
  };
  // handle change for the value of employees
  const handleEmployeesChange = (e) => {
    dispatch(
      setFilterAttendance({
        ...filterAttendance,
        employees: e,
      })
    );
  };

  const handleChanges = {
    handleChange,
    handleBUChange,
    handleDepartmentChange,
    handlePositionChange,
    handleEmployeesChange,
  };

  const filteringData = {
    businessUnit,
    department,
    position,
    employees,
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <FilterReturn
        filterAttendance={filterAttendance}
        handleChanges={handleChanges}
        filteringData={filteringData}
        getData={getData}
      />
    </Box>
  );
};

export default FilterHRComponent;
