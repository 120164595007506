import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import FilterAdminsComponent from "./Filter";
import allData from "../../../../../Services/Data";
import { useDispatch, useSelector } from "react-redux";
import BodyAdmins from "../Common/Body";
import AddUserComponent from "../../components/AddUser";
import { setUser } from "../../../../../Services/redux/reducers/userMenuReducer";

const SuperUsersComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(false);
  const [adminsData, setAdminsData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const dispatch = useDispatch();

  const [addDetails, setAddDetails] = useState({
    title: "",
    fullName: "",
    phoneCode: "",
    phoneNumber: "",
    address1: "",
    address2: "",
    email: "",
    idNumber: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    title: "",
    fullName: "",
    phoneCode: "",
    phoneNumber: "",
    address1: "",
    email: "",
    idNumber: "",
  });

  function getData(x, req, type) {
    console.log("ssssssssss");
    if (type === "searchbook") {
      setIsLoading(true);
    }
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        req,
        type,
        x,
        "admindsp/v1/adminuser"
      )
      .then(
        (response) => {
          console.log(response);
          if (type === "searchbook") {
            setAdminsData(response?.data?.addressbook?.searchbook);
            setIsLoading(false);
          } else if (type === "userdetails") {
            setDetailsData(response?.data?.userdata?.userdetails);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
        }
      );
  }

  var i;
  var _adminsData = [];

  const onButtonClick = (e, row) => {
    setOpenDetails(true);
    setRowData(row);
    getData({ id: row.id }, "userdata", "userdetails");
  };

  const adminsDataColumns = [
    {
      field: "action",
      headerName: "Details",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
        >
          Details
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "fullname", headerName: "Full Name", width: 200 },
    { field: "phonenbr", headerName: "Phone Number", width: 200 },
    { field: "address1", headerName: "Address 1", width: 200 },
    { field: "address2", headerName: "Address 2", width: 200 },
  ];

  for (i = 0; i < adminsData?.length; i++) {
    _adminsData[i] = {
      id: adminsData?.[i]?.id,
      fullname: adminsData?.[i]?.fullname,
      phonenbr: adminsData?.[i]?.phoneprefix + adminsData?.[i]?.phonenbr,
      address1: adminsData?.[i]?.address1,
      address2: adminsData?.[i]?.address2,
    };
  }

  return (
    <Box>
      <FilterAdminsComponent
        getData={(x) => {
          setDetailsData([]);
          setOpenDetails(false);
          getData(x, "addressbook", "searchbook");
        }}
        setOpen={setOpenAdd}
      />
      <BodyAdmins
        data={adminsData}
        isLoading={isLoading}
        httpError={httpError}
        adminsDataColumns={adminsDataColumns}
        _adminsData={_adminsData}
        open={openDetails}
        setOpen={setOpenDetails}
        rowData={rowData}
        detailsData={detailsData}
        getData={getData}
        admins={"users"}
      />
      <AddUserComponent
        open={openAdd}
        setOpen={setOpenAdd}
        setDetails={setAddDetails}
        details={addDetails}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        userAll={userAll}
        userType={"employee"}
      />
    </Box>
  );
};

export default SuperUsersComponent;
