import axios from "axios";
import { HTTP_URL } from "../GlobalVar/commonVar";

async function getData(userid, token, request, ureq, path) {
  return await axios.post(
    HTTP_URL + "/" + path,
    {
      userid: userid,
      token: token,
      environment: "test",
      application: "dsp-internal-01",
      platform: "web",
      request: request,
      [request]: ureq,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        authorization: "Bearer " + token,
      },
    }
  );
}

async function getSuperData(userid, token, request, type, ureq, path) {
  return await axios.post(
    HTTP_URL + "/" + path,
    {
      userid: userid,
      token: token,
      environment: "test",
      application: "dsp-internal-01",
      platform: "web",
      request: request,
      [request]: {
        type: type,
        [type]: ureq,
      },
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        authorization: "Bearer " + token,
      },
    }
  );
}

async function sendAddUser(adduserdata) {
  return await axios.post(HTTP_URL + "/adminendusr/v1/enduser", adduserdata, {
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
  });
}

async function getReadingData(userid, token, id, type) {
  return await axios.post(
    HTTP_URL + "/mrbc/spv/v1/emission",
    {
      userid: userid,
      token: token,
      environment: "test",
      application: "dsp-internal-01",
      platform: "web",
      request: "notesdata",
      id: id,
      type: type,
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        authorization: "Bearer " + token,
      },
    }
  );
}

const allData = {
  getData,
  getSuperData,
  sendAddUser,
  getReadingData,
};

export default allData;
