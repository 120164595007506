import React from "react";
import "../Message.scss";
import CompositeMessage from "../CompositeMessage";
import LocationMap from "../../../../components/LocationMap";
import { Button, Grid, Link } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";

const TypeLocation = ({ message }) => {
  // get the lat and lon for the location
  const center = {
    lat: Number(message?.message?.location?.lat),
    lng: Number(message?.message?.location?.lon),
  };

  return (
    <Grid>
      <Grid
        container
        sx={{ cursor: "pointer", height: "150px", marginBottom: "4%" }}
      >
        <LocationMap center={center} height={"100%"} />
      </Grid>
      <Grid container>
        <Grid item xs={5.5}>
          <Link
            href={`https://maps.google.com?q=${center?.lat},${center?.lng}`}
            underline="none"
            target="_blank"
          >
            <Button
              sx={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#b7b7b7",
              }}
            >
              Open
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5.5}>
          <Button
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#b7b7b7",
            }}
          >
            Attach
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TypeLocation;
