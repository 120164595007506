import React from "react";
import "../../Message.scss";
import { Button, Grid, Link } from "@mui/material";

const MediaApplicationType = ({ message }) => {
  return (
    <Link href={message?.message?.media?.link} underline="none" target="_blank">
      <Grid container sx={{ padding: "3% 1% 0% 1%", cursor: "pointer" }}>
        <Grid
          item
          xs={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {message?.message?.media?.extension === "pdf" && (
            <img src="./pdf.png" style={{ width: "45%" }} />
          )}
          {message?.message?.media?.extension === "docx" && (
            <img src="./doc.png" style={{ width: "45%" }} />
          )}
          {message?.message?.media?.extension === "xls" && (
            <img src="./xls-file.png" style={{ width: "45%" }} />
          )}
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
          }}
        >
          {message?.message?.media?.name}
        </Grid>
      </Grid>
      <Grid container>
        <Button
          sx={{
            width: "100%",
            textAlign: "center",
            backgroundColor: "#b7b7b7",
            margin: "3%",
          }}
        >
          Open
        </Button>
      </Grid>
    </Link>
  );
};

export default MediaApplicationType;
