import * as React from "react";
// import { Timeline, TimelineEvent } from "react-event-timeline";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import moment from "moment";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Typography } from "@mui/material";
import {
  TimelineOppositeContent,
  timelineOppositeContentClasses,
} from "@mui/lab";
import BugReportIcon from "@mui/icons-material/BugReport";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const TimelineComponent = ({ history }) => {
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0,
        },
        color: "#064987",
      }}
      align="left"
    >
      {history &&
        history?.map((event, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent color="textSecondary"></TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot sx={{ color: "#064987" }}>
                {(event?.AKLOGTYPE === "NEWTICKET" && <AddIcon />) ||
                  (event?.AKLOGTYPE === "ATTACHCASE" && <AttachmentIcon />) ||
                  (event?.AKLOGTYPE === "NEWASSIGNEE" && (
                    <AssignmentTurnedInIcon />
                  )) ||
                  (event?.AKLOGTYPE === "ISSUE" && <BugReportIcon />) ||
                  (event?.AKLOGTYPE === "UNASSIGNEE" && <HighlightOffIcon />) ||
                  (event?.AKLOGTYPE === "TICKETCLOSE" && <SportsScoreIcon />)}
              </TimelineDot>
              {index + 1 < history?.length && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography
                variant="h6"
                component="span"
                sx={{ fontWeight: "bold" }}
              >
                {moment(event?.AKDATETIME).format("YYYY-MM-DD hh:mm a")}
              </Typography>
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                component="span"
              >
                {event?.AKTEXT}
              </Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};

export default TimelineComponent;
