import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";

const AddPageContent = (props) => {
  const addPage = props.addPage;
  const setAddPage = props.setAddPage;
  const setBeSure = props.setBeSure;

  const handleChange = (e, item) => {
    setAddPage({
      ...addPage,
      [item]: e,
    });
  };

  return (
    <Box sx={{ marginY: "3%" }}>
      <Grid container>
        <Grid item xs={3.8}>
          <TextField
            fullWidth
            id="text"
            label="Text"
            value={addPage.text}
            onChange={(e) => handleChange(e.target.value, "text")}
          />
        </Grid>
        <Grid item xs={0.6 / 2}></Grid>
        <Grid item xs={3.8}>
          <TextField
            fullWidth
            id="description"
            label="Description"
            value={addPage.desc}
            onChange={(e) => handleChange(e.target.value, "desc")}
          />
        </Grid>
        <Grid item xs={0.6 / 2}></Grid>
        <Grid item xs={3.8}>
          <TextField
            fullWidth
            id="value"
            label="Value"
            value={addPage.value}
            onChange={(e) => handleChange(e.target.value, "value")}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: "3%" }}>
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: "#064987!important",
              color: "white",
              width: "100%",
            }}
            onClick={() => setBeSure(true)}
            disabled={
              addPage.text === "" || addPage.desc === "" || addPage.value === ""
            }
          >
            ADD
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

//pageupdate //lineid - text - desc - value - status

//pageadd

export default AddPageContent;
