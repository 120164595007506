import { Box } from "@mui/material";
import React from "react";
import AlertDialog from "../../components/AlertDialog";
import SubsciptionsContent from "./Items/Subscription";
import CustomizedSnackbars from "../../components/NotificationAlert";
import { useState } from "react";

const DetailsPopUp = (props) => {
  const openDetails = props.openDetails;
  const onClose = props.onClose;
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar({
      open: false,
      message: "",
      type: "",
    });
  };

  const content = (
    // <BasicTabs
    //   // tab1={"User"}
    //   tab2={"Subscriptions"}
    //   // item1={<UserContent rowDetails={props.rowDetails} />}
    //   item2={
    <SubsciptionsContent
      userDetails={props.userDetails}
      rowDetails={props.rowDetails}
      getUserDetails={props.getUserDetails}
      setOpenSnackBar={setOpenSnackBar}
      isLoadingDetails={props.isLoadingDetails}
      httpDetailsError={props.httpDetailsError}
    />
    //   }
    // />
  );

  return (
    <Box>
      <AlertDialog
        open={openDetails}
        handleClose={onClose}
        title={"Subscriptions"}
        content={content}
        buttonTitle={""}
      />
      <CustomizedSnackbars
        open={openSnackBar.open}
        handleClose={handleCloseSnackBar}
        severity={openSnackBar.type}
        message={openSnackBar.message}
      />
    </Box>
  );
};

export default DetailsPopUp;
