import React from "react";

import { Box, Grid, Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import TextAreaComponent from "../../../../components/TextArea";
import { useSelector } from "react-redux";

const ConversationReview = () => {
  // get ticket review then take review from it
  const ticketreview = useSelector((state) => state.ticket.ticketreview);
  const ticketreviewRef = React.useRef();
  ticketreviewRef.current = ticketreview;

  const review = ticketreviewRef.current?.review;
  // some styling
  const topic = {
    color: "black",
    fontWeight: "600",
  };

  const styleAFlag = {
    color: "#064987",
    padding: "1%",
    display: "flex",
    marginTop: "3%",
    marginRight: "3%",
    backgroundColor: "#c1bbbb",
    borderRadius: "7.5px",
  };

  const styleSFlag = {
    color: "#064987",
    padding: "1%",
    display: "flex",
    marginTop: "3%",
    marginRight: "3%",
    backgroundColor: "#e8b337",
    borderRadius: "7.5px",
  };

  return (
    <Box
      sx={{
        textAlign: "left",
        padding: "5%",
        height: "100%",
      }}
    >
      <Grid container sx={{ marginTop: "5%" }}>
        <Grid item xs={12} style={{ marginBottom: "5%" }} sx={topic}>
          Customer's Review
        </Grid>
        <Grid
          item
          xs={12}
          container
          sx={{
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: "7.5px",
            padding: "5%",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              h2: {
                color: "#064987",
                fontSize: "medium",
                display: "flex",
                textAlign: "center",
                marginY: "1%",
              },
              p: {
                marginY: "0px",
                textAlign: "center",
              },
            }}
            dangerouslySetInnerHTML={{
              __html: review?.message?.feedback?.rating?.text,
            }}
          />
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Rating
              name="size-large"
              value={
                review?.message?.feedback?.rating?.value
                  ? review?.message?.feedback?.rating?.value
                  : 0
              }
              readOnly
              precision={0.5}
              size="large"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.2, color: "black" }}
                  fontSize="inherit"
                />
              }
            />
          </Grid>
        </Grid>
        {review?.message?.feedback?.survey.map((question, index) => {
          if (index === review?.message?.feedback?.rating?.value - 1) {
            return (
              <Grid
                item
                xs={12}
                key={index}
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  marginTop: "1%",
                }}
              >
                <Grid
                  sx={{
                    boxShadow:
                      " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    borderRadius: "7.5px",
                    padding: "5%",
                    marginY: "5%",
                  }}
                >
                  <Grid
                    dangerouslySetInnerHTML={{
                      __html: question?.title,
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      marginTop: "3%",
                    }}
                  >
                    {question?.questions?.map((mcq, index) => {
                      return (
                        <Grid
                          item
                          key={mcq?.id}
                          sx={mcq?.flag === "A" ? styleAFlag : styleSFlag}
                        >
                          {mcq?.text}
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
                {review?.message?.feedback?.comment?.text && (
                  <Grid item xs={12} sx={{ marginY: "5%" }}>
                    <TextAreaComponent
                      value={review?.message?.feedback?.comment?.text}
                    />
                  </Grid>
                )}
              </Grid>
            );
          }
        })}
      </Grid>
    </Box>
  );
};

export default ConversationReview;
