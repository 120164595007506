export const userLoginMenu = [];
export const userLoginPages = [];
export const userLoginInfo = {};
export const currPagedesc = "Welcome";
export const jwtToken = {};
export const userSystems = [];
export const HTTP_URL = "https://mmsgw01.navydots.com:8443/mmsinternalrest";
export const SOCKET_IO_URL = "https://mmsgw02.navydots.com:2022";
export const MQTT_URL = "wss://tms.taqa-energies.com:2096/mqtt";
export default userLoginMenu;
