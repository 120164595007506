import mqtt from "mqtt";
import { useDispatch } from "react-redux";
import { setClient } from "../redux/reducers/userMenuReducer";

const useMqttConnect = () => {
  const dispatch = useDispatch();

  // Encapsulate the connection logic within the hook
  const mqttConnect = (host, mqttOption) => {
    const client = mqtt.connect(host, mqttOption);
    dispatch(setClient(client));
  };

  return mqttConnect;
};

export default useMqttConnect;
