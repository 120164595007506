import React from "react";
import { Box, Button, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DetailsFields from "./DetailsFields";

const PagesDetails = (props) => {
  const setOpen = props.setOpen;
  const rowData = props.rowData;
  const editDetails = props.editDetails;
  const setEditDetails = props.setEditDetails;
  // const [editDetails, setEditDetails] = useState({
  //   lineid: rowData.id,
  //   text: rowData.text,
  //   status: rowData.status,
  //   desc: rowData.description,
  //   value: rowData.value,
  // });

  const handleChange = (e, item) => {
    setEditDetails({
      ...editDetails,
      [item]: e,
    });
  };

  return (
    <Box sx={{ backgroundColor: "#c3c3c3", height: "100vh", padding: "3%" }}>
      <Grid container>
        <Grid item xs={11}></Grid>
        <Grid item xs={1}>
          <Button onClick={() => setOpen(false)}>
            <CancelIcon sx={{ color: "red" }} />
          </Button>
        </Grid>
      </Grid>
      <DetailsFields
        editDetails={editDetails}
        rowData={rowData}
        handleChange={handleChange}
        getData={props.getData}
        setBeSure={props.setBeSure}
        isDelete={props.isDelete}
      />
    </Box>
  );
};

export default PagesDetails;
