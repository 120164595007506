import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

const DetailsFields = (props) => {
  const editDetails = props.editDetails;
  const rowData = props.rowData;
  const handleChange = props.handleChange;

  const status = [
    {
      label: "Active",
      value: "A",
    },
    {
      label: "Inactive",
      value: "N",
    },
  ];

  return (
    <Grid
      sx={{
        marginY: "10%",
        ".css-75gcxd-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled": {
          color: "black !important",
        },
        ".css-wxl1jl-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled, .MuiOutlinedInput-notchedOutline,.css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
          {
            borderColor: "black !important",
            WebkitTextFillColor: "#064987 !important",
            fontWeight: "bold",
          },
      }}
    >
      <Grid
        item
        xs={11}
        sx={{ fontWeight: "bold", fontSize: "x-large", marginBottom: "6%" }}
      >
        Edit Page
      </Grid>
      <Grid container>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="text"
            label="Text"
            value={editDetails.text ? editDetails.text : ""}
            onChange={(e) => handleChange(e.target.value, "text")}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={3}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={editDetails.status ? editDetails.status : ""}
              width="100%"
              onChange={(e) => handleChange(e.target.value, "status")}
            >
              {status?.map((data) => (
                <MenuItem key={data.value} value={data.value}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container sx={{ marginY: "4%" }}>
        <Grid item xs={5.5}>
          <TextField
            fullWidth
            id="description"
            label="Description"
            value={editDetails.desc ? editDetails.desc : ""}
            onChange={(e) => handleChange(e.target.value, "desc")}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={5.5}>
          <TextField
            fullWidth
            id="value"
            label="Value"
            value={editDetails.value ? editDetails.value : ""}
            onChange={(e) => handleChange(e.target.value, "value")}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Button
          sx={{
            backgroundColor: "#064987!important",
            color: "white",
            width: "100%",
          }}
          onClick={() => props.setBeSure(true)}
          disabled={
            (editDetails.text === rowData.text &&
              editDetails.desc === rowData.description &&
              editDetails.status === rowData.status &&
              editDetails.value === rowData.value) ||
            editDetails.text === "" ||
            editDetails.desc === "" ||
            editDetails.status === "" ||
            editDetails.value === ""
          }
        >
          Update
        </Button>
      </Grid>
      <Grid container sx={{ marginY: "5%" }}>
        <Button
          sx={{
            backgroundColor: "#a30000",
            color: "white",
            width: "100%",
          }}
          onClick={() => {
            props.isDelete(rowData.id);
            props.setBeSure(true);
          }}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default DetailsFields;
