import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Ring } from "@uiball/loaders";
import Table from "../../../table/Table";
import UserDetails from "../../Users/UserDetails";
import CustomizedSnackbars from "../../../components/NotificationAlert";
import PagesDetails from "../../Pages/Details";

const BodyAdmins = (props) => {
  const data = props.data;
  const open = props.open;
  const setOpen = props.setOpen;
  const rowData = props.rowData;
  const isLoading = props.isLoading;
  const httpError = props.httpError;
  const adminsDataColumns = props.adminsDataColumns;
  const _adminsData = props._adminsData;
  const detailsData = props.detailsData;
  const admins = props.admins;
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: "",
    type: "",
  });

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar({
      open: false,
      message: "",
      type: "",
    });
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={open ? 8 : 12}>
          {!isLoading && !httpError && data?.length > 0 && (
            <Table columns={adminsDataColumns} _newData={_adminsData} />
          )}
          {!isLoading && !httpError && data?.length <= 0 && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginY: "4%",
                color: "#064987",
                fontWeight: "bold",
                fontSize: "1.2em",
              }}
            >
              No Records To Display!
            </Grid>
          )}
          {isLoading && (
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}
            >
              <Ring size={40} lineWeight={5} speed={2} color="#064987" />
            </Grid>
          )}
          {!isLoading && httpError && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginY: "4%",
                color: "#064987",
              }}
            >
              {httpError}
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} sx={{ display: open ? "block" : "none" }}>
          {open ? (
            <Grid>
              {admins === "users" && (
                <UserDetails
                  setOpen={setOpen}
                  rowData={rowData}
                  detailsData={detailsData}
                  setOpenSnackBar={setOpenSnackBar}
                  getData={props.getData}
                />
              )}
              {admins === "pages" && (
                <PagesDetails
                  setOpen={setOpen}
                  rowData={rowData}
                  getData={props.getData}
                  editDetails={props.editDetails}
                  setEditDetails={props.setEditDetails}
                  setBeSure={props.setBeSure}
                  isDelete={props.isDelete}
                />
              )}
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      {openSnackBar.open === true && (
        <CustomizedSnackbars
          open={openSnackBar.open}
          handleClose={handleCloseSnackBar}
          severity={openSnackBar.type}
          message={openSnackBar.message}
        />
      )}
    </Box>
  );
};

export default BodyAdmins;
