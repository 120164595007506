import React from "react";

import { Box, Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import Subscriptions from "./Subscriptions";
import AttachCaseID from "./AttachCase";

const ConversationActions = ({
  userAll,
  client,
  joinConversation,
  leaveConversation,
  closeConversation,
  joined,
  conversation,
}) => {
  // get ticket details then subscriptions and info from it
  const ticketdetails = useSelector((state) => state.ticket.ticketdetails);
  const ticketdetailsRef = React.useRef();
  ticketdetailsRef.current = ticketdetails;
  const subscriptions = ticketdetailsRef.current?.subscriptions;
  const info = ticketdetailsRef.current?.ticketInfo;
  // state for case id
  const [caseID, setCaseID] = React.useState({
    value: conversation?.AHJDECASEID,
    error: "",
  });

  const topic = {
    color: "black",
    fontWeight: "600",
  };

  const span = {
    color: "#064987",
  };
  // if the ticket is assigned to me
  const assToMe =
    conversation?.AHASSIGNED === "Y" &&
    conversation?.AHASSIGNEE === userAll?.userid;

  return (
    <Box
      sx={{
        textAlign: "left",
        padding: "5%",
        height: "100%",
        display: !assToMe && "flex",
        alignItems: !assToMe && "center",
      }}
    >
      {assToMe && (
        <Grid>
          {subscriptions?.length > 0 && (
            <Grid
              sx={{
                padding: "5%",
                boxShadow:
                  " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                borderRadius: "7.5px",
              }}
            >
              <Grid sx={topic}>Subscriptions</Grid>
              <Grid sx={{ marginTop: "5%" }}>
                {subscriptions?.map((subs) => (
                  <Subscriptions
                    key={subs?.id}
                    subs={subs}
                    topic={topic}
                    span={span}
                    info={info}
                    userAll={userAll}
                    client={client}
                    conversation={conversation}
                  />
                ))}
              </Grid>
            </Grid>
          )}
          <Grid
            sx={{
              padding: "5%",
              marginTop: "10%",
              boxShadow:
                " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
              borderRadius: "7.5px",
            }}
          >
            <AttachCaseID
              userAll={userAll}
              client={client}
              caseID={caseID}
              setCaseID={setCaseID}
              info={info}
              conversation={conversation}
            />
          </Grid>
        </Grid>
      )}
      {info?.AHSTATUS !== "C" && (
        <Grid container sx={{ padding: "5%" }}>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={5}>
            {assToMe ? (
              <Button
                sx={{ width: "100%", backgroundColor: "red", color: "white" }}
                onClick={() => leaveConversation()}
              >
                UnAssign
              </Button>
            ) : (
              <Button
                sx={{ width: "100%", backgroundColor: "green", color: "white" }}
                onClick={() => joinConversation()}
              >
                Assign To Me
              </Button>
            )}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <Button
              sx={{ width: "100%", backgroundColor: "red", color: "white" }}
              onClick={() => closeConversation()}
            >
              Close
            </Button>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ConversationActions;
