import mqtt from "mqtt";
import { MQTT_URL } from "../GlobalVar/commonVar";
import uuid from "react-uuid";
import { useDispatch } from "react-redux";
import { setClient } from "../redux/reducers/userMenuReducer";
import useMqttConnect from "./MQTTConnect";
// import useMqttConnect from "./MQTTConnect";

// const mqttConnect = (host, mqttOption) => {
//   const dispatch = useDispatch();
//   dispatch(setClient(mqtt.connect(host, mqttOption)));
// };

// const useMqttConnect = () => {
//   const dispatch = useDispatch();

//   // Encapsulate the connection logic within the hook
//   const mqttConnect = (host, mqttOption) => {
//     const client = mqtt.connect(host, mqttOption);
//     dispatch(setClient(client));
//   };

//   return mqttConnect;
// };

const mqttSub = (topic, client) => {
  if (client) {
    // const { topic } = subscription;
    client.subscribe(topic, { qos: 0 }, (error) => {
      if (error) {
        console.log("Subscribe to topics error", error);
        return;
      }
    });
  }
};

const mqttUnSub = (subscription, client) => {
  if (client) {
    const { topic, qos } = subscription;
    client.unsubscribe(topic, { qos }, (error) => {
      if (error) {
        console.log("Unsubscribe error", error);
        return;
      }
    });
  }
};

const mqttPublish = (context, client) => {
  if (client) {
    const { topic, qos, payload } = context;
    client.publish(topic, payload, { qos }, (error) => {
      if (error) {
        console.log("Publish error: ", error);
      } else {
      }
    });
  }
};

const MqttConnection = (
  client,
  userAll,
  liveUpdate,
  messagesUpdate,
  mqttConnect
) => {
  if (client) {
    client.setMaxListeners(100);
    client.on("connect", () => {
      mqttSub(`${userAll?.userid}/dspenduser/v1/tickets`, client);
      mqttSub(`dspenduser/v1/tickets/ccagent`, client);
    });
    client.on("error", (err) => {
      console.error("Connection error: ", err);
      client.end();
    });
    client.on("reconnect", () => {
      console.log("Reconnecting");
    });
    if (liveUpdate !== null) {
      liveUpdate();
    }
    if (messagesUpdate) {
      messagesUpdate();
    }
  } else {
    mqttConnect(MQTT_URL, {
      clientId: `CCAGENT_${userAll?.userid}_${uuid()}`,
      username: "test",
      password: userAll?.token,
    });
  }
};

const MQTT = {
  // useMqttConnect,
  // mqttConnect,
  mqttSub,
  mqttUnSub,
  mqttPublish,
  MqttConnection,
};

export default MQTT;
