import { Button, Drawer, Toolbar } from "@mui/material";
import React from "react";
import BasicTabs from "../../../components/Tabs";
import ConversationJoin from "../conversation/conversation-join/ConversationJoin";
import ConversationReview from "../conversation/conversation-review";
import ConversationActions from "../conversation/conversation-actions";
import MQTT from "../../../../../../Services/MQTT";
import ConversationMedia from "../conversation/conversation-media";
import { setAssignedToMe } from "../../../../../../Services/redux/reducers/ticketsReducer";
import { useDispatch } from "react-redux";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";

const drawerWidth = "28.5%";

const ChatDrawer = ({
  selectedConversation,
  client,
  userAll,
  joined,
  conversationMessages,
  setDrawerOpen,
}) => {
  const dispatch = useDispatch();

  const tabSx = {
    display: "flex",
    ".css-heg063-MuiTabs-flexContainer": {
      justifyContent: "space-around !important",
    },
    ".css-15b0or0-MuiTabs-root, .css-heg063-MuiTabs-flexContainer, .css-9q8ukk,.css-1rgi8bb, .css-k008qs":
      {
        backgroundColor: "#8cadbd",
      },
  };

  // Publish assign to me
  const joinConversation = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "ticketassign",
          userid: userAll?.userid,
          ticketid: selectedConversation?.AHLINEID,
        }),
      },
      client
    );
  };

  // Remove the ticket from the joined list then unsubscribe ticket
  const leaveConversation = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "ticketunassign",
          userid: userAll?.userid,
          ticketid: selectedConversation?.AHLINEID,
        }),
      },
      client
    );
  };

  // Close the ticket
  const closeConversation = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "ticketclose",
          userid: userAll?.userid,
          ticketid: selectedConversation?.AHLINEID,
        }),
      },
      client
    );
  };

  const tabs = [
    {
      label: "Details",
      value: "details",
    },
    {
      label: "Media",
      value: "media",
    },
    {
      label: "Actions",
      value: "actions",
    },
    {
      label: "Review",
      value: "review",
    },
  ];
  // to get each item in each tab
  const items = [
    {
      content: <ConversationJoin conversation={selectedConversation} />,
      empty: "",
    },
    {
      content: (
        <ConversationMedia conversationMessages={conversationMessages} />
      ),
      empty: "",
    },
    selectedConversation?.AHSTATUS !== "C" && {
      content: (
        <ConversationActions
          client={client}
          userAll={userAll}
          conversation={selectedConversation}
          joinConversation={joinConversation}
          leaveConversation={leaveConversation}
          closeConversation={closeConversation}
          joined={joined}
        />
      ),
      empty: "",
    },
    {
      content: <ConversationReview />,
      empty: "",
    },
  ];

  const drawer = (e) => {
    if (e === 3) {
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "ticketreview",
            userid: userAll?.userid,
            ticketid: selectedConversation?.AHLINEID,
          }),
        },
        client
      );
    } else {
      console.log("");
    }
  };

  return (
    <div>
      <BasicTabs
        tabs={tabs}
        tabSx={tabSx}
        items={items}
        drawer={(e) => drawer(e)}
      />
      <div
        style={{
          position: "absolute",
          left: 0,
          top: "45%",
        }}
      >
        <Button
          sx={{
            backgroundColor: "#064987",
            color: "white",
            minWidth: "30px",
            width: "35px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          onClick={() => setDrawerOpen(false)}
        >
          <KeyboardDoubleArrowRightIcon
            sx={{ height: "100%", width: "100%" }}
          />
        </Button>
      </div>
    </div>
  );
};

export default ChatDrawer;
