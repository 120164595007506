import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";

const CompositeMessage = ({ composite }) => {
  const [html, setHTML] = useState({ __html: composite.header });
  // styling
  const buttonAStyle = {
    width: "100%",
    borderTop: "1px solid #ababab",
    borderRadius: "0px",
    paddingY: "2%",
    color: "#064987 !important",
    display: "flex",
    justifyContent: "start",
    fontWeight: "bold",
    fontSize: "1rem",
  };
  const buttonSStyle = {
    width: "100%",
    height: "50px",
    borderRadius: "0px",
    color: "#064987 !important",
    display: "flex",
    justifyContent: "start",
    backgroundColor: "#FFC000",
    opacity: "75%",
    borderRadius: "0px 0px 14px 0px",
    marginTop: "3%",
    fontWeight: "bold",
    fontSize: "1rem",
  };

  return (
    <Box>
      <Grid>
        <img
          src={composite.image}
          alt={composite.image}
          style={{ width: "100%", borderRadius: "14px 14px 0px 0px" }}
        />
      </Grid>
      <div
        style={{ marginRight: "14px", marginLeft: "14px" }}
        dangerouslySetInnerHTML={html}
      />
      <Grid>
        {composite?.buttons.map((button) => {
          return (
            <Grid key={button.id}>
              {button.flag01 === "N" && <></>}
              {button.flag01 === "A" && (
                <div
                  style={buttonAStyle}
                  dangerouslySetInnerHTML={{ __html: button.text }}
                />
              )}
              {button.flag01 === "S" && (
                <div
                  style={buttonSStyle}
                  dangerouslySetInnerHTML={{ __html: button.text }}
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default CompositeMessage;
