import { Box, Button, Grid, InputAdornment, TextField } from "@mui/material";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";

const ControlsGetLocation = (props) => {
  const filter = props.filter;
  const handleChange = props.handleChange;

  return (
    <Box marginBottom={"1%"}>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="crid"
            label="CR ID"
            value={filter.crID}
            variant="outlined"
            autoComplete="off"
            placeholder="Enter CR ID.."
            onChange={(e) => handleChange(e.target.value, "crID")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PermContactCalendarIcon sx={{ color: "#064987" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            disabled={filter.crID === ""}
            onClick={() => {
              props.getData({
                crid: filter.crID,
              });
            }}
          >
            Get Location
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ControlsGetLocation;
