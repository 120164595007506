import React, { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Checkbox, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../table/Table";
import { Ring } from "@uiball/loaders";
import AlertDialog from "../../../../components/AlertDialog";
import allData from "../../../../../../../Services/Data";
import { setUser } from "../../../../../../../Services/redux/reducers/userMenuReducer";

const SubscriptionAdd = (props) => {
  const userAll = useSelector((state) => state.menu.userAll);
  const setIsAdding = props.setIsAdding;
  const rowDetails = props.rowDetails;
  const [searchBar, setSerachBar] = useState({
    branch: "",
    installation: "",
    name: "",
  });
  const [searchData, setSerachData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const [open, setOpen] = useState(false);
  const [finalAdding, setFinalAdding] = useState([]);
  const dispatch = useDispatch();
  // const [openSnackBar, setOpenSnackBar] = useState({
  //   open: false,
  //   message: "",
  //   type: "",
  // });

  function getUserDetails(x) {
    setIsLoading(true);
    const params = {
      branch: x.branch,
      installation: x.installation,
      name: x.name,
    };
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "userupdate",
        {
          type: "searchsubscription",
          searchsubscription: params,
        },
        "adminendusr/v1/enduser"
      )
      .then(
        (response) => {
          setIsLoading(false);
          setSerachData(response?.data?.userupdate);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setHttpError(error);
        }
      );
  }

  function onAddSubs() {
    const par = {
      subid: finalAdding?.[0].addressNb,
      enduserid: rowDetails.id,
    };
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "userupdate",
        {
          type: "addsubscription",
          addsubscription: par,
        },
        "adminendusr/v1/enduser"
      )
      .then(
        (response) => {
          setTimeout(() => {
            if (response.data.request === "subscriptionexists") {
              props.setOpenSnackBar({
                open: true,
                message: "Subscription already exists!",
                type: "error",
              });
              setOpen(false);
            } else {
              props.setOpenSnackBar({
                open: true,
                message: "Subscription added successfully!",
                type: "success",
              });
              setOpen(false);
              props.getdetails(rowDetails.id);
              setIsAdding(false);
            }
          }, 2000);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
        }
      );
  }

  function handleSearch(e, item) {
    setSerachBar({
      ...searchBar,
      [item]: e,
    });
  }

  const onClose = () => {
    setOpen(false);
  };

  const onSure = () => {
    onAddSubs();
  };

  let i;
  let _searchData = [];

  for (i = 0; i < searchData?.length; i++) {
    _searchData[i] = {
      id: Math.random(),
      addressNb: searchData?.[i]?.ABAN8,
      fullName: searchData?.[i]?.ABALPH,
      installation: searchData?.[i]?.ABALKY,
      branch: searchData?.[i]?.ABTXCT,
      meter: searchData?.[i]?.ABTX2,
      address: searchData?.[i]?.ALADD4,
      confirmed: false,
    };
  }

  function handleConfirmChange(clickedRow) {
    const updatedData = _searchData.map((x) => {
      if (x.addressNb === clickedRow.addressNb) {
        x.confirmed = !x.confirmed;
      }
      return x;
    });
    _searchData = updatedData;
  }

  const searchDataColumns = [
    {
      field: "confirmed",
      headerName: "Confirmed",
      renderCell: (params) => (
        <Checkbox
          checked={params.rows?.confirmed}
          onChange={() => handleConfirmChange(params.row)}
        />
      ),
    },
    { field: "addressNb", headerName: "Address Number", width: 100 },
    { field: "fullName", headerName: "Full Name", width: 100 },
    { field: "installation", headerName: "Installation", width: 100 },
    { field: "branch", headerName: "Branch", width: 100 },
    { field: "meter", headerName: "Meter", width: 100 },
    { field: "address", headerName: "Address", width: 125 },
  ];

  return (
    <Box>
      <Grid
        container
        sx={{ display: "flex", alignItems: "center", marginTop: "1%" }}
      >
        <Grid item xs={1}>
          <Button onClick={() => setIsAdding(false)}>
            <ArrowBackIcon sx={{ color: "#064987" }} />
          </Button>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={2.6}>
          <TextField
            fullWidth
            id="branch"
            value={searchBar.branch}
            onChange={(e) => handleSearch(e.target.value, "branch")}
            label="Branch"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Branch.."
          />
        </Grid>
        <Grid item xs={1 / 2}></Grid>
        <Grid item xs={2.6}>
          <TextField
            fullWidth
            id="installation"
            value={searchBar.installation}
            onChange={(e) => handleSearch(e.target.value, "installation")}
            label="Installation"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter installation.."
          />
        </Grid>
        <Grid item xs={1 / 2}></Grid>
        <Grid item xs={2.6}>
          <TextField
            fullWidth
            id="name"
            value={searchBar.name}
            onChange={(e) => handleSearch(e.target.value, "name")}
            label="Name"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Name.."
          />
        </Grid>
        <Grid item xs={0.3}></Grid>
        <Grid
          item
          xs={0.9}
          sx={{
            backgroundColor: "#064987",
            borderRadius: "7.5px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{ minWidth: "0px" }}
            onClick={() => getUserDetails(searchBar)}
            disabled={
              searchBar.branch?.length <= 0 &&
              searchBar.installation?.length <= 0 &&
              searchBar.name?.length <= 0
            }
          >
            <SearchIcon
              sx={{
                color:
                  searchBar.branch?.length > 0 ||
                  searchBar.installation?.length > 0 ||
                  searchBar.name?.length > 0
                    ? "white"
                    : "#6d6868",
              }}
            />
          </Button>
        </Grid>
      </Grid>
      <Grid marginTop={"3%"}>
        {!isLoading && !httpError && searchData?.length > 0 && (
          <Grid>
            <Grid>
              <Table columns={searchDataColumns} _newData={_searchData} />
            </Grid>
            <Grid marginTop={"3%"}>
              <Button
                autoFocus
                onClick={() => {
                  setFinalAdding(
                    _searchData.filter((x) => x.confirmed === true)
                  );
                  setOpen(true);
                }}
                sx={{
                  backgroundColor: "#064987!important",
                  color: "white",
                  width: "100%",
                }}
                disabled={finalAdding > 1}
              >
                ADD
              </Button>
            </Grid>
          </Grid>
        )}
        {isLoading && !httpError && (
          <Grid sx={{ textAlign: "center" }}>
            <Ring size={40} lineWeight={5} speed={2} color="#064987" />
          </Grid>
        )}
        {!isLoading && httpError && <Grid>{httpError}</Grid>}
        <AlertDialog
          open={open}
          handleClose={onClose}
          title={"Are You Sure?"}
          buttonTitle={"Yes"}
          buttonClick={onSure}
        />
      </Grid>
    </Box>
  );
};

export default SubscriptionAdd;
