import { Button, Grid } from "@mui/material";
import React from "react";
import BoxItem from "./BoxItem";
import { useNavigate } from "react-router-dom";

const BigBoxItems = (props) => {
  const navigate = useNavigate();
  const details = props.lBox;

  return (
    <Grid width={"100%"} marginY={"1%"} height={"100%"}>
      <Button
        sx={{
          padding: "0px",
          textTransform: "none",
          width: "100%",
          height: "100%",
        }}
        onClick={() => navigate(details.boxLink)}
      >
        <BoxItem
          title={details.boxTitle}
          description={details.boxDescription}
          icon={details.boxIcon}
        />
      </Button>
    </Grid>
  );
};

export default BigBoxItems;
