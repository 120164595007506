import { List, useTheme, Typography, Divider, Grid } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setCurrPageDesc } from "../../../../../Services/redux/reducers/userMenuReducer";
import { tokens } from "../../../../../theme";
import { MenuItem } from "react-pro-sidebar";
import * as Muicon from "@mui/icons-material";
import Cookies from "universal-cookie";

const Item = ({ title, to, icon, selected, setSelected, state }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => {
        setSelected(title);
        dispatch(setCurrPageDesc(title));
      }}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} state={state} />
    </MenuItem>
  );
};

const SidebarList = (props) => {
  const cookies = new Cookies();
  // const _nameSplit = cookies.get("_location").split("/");
  // const _extension = _nameSplit[_nameSplit?.length - 1];
  // const isSelected = props?.userMenu?.filter(
  //   (menu) => _extension === menu?.AIVALUE
  // )?.[0]?.AIDESC;
  const [selected, setSelected] = useState(props.userMenu?.[0]?.AIDESC);

  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation];
    return <IconName {...props} />;
  };

  return (
    <List sx={{ textAlign: "left" }}>
      {props.userMenu.map((menu) => {
        return (
          <Grid key={menu.AIVALUE}>
            <Item
              selected={selected}
              setSelected={setSelected}
              to={menu.AIVALUE}
              state={menu.AIVALUE}
              title={menu.AIDESC}
              icon={GenerateIcon("Add")}
            />
            <Divider
              sx={{
                marginX: "6%",
                borderColor: "rgb(124 124 124 / 67%)",
              }}
            />
          </Grid>
        );
      })}
    </List>
  );
};

export default SidebarList;
