export const StatusJSON = [
  {
    description: "All",
    value: "All",
  },
  {
    description: "Completed",
    value: "D",
  },
  {
    description: "Incomplete",
    value: "N",
  },
];

export default {
  StatusJSON,
};
