import React from "react";

import "./Button.scss";

const FormButton = ({ children, disabled, onClick, onChange }) => {
  return (
    <>
      <button
        type="submit"
        className="primary-button"
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
      >
        {children}
      </button>
    </>
  );
};

export default FormButton;
