import { Box, Button, Grid } from "@mui/material";
import React from "react";
import FilterComponent from "../Filter";
import Table from "../../../table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import AlertDialog from "../../AlertDialog/index";
import { Ring } from "@uiball/loaders";
import allData from "../../../../../../Services/Data";
import gettingData from "../Services/supervisorsAccess";
import { setUser } from "../../../../../../Services/redux/reducers/userMenuReducer";

const BillCollectionComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [data, setData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [readingData, setReadingData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [detailsData, setDetailsData] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [moreDetailsData, setMoreDetailsData] = useState(false);
  const [isMap, setIsMap] = useState(false);
  const [openPath, setOpenPath] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const dispatch = useDispatch();

  function getData(x) {
    setIsLoading(true);
    setHttpError("");
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        "emissiondata",
        x,
        "mrbc/spv/v1/emission"
      )
      .then(
        (response) => {
          setSearchedData(response);
          setIsLoading(false);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
        }
      );
  }

  function sendMyID(x) {
    allData
      .getReadingData(`${userAll?.userid}`, `${userAll?.token}`, x, "bc")
      .then(
        (response) => {
          setReadingData(response?.data?.notesdata);
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
        }
      );
  }

  const divisionData = data?.data?.controldata?.filter(
    (div) => div.AGFIELD === "divid"
  );

  useEffect(() => {
    gettingData.superAccessData(userAll, setData, "spvlvmrbc01b", dispatch);
  }, []);

  const onButtonClick = (e, row) => {
    setOpenDetails(true);
    setDetailsData(row);
    sendMyID(row.id);
  };

  const onMapClick = (e, row) => {
    setOpenMore(true);
    setMoreDetailsData(row);
    setIsMap(true);
  };

  const onImageClick = (e, row) => {
    setOpenMore(true);
    setMoreDetailsData(row);
    setIsMap(false);
  };

  const onPathClick = () => {
    setOpenMore(true);
    setOpenPath(true);
  };

  const handleClose = () => {
    setOpenDetails(false);
    setOpenPath(false);
  };

  const handleMIClose = () => {
    setOpenMore(false);
    setIsMap(false);
    setOpenPath(false);
  };

  var i;
  var j;
  var _billCollectionData = [];
  var _readingData = [];

  const billCollectionColumns = [
    {
      field: "action",
      headerName: "Action",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
        >
          Details
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "period", headerName: "Period", width: 100 },
    { field: "year", headerName: "Year", width: 100 },
    { field: "villageID", headerName: "Village ID", width: 100 },
    { field: "villageName", headerName: "Village Name", width: 100 },
    { field: "round", headerName: "Round", width: 100 },
    { field: "installation", headerName: "Installation", width: 100 },
    { field: "branch", headerName: "Branch", width: 100 },
    { field: "meter", headerName: "Meter SN", width: 100 },
    { field: "customer", headerName: "Customer", width: 100 },
    { field: "crid", headerName: "CR ID", width: 100 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "sayrafaDate", headerName: "Sayrafa Date", width: 100 },
    { field: "sayrafaRate", headerName: "Sayrafa Rate", width: 100 },
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "corrected", headerName: "Corrected", width: 100 },
    { field: "BCCOLLECTED", headerName: "Collected", width: 100 },
    { field: "paymentType", headerName: "Payment Type", width: 100 },
    { field: "paymentUSD", headerName: "Payment USD", width: 100 },
  ];
  for (i = 0; i < searchedData?.data?.emissiondata?.length; i++) {
    _billCollectionData[i] = {
      id: searchedData?.data?.emissiondata?.[i].BCID,
      period: searchedData?.data?.emissiondata?.[i].BCMONTH,
      year: searchedData?.data?.emissiondata?.[i].BCYEAR,
      villageID: searchedData?.data?.emissiondata?.[i].BCVILLAGEID,
      villageName: searchedData?.data?.emissiondata?.[i].UVDESC,
      round: searchedData?.data?.emissiondata?.[i].BCROUND,
      installation: searchedData?.data?.emissiondata?.[i].BCINSTALLATION,
      branch: searchedData?.data?.emissiondata?.[i].BCBRANCH,
      meter: searchedData?.data?.emissiondata?.[i].BCMETERID,
      customer: searchedData?.data?.emissiondata?.[i].BCSUBNAME,
      crid: searchedData?.data?.emissiondata?.[i].BCCRID,
      status: searchedData?.data?.emissiondata?.[i].BCCAT1,
      sayrafaDate: searchedData?.data?.emissiondata?.[i].BCSAYRAFADATE,
      sayrafaRate: searchedData?.data?.emissiondata?.[i].BCSAYRAFARATE,
      amount: searchedData?.data?.emissiondata?.[i].BCAMOUNT,
      corrected: searchedData?.data?.emissiondata?.[i].BCCORRECTED,
      collected: searchedData?.data?.emissiondata?.[i].BCCOLLECTED,
      paymentType: searchedData?.data?.emissiondata?.[i].BCPAYMENTTYPE,
      paymentUSD: searchedData?.data?.emissiondata?.[i].BCPAYMENTCAT1,
    };
  }

  const readingColumns = [
    { field: "time", headerName: "Date", width: 150 },
    { field: "type", headerName: "Type", width: 100 },
    { field: "note", headerName: "Notes", width: 150 },
    {
      field: "map",
      headerName: "Map",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
            borderRadius: "0px",
          }}
          onClick={(e) => onMapClick(e, params.row)}
        >
          Map
        </Button>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
            borderRadius: "0px",
          }}
          onClick={(e) => onImageClick(e, params.row)}
        >
          Image
        </Button>
      ),
    },
  ];

  for (j = 0; j < readingData?.length; j++) {
    _readingData[j] = {
      id: readingData?.[j].BCALINEID,
      time: readingData?.[j].BCAUPDATETIME,
      type: readingData?.[j].BCACAT1,
      note: readingData?.[j].BCANOTE,
      lat: readingData?.[j].BCALAT,
      lon: readingData?.[j].BCALONT,
    };
  }

  return (
    <Box>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          color: "#064987",
          fontSize: "1.5rem",
          marginY: "1%",
        }}
      >
        Bill Collection Operation
      </Grid>
      <Box>
        <FilterComponent
          divisionData={divisionData}
          getData={getData}
          type={"bc"}
        />
        {!isLoading &&
          !httpError &&
          searchedData?.data?.emissiondata?.length > 0 && (
            <Table
              columns={billCollectionColumns}
              _newData={_billCollectionData}
            />
          )}
        {!isLoading &&
          !httpError &&
          searchedData?.data?.emissiondata?.length <= 0 && (
            <Grid
              sx={{
                display: "flex",
                justifyContent: "center",
                marginY: "4%",
                color: "#064987",
                fontWeight: "bold",
                fontSize: "1.2em",
              }}
            >
              No Records To Display!
            </Grid>
          )}
        {isLoading && (
          <Grid
            sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}
          >
            <Ring size={40} lineWeight={5} speed={2} color="#064987" />
          </Grid>
        )}
        {!isLoading && httpError && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: "4%",
              color: "#064987",
            }}
          >
            {httpError}
          </Grid>
        )}
      </Box>
      <Box>
        <AlertDialog
          type={"bc"}
          detailsData={detailsData}
          open={openDetails}
          openPop={openMore}
          moreDetailsData={moreDetailsData}
          handleClose={handleClose}
          handleMIClose={handleMIClose}
          columns={readingColumns}
          _newData={_readingData}
          isMap={isMap}
          openPath={openPath}
          onPathClick={onPathClick}
        />
      </Box>
    </Box>
  );
};

export default BillCollectionComponent;
