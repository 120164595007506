import { Box, Grid } from "@mui/material";
import React from "react";
import BarChart from "../../Charts/BarChart";
import PieChart from "../../Charts/PieChart";
import Spline from "../../Charts/Spline";
import moment from "moment/moment";

const DashboardComponent = (props) => {
  const type = props.type;

  const firstVisit = moment(
    new Date(
      Math.min.apply(
        null,
        props.searchedData?.dash0?.map(function (e) {
          return new Date(e.BCADATE);
        })
      )
    )
  )?.format("YYYY-MM-DD");

  const lastVisit = moment(
    new Date(
      Math.max.apply(
        null,
        props.searchedData?.dash0?.map(function (e) {
          return new Date(e.BCADATE);
        })
      )
    )
  )?.format("YYYY-MM-DD");

  const oneDay = 24 * 60 * 60 * 1000;
  const firstDate = new Date(firstVisit);
  const secondDate = new Date(lastVisit);

  const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1;

  const TextSx = {
    color: "#064987",
    fontWeight: "bold",
    fontSize: "1.5em",
  };
  const GridSx = {
    boxShadow:
      " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    padding: "20px",
    textAlign: "center",
  };

  return (
    <Box margin={"3%"}>
      <Grid container>
        <Grid item xs={5.8} sx={GridSx}>
          <Grid sx={TextSx}>Visits Distribution / Day</Grid>
          {type === "bc" && (
            <Grid container>
              <Grid item xs={4}>
                {"First visit on " +
                  (!moment(firstVisit).isValid() ? "0" : firstVisit)}
              </Grid>
              <Grid item xs={4}>
                {"Last visit on " +
                  (!moment(lastVisit).isValid() ? "0" : lastVisit)}
              </Grid>
              <Grid item xs={4}>
                {"Days active " +
                  Object.keys(props.searchedData?.dash0)?.length +
                  "/" +
                  diffDays}
              </Grid>
            </Grid>
          )}
          <Grid>
            <BarChart data={props.chart1} width="100%" height="100%" />
          </Grid>
        </Grid>
        <Grid item xs={0.4}></Grid>
        <Grid item xs={5.8} sx={GridSx}>
          <Grid sx={TextSx}>
            {type === "bc" ? "Collection" : "Readings"} Summary
          </Grid>
          <Grid>
            <PieChart data={props.chart2} width="100%" maxHeight="400px" />
          </Grid>
        </Grid>
      </Grid>
      <Grid container marginY={"3%"}>
        <Grid item xs={5.8} sx={GridSx}>
          <Grid sx={TextSx}>
            {type === "bc" ? "Collected Vs Delivered" : "Consumption Intervals"}
          </Grid>
          <Grid>
            <BarChart data={props.chart3} width="100%" height="100%" />
          </Grid>
        </Grid>
        <Grid item xs={0.4}></Grid>
        <Grid item xs={5.8} sx={GridSx}>
          <Grid sx={TextSx}>
            {type === "bc" ? "Collection" : "Consumption"} Per Emission
          </Grid>
          <Grid>
            {type === "mr" ? (
              <Spline data={props.chart4} width="100%" height="100%" />
            ) : (
              <BarChart data={props.chart4} width="100%" height="100%" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardComponent;

//crpath
