import { Box, Button, Grid } from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import * as Muicon from "@mui/icons-material";

const TicketsInfoBoxes = ({ data }) => {
  // common styles for all boxes
  const displaying = {
    display: "flex",
    alignItems: "center !important",
  };
  // get icon from mui by its name(from jde)
  const GenerateIcon = (variation, props = {}) => {
    const IconName = Muicon[variation];
    return (
      <IconName
        sx={{ width: "60%", height: "60%", color: "#064987" }}
        {...props}
      />
    );
  };

  return (
    <Box
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
      sx={{
        width: "15%",
        backgroundColor: "#939393",
        borderRadius: "7.5px",
        padding: "0.5%",
      }}
    >
      <Button
        sx={{
          displaying,
          width: "100%",
          textTransform: "initial",
          color: "white",
          height: "100%",
        }}
      >
        <Grid container sx={displaying}>
          {/* display the icon */}
          <Grid item xs={3} sx={displaying}>
            {GenerateIcon(data?.UVCAT1)}
          </Grid>
          <Grid item xs={0.5}></Grid>
          {/* display the count and the description */}
          <Grid item xs={8.5}>
            <Grid
              sx={{
                fontSize: "1rem",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {data?.COUNT} {data?.UVDESC}
            </Grid>
          </Grid>
        </Grid>
      </Button>
    </Box>
  );
};

export default TicketsInfoBoxes;
