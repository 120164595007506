import React from "react";

import FormButton from "../controls/buttons/FormButton";
import "./ChatForm.scss";
import { Button, Grid, TextField } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { styled } from "@mui/material/styles";
import { Buffer } from "buffer";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const isMessageEmpty = (textMessage) => {
  return adjustTextMessage(textMessage)?.length === 0;
};

const adjustTextMessage = (textMessage) => {
  return textMessage.trim();
};

const ChatForm = ({
  selectedConversation,
  setOpenUpload,
  sendedMessage,
  setSendedMessage,
  sendMessage,
}) => {
  // disable send button if nothing to send
  const disableButton = isMessageEmpty(sendedMessage?.message?.text);
  let formContents = null;
  // handle attachments on change
  const handleAttachment = (event) => {
    // get the file
    var file = event?.target?.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function (e) {
      // split the type of the file to get the type without .png/jpg/mp4...
      const spliting = file?.type.split("/");
      // split the name of the file and get the extension as the last item after the last point in the name
      const _nameSplit = file?.name.split(".");
      const _extension = _nameSplit[_nameSplit?.length - 1];
      // get the result then split the first paragraph from it to get its buffer
      var x = e.target.result;
      x = x.split("base64,")[1];
      x = Buffer.from(x, "base64");
      // set the message state
      setSendedMessage({
        ...sendedMessage,
        ticketid: selectedConversation.AHLINEID,
        message: {
          ...sendedMessage.message,
          type: "media",
          media: {
            extension: _extension,
            type: spliting?.[0],
            buffer: x,
            name: file?.name,
          },
        },
      });
    };
    setOpenUpload(true);
  };

  // The input for typing a message
  if (selectedConversation) {
    formContents = (
      <>
        <Grid container>
          <Grid
            item
            xs={0.5}
            sx={{
              ".css-13qp4b7-MuiButtonBase-root-MuiButton-root": {
                minWidth: "0px !important",
                maxWidth: "100%",
                height: "100%",
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              onChange={(e) => handleAttachment(e)}
              sx={{ width: "100%", height: "100%" }}
              component="label"
              startIcon={<AttachFileIcon />}
            >
              <VisuallyHiddenInput type="file" />
            </Button>
          </Grid>
          <Grid item xs={10.5}>
            <TextField
              type="text"
              multiline={true}
              onKeyDown={(e) => {
                if (e.key == "Enter" && !e.shiftKey) {
                  // prevent default behavior
                  e.preventDefault();
                  sendMessage();
                }
              }}
              placeholder="type a message"
              value={
                sendedMessage?.message?.text ? sendedMessage?.message?.text : ""
              }
              sx={{
                ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                  borderRadius: "0px",
                },
                ".css-18ax2bx-MuiInputBase-input-MuiOutlinedInput-input,.css-aremad":
                  {
                    color: "black",
                    fontSize: "18px",
                  },
                width: "100%",
                backgroundColor: "white",
              }}
              style={{ width: "95%", margin: "0px" }}
              onChange={(e) => {
                setSendedMessage({
                  ...sendedMessage,
                  ticketid: selectedConversation.AHLINEID,
                  message: {
                    ...sendedMessage.message,
                    type: "text",
                    text: e.target.value,
                  },
                });
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <FormButton disabled={disableButton} onClick={sendMessage}>
              Send
            </FormButton>
          </Grid>
        </Grid>
      </>
    );
  }

  return <form id="chat-form">{formContents}</form>;
};

export default ChatForm;
