import * as React from "react";
import { Box, Grid } from "@mui/material";

import { Navigate, Route, Routes } from "react-router-dom";
import Topbar from "./components/Topbar";
import { useSelector } from "react-redux";
import Sidebar from "./components/Sidebar";
import MRBCDefault from "./mrbc/mrbc";
import MeterReadingComponent from "./mrbc/MrbcItems/MeterReading";
import BillCollectionComponent from "./mrbc/MrbcItems/BillCollection";
import BCDashboard from "./mrbc/MrbcItems/BCDashboard";
import MRDashboard from "./mrbc/MrbcItems/MRDashboard";
import ControlsComponent from "./mrbc/MrbcItems/Controls";
import SubUsersComponent from "./subUsers";
import SuperAdminsComponent from "./superAdmin";
import SuperUsersComponent from "./superAdmin/Users";
import SuperPagesComponent from "./superAdmin/Pages";
import TicketsComponent from "./chatTickets";
import TicketListComponent from "./chatTickets/ticketList";
import DashboardListComponent from "./chatTickets/dashboard";
import { SnackbarProvider } from "notistack";
import SimpleSnackbar from "./components/NotificationMessages";
import HumanResoursesComponent from "./humanResource";
import AttendanceComponent from "./humanResource/Attendance";

export default function MainLayout() {
  const userMenu = useSelector((state) => state.menu.userMenu);
  const userAll = useSelector((state) => state.menu.userAll);
  const client = useSelector((state) => state.menu.client);
  // const [client, setClient] = React.useState(null);
  // get the new msg to display as notification
  const notificationmessages = useSelector(
    (state) => state.ticket.notificationmessages
  );

  return (
    <div className="mainDiv">
      <Grid container height={"100%"}>
        {/* display the side bar */}
        <Grid
          item
          lg={0.6}
          md={0.8}
          xs={1}
          sx={{ height: "auto", width: "100%", minHeight: "100vh" }}
        >
          <Sidebar />
        </Grid>
        <Grid item lg={11.4} md={11.2} xs={11}>
          <Box width="5px" className="content" />
          <main className="content">
            {/* display the top bar */}
            <Box className="topbar">
              <Topbar client={client} />
            </Box>
            {/* display all the contents as route with each link */}
            <Box sx={{ overflow: "hidden" }}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to={userMenu[0].AIVALUE} />}
                />
                <Route exact path="spvlvmrbc01" element={<MRBCDefault />} />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01a"
                  element={<MeterReadingComponent />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01b"
                  element={<BillCollectionComponent />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01c"
                  element={<BCDashboard />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01d"
                  element={<MRDashboard />}
                />
                <Route
                  exact
                  path="spvlvmrbc01/spvlvmrbc01e"
                  element={<ControlsComponent />}
                />
                <Route
                  exact
                  path="admindspsub"
                  element={<SubUsersComponent />}
                />
                <Route
                  exact
                  path="superadmin01"
                  element={<SuperAdminsComponent />}
                />
                <Route
                  exact
                  path="superadmin01/superadmin01a"
                  element={<SuperPagesComponent />}
                />
                <Route
                  exact
                  path="superadmin01/superadmin01b"
                  element={<SuperUsersComponent />}
                />
                <Route
                  exact
                  path="superadmin01/superadmin01c"
                  element={<TicketsComponent />}
                />
                <Route
                  exact
                  path="chatickets01"
                  element={<TicketsComponent />}
                />
                <Route
                  exact
                  path="chatickets01/chatickets01a"
                  element={<TicketListComponent client={client} />}
                />
                <Route
                  exact
                  path="chatickets01/chatickets01b"
                  element={<DashboardListComponent client={client} />}
                />
                <Route
                  exact
                  path="humanresources01"
                  element={<HumanResoursesComponent />}
                />
                <Route
                  exact
                  path="humanresources01/humanresources01a"
                  element={<AttendanceComponent />}
                />
              </Routes>
            </Box>
          </main>
        </Grid>
        {/* display the notification for the new messages */}
        {Object.keys(notificationmessages)?.length > 0 && (
          <Grid
            item
            xs={12}
            sx={{
              display: "grid",
              gridTemplateColumns: "45%",
              height: "100%",
              backgroundColor: "red",
              ".go3844575157": {
                backgroundColor: "#8e8b8b",
              },
              ".go946087465": {
                width: "85%",
              },
            }}
          >
            <SnackbarProvider
              maxSnack={5}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <SimpleSnackbar
                msg={notificationmessages}
                userAll={userAll}
                client={client}
              />
            </SnackbarProvider>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
