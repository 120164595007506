import React from "react";
import "../Message.scss";
import { useSelector } from "react-redux";

const TypeText = ({ message }) => {
  const msgSearched = useSelector((state) => state.ticket.msgSearched);
  const searchMsg = useSelector((state) => state.ticket.searchInMsgs);

  return (
    <div
      className="message-text"
      style={{
        padding: "2%",
        maxWidth: "500px",
        width: "60%",
        overflowWrap: "break-word",
        whiteSpace: "pre-line",
      }}
    >
      {message?.message?.messageId === msgSearched ? (
        <span>
          <mark>{message?.message?.text?.slice(0, searchMsg?.length)}</mark>
          {message?.message?.text?.slice(searchMsg?.length)}
        </span>
      ) : (
        message?.message?.text
      )}
    </div>
  );
};

export default TypeText;
