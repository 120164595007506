import React, { useState } from "react";
import FilterReturn from "./filterReturn";

const FilterComponent = (props) => {
  const divisionData = props.divisionData;

  const [filter, setFilter] = useState({
    search: "",
    divisionID: "",
    period: "",
    year: "",
    crID: "",
    round: "41 047",
    status: "All",
    obs: "All",
    editix: "All",
  });

  const [error, setError] = useState({
    period: "",
    year: "",
  });

  const handleChange = (e, item) => {
    setFilter({
      ...filter,
      [item]: e,
    });
  };

  const handlePeriod = (e) => {
    const regex = /^[0-9\b]+$/;
    if ((e === "" || regex.test(e)) && ((e >= 1 && e <= 12) || e === "")) {
      setFilter({
        ...filter,
        period: e,
      });
      setError({
        ...error,
        period: "",
      });
    } else {
      setError({
        ...error,
        period: "Period Field Should Be Only Numbers Between 1 & 12!",
      });
    }
  };

  const handleYear = (e) => {
    const regex = /^[0-9\b]+$/;
    if (
      (e === "" || regex.test(e)) &&
      (e <= new Date().getFullYear() || e === "")
    ) {
      setFilter({
        ...filter,
        year: e,
      });
      setError({
        ...error,
        year: "",
      });
    } else {
      setError({
        ...error,
        year: "Year Should Be Only Numbers!",
      });
    }
  };

  return (
    <FilterReturn
      filter={filter}
      setFilter={setFilter}
      error={error}
      handleChange={handleChange}
      divisionData={divisionData}
      handlePeriod={handlePeriod}
      handleYear={handleYear}
      type={props.type}
      getData={props.getData}
      getPathData={props.getPathData}
      pathType={props.pathType}
      setTypeControl={props.setTypeControl}
      typeControl={props.typeControl}
      wichControl={props.wichControl}
    />
  );
};

export default FilterComponent;
