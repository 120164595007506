import allData from "../../../../../../Services/Data";
import { setUser } from "../../../../../../Services/redux/reducers/userMenuReducer";

function superAccessData(userAll, setData, route, dispatch) {
  allData
    .getData(
      `${userAll?.userid}`,
      `${userAll?.token}`,
      "accessdata",
      { page: route },
      "mrbc/spv/v1/control"
    )
    .then(
      (response) => {
        setData(response);
      },
      (error) => {
        if (error?.response?.status === 401) {
          dispatch(setUser(false));
        }
      }
    );
}

const gettingData = {
  superAccessData,
};

export default gettingData;
