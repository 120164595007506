import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

export default function BasicPopover({ buttonTitle, content, onButtonClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onButtonClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const width = "100%";

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        sx={{ width: width, backgroundColor: "#064987" }}
      >
        {buttonTitle}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          ".css-jx6v87-MuiPaper-root-MuiPopover-paper, .css-1a7gu9p": {
            backgroundColor: "white !important",
          },
          width: width,
          ".css-ihmqnw-MuiModal-root-MuiPopover-root ,.css-jx6v87-MuiPaper-root-MuiPopover-paper, .css-1a7gu9p":
            {
              paddingX: "1%",
              paddingY: "1%",
            },
        }}
      >
        {content}
      </Popover>
    </div>
  );
}
