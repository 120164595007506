import axios from "axios";

import { useNavigate } from "react-router-dom";
import { HTTP_URL } from "../GlobalVar/commonVar";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrPageDesc,
  setSocket,
  setUser,
  setUserAll,
  setUserData,
  setUserInfo,
  setUserMenu,
} from "../redux/reducers/userMenuReducer";

// const API_URL = "https://tms.taqa-energies.com:8443/mmsinternalrest/";

async function login(username, password) {
  return await axios.post(
    HTTP_URL + "/session/v1/loginrequest",
    {
      username: username,
      password: password,
      environment: "test",
      application: "dsp-internal-01",
      platform: "web",
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}

function Logout(client, socket, dispatch, cookies, navigate) {
  // const navigate = useNavigate();
  // const cookies = new Cookies();
  // const socket = useSelector((state) => state.menu.socket);
  // const dispatch = useDispatch();

  localStorage.removeItem("user");

  dispatch(setUser(false));
  cookies.remove("_user");
  dispatch(setUserMenu([]));
  cookies.remove("_userMenu");
  dispatch(setUserData([]));
  cookies.remove("_userData");
  dispatch(setUserAll([]));
  cookies.remove("_userAll");
  dispatch(setUserInfo([]));
  cookies.remove("_userInfo");
  dispatch(setCurrPageDesc([]));
  cookies.remove("_currpagedesc");
  cookies.set("_location", "/main", {
    path: "/",
    expires: new Date(Date.now() + 2592000),
  });
  navigate("/");
  if (client) {
    client.end();
  }
  if (socket) {
    socket.disconnect();
  }
  dispatch(setSocket(null));
  cookies.remove("_socket");
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  Logout,
  getCurrentUser,
};

export default AuthService;
