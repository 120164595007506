import React, { useEffect } from "react";
import Box from "@mui/material/Box";

import "./App.css";
import LoginForm from "./Pages/Common/Login/loginFrm";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import MainLayout from "./Pages/Common/Main/MainLayout";
import MRBCDefault from "./Pages/Common/Main/mrbc/mrbc";
import MeterReadingComponent from "./Pages/Common/Main/mrbc/MrbcItems/MeterReading";
import BillCollectionComponent from "./Pages/Common/Main/mrbc/MrbcItems/BillCollection";
import BCDashboard from "./Pages/Common/Main/mrbc/MrbcItems/BCDashboard";
import MRDashboard from "./Pages/Common/Main/mrbc/MrbcItems/MRDashboard";
import ControlsComponent from "./Pages/Common/Main/mrbc/MrbcItems/Controls";
import SubUsersComponent from "./Pages/Common/Main/subUsers";
import ForgetPasswordComponent from "./Pages/Common/ForgetPassword";
import SuperAdminsComponent from "./Pages/Common/Main/superAdmin";
import SuperUsersComponent from "./Pages/Common/Main/superAdmin/Users";
import SuperPagesComponent from "./Pages/Common/Main/superAdmin/Pages";
import TicketsComponent from "./Pages/Common/Main/chatTickets";
import TicketListComponent from "./Pages/Common/Main/chatTickets/ticketList";
import DashboardListComponent from "./Pages/Common/Main/chatTickets/dashboard";
import Cookies from "universal-cookie";
import HumanResoursesComponent from "./Pages/Common/Main/humanResource";
import AttendanceComponent from "./Pages/Common/Main/humanResource/Attendance";
import { setSocket } from "./Services/redux/reducers/userMenuReducer";
import SocketIO from "./Services/SocketIO";

export const UserContext = React.createContext();

function App() {
  const [theme, colorMode] = useMode();
  const user = useSelector((state) => state.menu.user);
  const socket = useSelector((state) => state.menu.socket);
  const userAll = useSelector((state) => state.menu.userAll);
  const location = useLocation();
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (socket === null && user) {
      const socket2 = SocketIO(userAll?.token);
      dispatch(setSocket(socket2));
    }
  }, [socket]);

  const pages = [
    {
      path: "/",
      element: !user ? (
        <LoginForm />
      ) : (
        <Navigate to={cookies.get("_location")} />
      ),
      childs: [],
    },
    {
      path: "/forgetPassword",
      element: <ForgetPasswordComponent />,
      childs: [],
    },
    {
      path: "/main/*",
      element: user ? <MainLayout /> : <Navigate to="/" />,
      childs: [
        {
          path: "spvlvmrbc01",
          element: user ? <MRBCDefault /> : <Navigate to="/" />,
          childs: [
            {
              path: "spvlvmrbc01a",
              element: user ? <MeterReadingComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01b",
              element: user ? <BillCollectionComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01c",
              element: user ? <BCDashboard /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01d",
              element: user ? <MRDashboard /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "spvlvmrbc01e",
              element: user ? <ControlsComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "admindspsub",
          element: <SubUsersComponent />,
          childs: [],
        },
        {
          path: "superadmin01",
          element: user ? <SuperAdminsComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "superadmin01a",
              element: user ? <SuperPagesComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "superadmin01b",
              element: user ? <SuperUsersComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "superadmin01c",
              element: user ? <TicketsComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "chatickets01",
          element: user ? <TicketsComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "chatickets01a",
              element: user ? <TicketListComponent /> : <Navigate to="/" />,
              childs: [],
            },
            {
              path: "chatickets01b",
              element: user ? <DashboardListComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
        {
          path: "humanresources01",
          element: user ? <HumanResoursesComponent /> : <Navigate to="/" />,
          childs: [
            {
              path: "humanresources01/humanresources01a",
              element: user ? <AttendanceComponent /> : <Navigate to="/" />,
              childs: [],
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (location?.pathname !== "/" && location?.pathname !== undefined) {
      cookies.set("_location", location?.pathname, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    } else {
      cookies.set("_location", "/main", {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
    }
  }, [location?.pathname]);

  return (
    <Box sx={{ height: "100%", backgroundColor: "rgba(247, 245, 245, 0.651)" }}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <Box bgcolor="rgba(247, 245, 245, 0.651)" width="100%" height="100%">
            {/* {user ? ( */}
            <Routes>
              {pages?.map((page, i) => {
                return (
                  <Route
                    // exact={true}
                    key={i}
                    path={page?.path}
                    element={page?.element}
                  >
                    {page?.childs?.map((child1, i) => {
                      return (
                        <Route
                          // exact={true}
                          key={i}
                          path={child1?.path}
                          element={child1?.element}
                        >
                          {child1?.childs?.map((child2, i) => {
                            return (
                              <Route
                                // exact={true}
                                key={i}
                                path={child2?.path}
                                element={child2?.element}
                                action={
                                  child2?.path === cookies.get("_location")
                                }
                              />
                            );
                          })}
                        </Route>
                      );
                    })}
                  </Route>
                );
              })}
            </Routes>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Box>
  );
}

export default App;
