import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import moment from "moment";

const FilterReturn = ({
  filterAttendance,
  handleChanges,
  filteringData,
  getData,
}) => {
  const alignmenmt = {
    display: "flex",
    alignItems: "center",
    ".css-sde65v-MuiInputBase-root-MuiOutlinedInput-root": {
      height: "36.7px",
    },
  };

  const disableSearch = filterAttendance?.employees?.length <= 0;

  return (
    <Grid container sx={alignmenmt}>
      <Grid container sx={{ marginBottom: "1%" }}>
        <Grid item xs={1.8} sx={alignmenmt} container>
          <Grid item xs={5.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Check</InputLabel>
              <Select
                label="Check"
                width="100%"
                value={filterAttendance?.check}
                onChange={(e) =>
                  handleChanges.handleChange(e.target.value, "check")
                }
              >
                <MenuItem key={"all"} value={"all"}>
                  All
                </MenuItem>
                <MenuItem key={"checkin"} value={"checkin"}>
                  Check In
                </MenuItem>
                <MenuItem key={"checkout"} value={"checkout"}>
                  Check Out
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5.5}>
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Break</InputLabel>
              <Select
                label="Check"
                width="100%"
                value={filterAttendance?.break}
                onChange={(e) =>
                  handleChanges.handleChange(e.target.value, "break")
                }
              >
                <MenuItem key={"all"} value={"all"}>
                  All
                </MenuItem>
                <MenuItem key={"breakin"} value={"breakin"}>
                  Break In
                </MenuItem>
                <MenuItem key={"breakout"} value={"breakout"}>
                  Break Out
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Business Unit
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterAttendance.businessUnit?.length <= 0
                  ? []
                  : filterAttendance.businessUnit
              }
              onChange={(e) => handleChanges.handleBUChange(e.target.value)}
              input={<OutlinedInput label="Business Unit" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              {/* <MenuItem value={filteringData.businessUnit?.map((b) => b?.BFBU)}>
                <Checkbox
                  checked={
                    filterAttendance.businessUnit?.length ===
                    filteringData.businessUnit?.length
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem> */}
              {filteringData.businessUnit?.map((unit) => (
                <MenuItem key={unit?.BFBU} value={unit?.BFBU}>
                  <Checkbox
                    sx={{
                      ".css-havevq-MuiSvgIcon-root": {
                        color: "white !important",
                      },
                    }}
                    checked={
                      filterAttendance.businessUnit?.indexOf(unit?.BFBU) > -1
                    }
                  />
                  <ListItemText primary={`${unit?.BFBU} - ${unit?.BUDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">
              Department
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterAttendance?.department?.length <= 0
                  ? []
                  : filterAttendance?.department
              }
              onChange={(e) =>
                handleChanges.handleDepartmentChange(e.target.value)
              }
              input={<OutlinedInput label="Department" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              {filteringData.department?.map((dep) => (
                <MenuItem key={dep.BFDPT} value={dep.BFDPT}>
                  <Checkbox
                    sx={{
                      ".css-havevq-MuiSvgIcon-root": {
                        color: "white !important",
                      },
                    }}
                    checked={
                      filterAttendance?.department?.indexOf(dep?.BFDPT) > -1
                    }
                  />
                  <ListItemText primary={`${dep.BFDPT} - ${dep.DPTDESC}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-multiple-checkbox-label">Position</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              sx={{ width: "100%" }}
              value={
                filterAttendance?.position?.length <= 0
                  ? []
                  : filterAttendance?.position
              }
              onChange={(e) =>
                handleChanges.handlePositionChange(e.target.value)
              }
              input={<OutlinedInput label="Position" />}
              renderValue={(selected) => selected.join(", ")}
              // MenuProps={MenuProps}
            >
              {filteringData.position?.map((position) => (
                <MenuItem key={position?.BFPOST} value={position?.BFPOST}>
                  <Checkbox
                    sx={{
                      ".css-havevq-MuiSvgIcon-root": {
                        color: "white !important",
                      },
                    }}
                    checked={
                      filterAttendance?.position?.indexOf(position?.BFPOST) > -1
                    }
                  />
                  <ListItemText
                    primary={`${position?.BFPOST} - ${position?.POSTDESC}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(new Date())}
              slotProps={{
                textField: {
                  size: "small",
                  id: "fromdate",
                  label: "From Date",
                },
              }}
              format="YYYY-MM-DD"
              value={dayjs(filterAttendance?.fromdate)}
              onChange={(e) =>
                handleChanges.handleChange(
                  moment(e?.$d).format("YYYY-MM-DD"),
                  "fromdate"
                )
              }
              sx={{
                ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={1.2 / 5}></Grid>
        <Grid item xs={1.8} sx={alignmenmt}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={dayjs(new Date())}
              minDate={dayjs(filterAttendance?.fromdate)}
              slotProps={{
                textField: { size: "small", id: "todate", label: "To Date" },
              }}
              sx={{
                ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                  color: "black",
                },
                width: "100%",
              }}
              format="YYYY-MM-DD"
              value={dayjs(filterAttendance?.todate)}
              onChange={(e) =>
                handleChanges.handleChange(
                  moment(e?.$d).format("YYYY-MM-DD"),
                  "todate"
                )
              }
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10.5} sx={alignmenmt}>
          <Autocomplete
            multiple
            id="tags-filled"
            options={filteringData?.employees?.map(
              (emp) => emp?.ABLINEID + " - " + emp?.ABFULLNAME
            )}
            onChange={(event, value) =>
              handleChanges.handleEmployeesChange(value)
            }
            value={
              filterAttendance?.employees?.length > 0
                ? filterAttendance?.employees
                : []
            }
            freeSolo
            disableClearable
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Employees"
                placeholder="Select.."
              />
            )}
            sx={{
              width: "100% !important",
              ".css-16imkoi-MuiAutocomplete-root, .MuiAutocomplete-tag": {
                color: "black !important",
              },
              ".css-e2o3q6-MuiButtonBase-root-MuiChip-root, .MuiChip-deleteIcon":
                {
                  color: "black !important",
                },
            }}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1} sx={alignmenmt}>
          <Button
            sx={{
              backgroundColor: !disableSearch ? "#064987" : "#afabab",
              width: "100%",
            }}
            disabled={disableSearch}
            onClick={() => getData(filterAttendance)}
          >
            <SearchIcon sx={{ color: "white" }} />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterReturn;
