export const OBS = [
  {
    code: "All",
    description: "All",
  },
  {
    code: "+",
    description: "جديد",
  },
  {
    code: "0",
    description: "مسكون",
  },
  {
    code: "4",
    description: "مهدم",
  },
  {
    code: "5",
    description: "HIV",
  },
  {
    code: "9",
    description: "مقفل",
  },
  {
    code: "A",
    description: "محروق",
  },
  {
    code: "B",
    description: "يقلب / 10",
  },
  {
    code: "C",
    description: "معطل",
  },
  {
    code: "D",
    description: "مفقود",
  },
  {
    code: "E",
    description: "مهجور",
  },
  {
    code: "F",
    description: "مجهول",
  },
  {
    code: "G",
    description: "غير موصول",
  },
  {
    code: "H",
    description: "مكسور",
  },
  {
    code: "I",
    description: "يقلب / 100",
  },
  {
    code: "J",
    description: "عداد ملغى",
  },
  {
    code: "Q",
    description: "تعليق على الشبكة",
  },
  {
    code: "R",
    description: "تعليق داخل غرفة العدادات",
  },
  {
    code: "S",
    description: "عداد لا يعمل بشكل صحيح (MV)",
  },
  {
    code: "T",
    description: "عداد غير موصول على الفاصل (MV)",
  },
  {
    code: "U",
    description: "عداد غير موصول بشكل صحيح (MV)",
  },
  {
    code: "V",
    description: "وجود سائل تحت الفاصل",
  },
  {
    code: "W",
    description: "تاكل على الفاصل",
  },
  {
    code: "X",
    description: "تلاعب بالعداد",
  },
  {
    code: "Y",
    description: "المؤشر لا يسجل مقطوعية",
  },
  {
    code: "Z",
    description: "غطاء عداد مكسور",
  },
];

export default {
  OBS,
};
