import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterComponent from "../Filter";
import { useDispatch, useSelector } from "react-redux";
import allData from "../../../../../../Services/Data";
import gettingData from "../Services/supervisorsAccess";
import MIPopUp from "../map&imagePopUp/MIPopup";
import CustomizedSnackbars from "../../../components/NotificationAlert";
import AlertDialog from "../../../components/AlertDialog";
import { setUser } from "../../../../../../Services/redux/reducers/userMenuReducer";

const ControlsComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [data, setData] = useState([]);
  const [controlData, setControlData] = useState([]);
  const [openRoundData, setOpenRoundData] = useState([]);
  const divisionData = data?.data?.controldata?.filter(
    (div) => div.AGFIELD === "divid"
  );
  const [typeControl, setTypeControl] = useState("");
  const [open, setOpen] = useState(false);
  const [openRound, setOpenRound] = useState(false);
  const dispatch = useDispatch();

  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar({
      open: false,
      message: "",
      type: "",
    });
  };

  useEffect(() => {
    gettingData.superAccessData(userAll, setData, "spvlvmrbc01e", dispatch);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setOpenRound(false);
  };

  function getControlsData(req, x, isLocation) {
    allData
      .getData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        req,
        x,
        "mrbc/spv/v1/control"
      )
      .then(
        (response) => {
          setControlData(response);
          if (isLocation === true) {
            setOpen(true);
          } else {
            setOpenRound(false);
            setOpenSnackBar({
              open: true,
              message: "Round Opened Successfully!",
              type: "success",
            });
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
        }
      );
  }

  const controlsJSON = [
    {
      title: "Open Round",
      content: (
        <Grid>
          <FilterComponent
            divisionData={divisionData}
            getData={(x) => {
              setOpenRoundData(x);
              setOpenRound(true);
            }}
            type={"controls"}
            typeControl={typeControl}
            setTypeControl={setTypeControl}
            wichControl={"openRound"}
          />
          <AlertDialog
            open={openRound}
            handleClose={handleClose}
            title={"Are You Sure?"}
            buttonTitle={"Yes"}
            buttonClick={() =>
              getControlsData("openround", openRoundData, false)
            }
          />
          <CustomizedSnackbars
            open={openSnackBar.open}
            handleClose={handleCloseSnackBar}
            severity={openSnackBar.type}
            message={openSnackBar.message}
          />
        </Grid>
      ),
    },
    {
      title: "Get Location",
      content: (
        <Grid>
          <FilterComponent
            divisionData={divisionData}
            getData={(x) => getControlsData("crlivecoordinates", x, true)} //crlivecoordinates
            type={"controls"}
            typeControl={typeControl}
            setTypeControl={setTypeControl}
            wichControl={"getLocation"}
            setOpen={setOpen}
          />
          <MIPopUp
            open={open}
            handleClose={handleClose}
            isMap={true}
            openPath={false}
            detailsData={controlData?.data?.crlivecoordinates}
          />
        </Grid>
      ),
    },
  ];

  return (
    <Box>
      {controlsJSON.map((data, index) => (
        <Box key={index}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {data.title}
          </Grid>
          {data.content}
        </Box>
      ))}
    </Box>
  );
};

export default ControlsComponent;
