import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import FilterTicketsComponent from "../Filter";
import MQTT from "../../../../../Services/MQTT";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import ECharts from "../../components/ECharts";
import {
  getDoughnutOptions,
  getFunnelChartOptions,
  getBarColumnsOptions,
} from "./ChartsOptions";
import TicketsInfoBoxes from "./BoxInfo/TicketsInfoBoxes";
import StarsReview from "./BoxInfo/StarsReviews";
import moment from "moment";
import AlertDialog from "../../components/AlertDialog";
import DetailsPopUp from "./DetailsPopUp";
import { MQTT_URL } from "../../../../../Services/GlobalVar/commonVar";
import useMqttConnect from "../../../../../Services/MQTT/MQTTConnect";

const initialPie = { data: [], error: undefined, loading: true };

const DashboardListComponent = ({ client }) => {
  const userAll = useSelector((state) => state.menu.userAll);
  const dispatch = useDispatch();
  // use state to set all the data from dashboard
  const [allData, setAllData] = useState([]);
  // use state to set the status data for the pie
  const [pieStatusData, setPieStatusData] = useState(initialPie);
  // use state to set is assigned or not data for the line
  const [isAssignedData, setIsAssignedData] = useState(initialPie);
  // use state to set is joined or not data for the line
  const [joinAssignedData, setJoinAssignedData] = useState(initialPie);
  // use state to set the last 10 tickets closure time for the pyramid
  const [ticketTimeData, setTicketTimeData] = useState(initialPie);
  // use state to set the review stars and count to get the review dash
  const [reviewData, setReviewData] = useState([
    {
      AHREVIEWRATE: 1,
      RATECOUNT: 0,
    },
    {
      AHREVIEWRATE: 2,
      RATECOUNT: 0,
    },
    {
      AHREVIEWRATE: 3,
      RATECOUNT: 0,
    },
    {
      AHREVIEWRATE: 4,
      RATECOUNT: 0,
    },
    {
      AHREVIEWRATE: 5,
      RATECOUNT: 0,
    },
  ]);

  const [openDialog, setOpenDialog] = useState(false);
  const [detailsData, setDetailsData] = useState([]);

  var today = new Date();
  today?.setDate(today.getDate() - 14);

  const [filterTickets, setFilterTickets] = useState({
    AHLINEID: "",
    AHUSERID: "",
    AHJDECASEID: "",
    AHASSIGNED: "",
    AHASSIGNEE: "",
    AHSTATUS: "",
    fromdate: moment(today).format("YYYY-MM-DD"),
    todate: moment(new Date()).format("YYYY-MM-DD"),
  });

  // publish the dashboard filter to get the data
  const getFilteredData = () => {
    // set each loading in the charts to true
    setPieStatusData((prev) => ({ ...prev, loading: true }));
    setIsAssignedData((prev) => ({ ...prev, loading: true }));
    setJoinAssignedData((prev) => ({ ...prev, loading: true }));
    setTicketTimeData((prev) => ({ ...prev, loading: true }));
    // publish filter
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/ticketsdash",
        qos: 0,
        payload: JSON.stringify({
          request: "spvdash001",
          userid: userAll?.userid,
          // ticketid: ticketid,
          spvdash001: filterTickets,
        }),
      },
      client
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
    setDetailsData([]);
  };

  const getSpecificData = (x) => {
    setOpenDialog(true);
    // publish filter
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/ticketsdash",
        qos: 0,
        payload: JSON.stringify({
          request: "spvdash001details",
          userid: userAll?.userid,
          spvdash001details: {
            searchFilter: filterTickets,
            dash: x?.spvdash001,
            [x?.spvdash001]: {
              [x?.filter]: x?.status,
            },
          },
        }),
      },
      client
    );
  };

  // define some variables for the data
  var i;
  var j;
  var k;
  var m;
  var _statusData = [];
  var _isAssignedData = [];
  var _tickeTimeData = [];

  // 3 : case currently joined per user
  // 4 : assigned but not nessecerly subscribed

  const liveUpdate = () => {
    // hear the dashboard data msg
    client.on("message", (topic, message) => {
      var string = new TextDecoder().decode(message);
      const json = JSON.parse(string);

      switch (json?.request) {
        // Filtered Data
        case "spvdash001":
          // set all the data in the state
          setAllData(json?.reply?.[0]);
          for (i = 0; i < json?.reply?.[0]?.length; i++) {
            // for loop for the status data
            for (j = 0; j < json?.reply?.[0]?.[0]?.length; j++) {
              _statusData[j] = {
                name: json?.reply?.[0]?.[0]?.[j]?.UVDESC,
                value: json?.reply?.[0]?.[0]?.[j]?.COUNT,
                status: json?.reply?.[0]?.[0]?.[j]?.AHSTATUS,
                filter: "AHSTATUS",
                spvdash001: "spvdash001a",
              };
            }
            // for loop for the is assigned or not data
            for (k = 0; k < json?.reply?.[0]?.[1]?.length; k++) {
              _isAssignedData[k] = {
                name:
                  json?.reply?.[0]?.[1]?.[k]?.AHASSIGNED === "N"
                    ? "Not Assigned"
                    : "Assigned",
                value: json?.reply?.[0]?.[1]?.[k]?.COUNT,
                status: json?.reply?.[0]?.[1]?.[k]?.AHASSIGNED,
                filter: "AHASSIGNED",
                spvdash001: "spvdash001b",
              };
            }
            // for loop for the ticket time closure data
            for (m = 0; m < json?.reply?.[0]?.[4]?.length; m++) {
              _tickeTimeData[m] = {
                value: json?.reply?.[0]?.[4]?.[m]?.DURATIONHR,
                name: json?.reply?.[0]?.[4]?.[m]?.AHLINEID,
                status: json?.reply?.[0]?.[4]?.[m]?.AHLINEID,
              };
            }
          }
          // merge the (is assigned or not) objects with (is joined or not) objects with the same user id
          const joinAssignedMerged = json?.reply?.[0]?.[3]?.map((item) => {
            const matchedObject = json?.reply?.[0]?.[2]?.find(
              (obj) => obj.AIUSERID === item.AHASSIGNEE
            );
            return { ...item, ...matchedObject };
          });
          // ascending sort for the time ticket by duration
          const ticketTimeSorting = _tickeTimeData?.sort(
            (a, b) => parseFloat(b.DURATIONHR) - parseFloat(a.DURATIONHR)
          );
          // options for each chart
          const statusOptions = getDoughnutOptions(
            _statusData,
            "Number of tickets per status"
          );
          const isAssignedOptions = getDoughnutOptions(
            _isAssignedData,
            "Number of assigned/unassigned tickets"
          );
          const joinedAssignedOptions = getBarColumnsOptions(
            [
              {
                name: "Assigned",
                type: "bar",
                data: joinAssignedMerged?.map((data) => data?.ASSIGNCOUNT),
                status: "hi",
              },
              {
                name: "Joined",
                type: "bar",
                data: joinAssignedMerged?.map((data) => data?.JOINCOUNT),
              },
            ],
            joinAssignedMerged?.map(
              (data) => `${data?.AHASSIGNEE}- ${data?.ABFULLNAME}`
            ),
            "Number of joined/assigned ticktes per agent"
          );

          const ticketTimeOptions = getFunnelChartOptions(
            ticketTimeSorting,
            json?.reply?.[0]?.[4]?.map((data) => data?.AHLINEID)
          );
          // update the initial reviews by the coming reviews to display the stars with 0 count
          const changeReviews = () => {
            const newState = reviewData?.map((obj) => {
              // 👇️ if id equals 2, update country property
              if (
                json?.reply?.[0]?.[6]?.filter(
                  (data) => data?.AHREVIEWRATE === obj?.AHREVIEWRATE
                )?.length > 0
              ) {
                return {
                  ...obj,
                  RATECOUNT: json?.reply?.[0]?.[6]?.filter(
                    (data) => data?.AHREVIEWRATE === obj?.AHREVIEWRATE
                  )?.[0]?.RATECOUNT,
                };
              }
              return obj;
            });
            setReviewData(newState);
          };
          // call the change initial review with the coming ones function
          changeReviews();
          // set all the states to stop loading with the right data
          setPieStatusData({
            data: statusOptions,
            error: false,
            loading: false,
          });
          setIsAssignedData({
            data: isAssignedOptions,
            error: false,
            loading: false,
          });
          setJoinAssignedData({
            data: joinedAssignedOptions,
            error: false,
            loading: false,
          });
          setTicketTimeData({
            data: ticketTimeOptions,
            error: false,
            loading: false,
          });

          break;

        case "spvdash001details":
          console.log(json);
          setDetailsData(json?.reply);
          break;

        default:
          break;
      }
    });
  };

  const mqttConnect = useMqttConnect();

  // Connecting & Subscribe on dashboard page
  useEffect(() => {
    // if (client) {
    //   client.setMaxListeners(100);
    //   client.on("connect", () => {
    //     MQTT.mqttSub(`${userAll?.userid}/dspenduser/v1/tickets`, client);
    //     MQTT.mqttSub(`dspenduser/v1/tickets/ccagent`, client);
    //   });
    //   client.on("error", (err) => {
    //     console.error("Connection error: ", err);
    //     client.end();
    //   });
    //   client.on("reconnect", () => {
    //     console.log("Reconnecting");
    //   });
    //   liveUpdate();
    // } else {
    //   MQTT.mqttConnect(
    //     MQTT_URL,
    //     {
    //       clientId: `CCAGENT_${userAll?.userid}_${uuid()}`,
    //       username: "test",
    //       password: userAll?.token,
    //     },
    //     setClient
    //   );
    // }
    MQTT.MqttConnection(client, userAll, liveUpdate, null, mqttConnect);
    return () => {};
  }, [client, dispatch]);

  return (
    <Box>
      <Grid>
        {/* display the filter component with the fuction to get data as prop */}
        <FilterTicketsComponent
          getData={(x) => getFilteredData(x)}
          filterTickets={filterTickets}
          setFilterTickets={setFilterTickets}
        />
      </Grid>
      {/* dispaly dashboard when we get the data */}
      {allData?.[5]?.length > 0 && (
        <Grid>
          <Grid container>
            <Grid
              sx={{
                marginY: "3%",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {/* display the general information boxes */}
              {allData?.[5]?.map((data, i) => {
                return <TicketsInfoBoxes key={i} data={data} />;
              })}
            </Grid>
            {/* <ECharts loading={pieData.loading} options={pieData.data} /> */}
          </Grid>
          <Grid sx={{ padding: "3%" }}>
            <Grid container sx={{ marginBottom: "5%" }}>
              <Grid
                item
                xs={3}
                sx={{ padding: "2%" }}
                boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              >
                {/* display the status doughnut */}
                <ECharts
                  loading={pieStatusData.loading}
                  options={pieStatusData.data}
                  getSpecificData={getSpecificData}
                  type="status"
                />
              </Grid>
              <Grid item xs={1 / 2}></Grid>
              <Grid
                item
                xs={3}
                sx={{ padding: "2%" }}
                boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              >
                {/* display is assigned or not doughnut */}
                <ECharts
                  loading={isAssignedData.loading}
                  options={isAssignedData.data}
                  getSpecificData={getSpecificData}
                  type="isAssignedData"
                />
              </Grid>
              <Grid item xs={1 / 2}></Grid>
              <Grid
                item
                xs={5}
                sx={{ padding: "2%" }}
                boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              >
                {/* display the number of joined and assigned for each agent */}
                <ECharts
                  loading={joinAssignedData.loading}
                  options={joinAssignedData.data}
                  getSpecificData={getSpecificData}
                  type="joinAssignedData"
                />
                {/* <BasicTable /> */}
              </Grid>
            </Grid>
            {/* <Grid container sx={{ width: "100%" }}>
              <Grid
                item
                xs={6}
                sx={{ padding: "2%" }}
                boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              >
                <ECharts
                  loading={ticketTimeData.loading}
                  options={ticketTimeData.data}
                  getSpecificData={getSpecificData}
                  type="ticketTimeData"
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={5}
                sx={{ padding: "2%" }}
                boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
              >
                <Grid
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    marginBottom: "8%",
                  }}
                >
                  Review rating distribution
                </Grid>
                {reviewData?.map((data) => (
                  <StarsReview
                    key={data?.AHREVIEWRATE}
                    reviewData={reviewData}
                    data={data}
                  />
                ))}
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      )}
      {openDialog && (
        <AlertDialog
          open={openDialog}
          handleClose={handleClose}
          title={"Details"}
          content={<DetailsPopUp detailsData={detailsData} />}
          buttonTitle={""}
          // buttonClick={() => setOpenSure(true)}
          // buttonDisabled={buttonDisabled}
        />
      )}
    </Box>
  );
};

export default DashboardListComponent;
