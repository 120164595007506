export const PageJson = [
  {
    boxName: "Tickets",
    littleBoxes: [
      // {
      //   boxLink: "/main/chatickets01/chatickets01a/chat",
      //   boxTitle: "Chat",
      //   boxDescription: "Communicate with our customer!",
      //   boxIcon: "Chat",
      // },
      {
        boxLink: "/main/chatickets01/chatickets01a",
        boxTitle: "Ticket List",
        boxDescription: "Communicate with our customer!",
        boxIcon: "ListAlt",
      },
      {
        boxLink: "/main/chatickets01/chatickets01b",
        boxTitle: "Dashboard",
        boxDescription: "Dashboard for tickets!",
        boxIcon: "Dashboard",
      },
    ],
  },
];
