import * as React from "react";
import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";

import { tokens } from "../../../../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import "react-pro-sidebar/dist/css/styles.css";
import SidebarList from "./sidebarList/SideBarList";
import styles from "./topbar.module.scss";

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const userMenu = useSelector((state) => state.menu.userMenu);
  const userInfo = useSelector((state) => state.menu.userInfo);

  return (
    <Box
      height="100%"
      sx={{
        ".pro-sidebar.collapsed": {
          width: "100%",
        },
        "& .pro-sidebar-inner": {
          background: `#064987 !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "lightblue !important",
        },
        "& .pro-menu-item.active": {
          color: "lightgreen !important",
        },
      }}
    >
      <ProSidebar collapsed={!isCollapsed}>
        {isCollapsed && (
          <Grid
            sx={{
              height: "120px",
              width: "100%",
              padding: "10%",
              backgroundColor: "white",
            }}
          >
            <img alt="" src="/reglogo.png" className={styles.logoSide} />
          </Grid>
        )}
        <Menu iconShape="square">
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={!isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
              width: "100%",
            }}
          >
            {isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6" color="white">
                    {userInfo.ABFULLNAME}
                  </Typography>
                  <Typography variant="h7" color={colors.grey[200]}>
                    {userInfo.ABADDRESS1}
                  </Typography>
                </Box>

                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          <Divider textAlign="left">Pages </Divider>
          <SidebarList userMenu={userMenu} />
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
