import React from "react";
import { Button, Grid, TextField } from "@mui/material";
import MQTT from "../../../../../../../../Services/MQTT";
import { useSelector } from "react-redux";
import CheckAttachCaseDetails from "./CheckDetails";

const AttachCaseID = ({
  caseID,
  setCaseID,
  client,
  userAll,
  info,
  conversation,
}) => {
  const checkCase = useSelector((state) => state.ticket.checkCase);

  // attach case id
  const onAttach = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "attachcase",
          userid: userAll?.userid,
          ticketid: info?.AHLINEID,
          casenbr: caseID?.value,
        }),
      },
      client
    );
  };

  const onCheck = (e) => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "checkcase",
          userid: userAll?.userid,
          ticketid: info?.AHLINEID,
          casenbr: e,
          subid: conversation?.AHSUBID,
        }),
      },
      client
    );
  };

  // check if case id is entered correctly and set state
  // 20033826
  const onChangeCaseID = (e) => {
    const regex = /^[0-9\b]+$/;
    setCaseID({
      ...caseID,
      value: e,
    });
    if (e !== "" && regex.test(e) && e?.length === 8) {
      setCaseID({
        value: e,
        error: "",
      });
      if (e.length === 8) {
        onCheck(e);
        if (!checkCase.reply)
          setCaseID({
            value: e,
            error: "Case does not exist!!",
          });
      }
    } else {
      setCaseID({
        value: "",
        error: "Should be 8 numbers exactly!!",
      });
    }
  };

  return (
    <Grid
      sx={{
        // marginTop: "7%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
      container
    >
      <Grid
        item
        xs={8}
        sx={{
          ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
            color: "black",
          },
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          fullWidth
          type="number"
          id="jdeid"
          label="JDE Case ID"
          variant="outlined"
          autoComplete="off"
          placeholder="Enter JDE Case ID.."
          defaultValue={caseID?.value ? caseID?.value : ""}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 8);
          }}
          onChange={(e) => onChangeCaseID(e.target.value)}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <Button
          sx={{ width: "100%", backgroundColor: "#064987", color: "white" }}
          disabled={
            checkCase?.reply === false ||
            caseID?.error !== "" ||
            caseID?.value === ""
          }
          onClick={onAttach}
        >
          Attach
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ color: "#a40303", fontSize: "small" }}>
        {caseID.error}
      </Grid>
      {checkCase?.reply && (
        <Grid item xs={12}>
          <CheckAttachCaseDetails checkCase={checkCase?.result} />
        </Grid>
      )}
    </Grid>
  );
};

export default AttachCaseID;
