import React from "react";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/material";
import "chart.js/auto"; // ADD THIS

export default function PieChart({ width, maxHeight, data }) {
  return (
    <Box
      width={width}
      maxHeight={maxHeight}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Pie data={data} />
    </Box>
  );
}
