export const TitlesJSON = [
  {
    description: "Mr.",
    value: "Mr",
  },
  {
    description: "Mrs.",
    value: "Mrs",
  },
];

export default {
  TitlesJSON,
};
