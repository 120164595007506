import React from "react";
import "../Message.scss";
import CompositeMessage from "../CompositeMessage";

const TypeComposite = ({ message, type }) => {
  const composite =
    type === "composite"
      ? message?.message?.composite
      : message?.message?.composite1;

  return (
    <div className="message-text">
      <CompositeMessage composite={composite} />
    </div>
  );
};

export default TypeComposite;
