import React from "react";
import { Box, Button, Grid } from "@mui/material";
import { Buffer } from "buffer";

const MediaPreview = ({ sendedMessage }) => {
  // take the media from sended message
  const msg = sendedMessage?.message?.media;
  // if its not from the gallery, transform the msg buffer to string
  const str = Buffer?.from(msg?.buffer, "base64").toString("base64");

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Grid sx={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12}>
            {msg?.type === "image" && (
              <Grid>
                <img
                  src={`data:${msg?.type}/${msg?.type};base64,` + str}
                  alt={msg?.buffer}
                  style={{
                    width: "100%",
                    height: "300px",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            )}
            {msg?.type === "video" && (
              <video
                width="100%"
                height="300"
                style={{
                  width: "100%",
                  borderRadius: "14px 14px 14px 0px",
                }}
                controls
              >
                <source
                  src={`data:${msg?.type}/${msg?.type};base64,` + str}
                  type="video/mp4"
                />
              </video>
            )}
            {msg?.type === "application" && (
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {msg?.extension === "pdf" && (
                  <Grid item xs={12}>
                    <img src="./pdf.png" />
                  </Grid>
                )}
                {msg?.extension === "docx" && (
                  <Grid item xs={12}>
                    <img src="./doc.png" />
                  </Grid>
                )}
                {msg?.extension === "xls" && (
                  <Grid item xs={12}>
                    <img src="./xls-file.png" />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sx={{ marginTop: "2%", color: "black", fontWeight: "bold" }}
                >
                  {msg?.name}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Button
            sx={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#b7b7b7",
              margin: "3%",
            }}
            href={msg?.link}
            target="_blank"
          >
            Open
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MediaPreview;
