export const Status = [
  { text: "All", value: "All" },
  { text: "Done", value: "D" },
  { text: "Unvisited", value: "N" },
  { text: "Visited Once", value: "V1" },
  { text: "Visited Twice", value: "V2" },
];

export default {
  Status,
};
