import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MQTT from "../../../../../../../../Services/MQTT";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

const Subscriptions = ({
  subs,
  topic,
  span,
  userAll,
  info,
  client,
  conversation,
}) => {
  // publish ticket update subscription on selecting a subsc
  const onSelect = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "ticketupdatesubscription",
          userid: userAll?.userid,
          ticketid: info?.AHLINEID,
          subid: subs?.id,
        }),
      },
      client
    );
  };

  const conditions =
    (subs?.flag01 === "S" && conversation?.AHSUBID === subs?.id) ||
    conversation?.AHSUBID === subs?.id;

  return (
    <Accordion
      sx={{
        backgroundColor: !conditions
          ? "#cbcbc9!important"
          : "#e8b337 !important",
        color: "black",
        fontWeight: "bold !important",
        ".css-19hn6xk-MuiAccordionSummary-expandIconWrapper": {
          color: "black",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography sx={{ display: "flex", width: "100%" }}>
          {conditions ? (
            <RadioButtonCheckedIcon sx={{ marginRight: "3%" }} />
          ) : (
            <RadioButtonUncheckedIcon sx={{ marginRight: "3%" }} />
          )}
          {subs?.fullname}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          item
          xs={9}
          sx={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            paddingLeft: "2%",
          }}
        >
          <Grid
            sx={{
              ".css-1axpip2-MuiGrid-root": {
                textAlign: "left",
                marginY: "5%",
              },
            }}
          >
            <Grid sx={topic}>
              Branch: <span style={span}>{subs?.branch}</span>
            </Grid>
            <Grid sx={topic}>
              Installation: <span style={span}>{subs?.installation}</span>
            </Grid>
            <Grid sx={topic}>
              Meter: <span style={span}>{subs?.meter}</span>
            </Grid>
          </Grid>
        </Grid>
        {!conditions && (
          <Grid container>
            <Button
              onClick={onSelect}
              sx={{
                width: "100%",
                backgroundColor: "rgb(6, 73, 135)",
                color: "white",
                marginTop: "2%",
              }}
            >
              Select
            </Button>
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default Subscriptions;
