/* Created by Dhaval Laiya */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as echarts from "echarts";
import cx from "classnames";

function ECharts(props) {
  const { options, style, className, loading, message, getSpecificData } =
    props;
  const [chart, setChart] = useState(null);
  const chartRef = useRef();

  const resizeObserver = new window.ResizeObserver((entries) => {
    entries.map(({ target }) => {
      const instance = echarts.getInstanceByDom(target);
      if (instance) {
        instance.resize();
        instance.on("click", function (params) {
          console.log(params);
          if (props?.type === "status" || props?.type === "isAssignedData") {
            getSpecificData(params?.data);
            // Print name in console
          } else if (props?.type === "joinAssignedData") {
            if (params?.componentIndex === 0) {
              getSpecificData({
                spvdash001: "spvdash001c",
                status: params?.name?.split("-")?.[0],
                filter: "AHASSIGNEE",
              });
            } else {
              getSpecificData({
                spvdash001: "spvdash001c",
                status: params?.name?.split("-")?.[0],
                filter: "AIUSERID",
              });
            }
          }
        });
      }
    });
  });

  useEffect(() => {
    const chart = echarts.init(chartRef.current, "westeros"); // echarts theme
    chart.setOption({ ...options, resizeObserver }, true); // second param is for 'noMerge'
    setChart(chart);
    if (resizeObserver) resizeObserver.observe(chartRef.current);
  }, [options]);

  useEffect(() => {
    if (!chart) {
      return;
    }
    if (loading) {
      chart.showLoading();
      return;
    }

    chart.hideLoading();
  }, [chart, loading]);

  useEffect(() => {
    if (chart && options && message) {
      chart.clear();
    }
  }, [message]);

  const newStyle = {
    height: 500,
    ...style,
  };

  return (
    <div className="echarts-parent position-relative">
      <div
        ref={chartRef}
        style={newStyle}
        className={cx("echarts-react", className)}
      />
      {message ? <div className="no-data">{message}</div> : null}
    </div>
  );
}

ECharts.propTypes = {
  loading: PropTypes.bool,
  options: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  message: PropTypes.any,
};

export default React.memo(ECharts);
