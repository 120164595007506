import * as React from "react";

import "./ConversationJoin.scss";
import { Avatar, Box, Grid } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import TimelineComponent from "../../../../components/Timeline";

const ConversationJoin = ({ conversation }) => {
  // some styling
  const topic = {
    color: "black",
    fontWeight: "600",
  };

  const span = {
    color: "#064987",
  };

  // get ticket details and devide it to members / history / info
  const ticketdetails = useSelector((state) => state.ticket.ticketdetails);
  const ticketdetailsRef = React.useRef();
  ticketdetailsRef.current = ticketdetails;

  const members = ticketdetailsRef.current?.members;
  const history = ticketdetailsRef.current?.ticketHistory;
  const info = ticketdetailsRef.current?.ticketInfo;

  // choose the status of the case and its color
  const status =
    (info?.AHSTATUS === "N" && "New") ||
    (info?.AHSTATUS === "U" && "Under Process") ||
    (info?.AHSTATUS === "C" && "Closed");

  const statusColor = {
    color:
      (conversation?.AHSTATUS === "N" && "green") ||
      (conversation?.AHSTATUS === "U" && "#939300") ||
      (conversation?.AHSTATUS === "C" && "red"),
  };

  return (
    <Box
      sx={{
        textAlign: "left",
        padding: "5%",
        height: "100%",
      }}
    >
      <Grid
        container
        sx={{
          textAlign: "center",
          width: "100%",
          padding: "2%",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          borderRadius: "7.5px",
        }}
      >
        <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={conversation?.ABTEXT1 ? conversation?.ABTEXT1 : "./avatar.jpg"}
            alt="avatar"
            style={{
              width: "100%",
              padding: "3%",
            }}
          />
        </Grid>
        {/* CONTACT INFORMATION */}
        <Grid
          item
          xs={9}
          sx={{
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            paddingLeft: "2%",
          }}
        >
          <Grid
            sx={{
              ".css-1axpip2-MuiGrid-root": {
                textAlign: "left",
                marginY: "5%",
              },
            }}
          >
            <Grid sx={topic}>
              Full Name: <span style={span}>{conversation?.ABFULLNAME}</span>
            </Grid>
            <Grid sx={topic}>
              Email Address: <span style={span}>{conversation?.ABEMAIL}</span>
            </Grid>
            <Grid sx={topic}>
              Phone Number:{" "}
              <span style={span}>
                {conversation?.ABPHONECODE} {conversation?.ABPHONEPREFIX}{" "}
                {conversation?.ABPHONENBR}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* CURRENT CASE */}
      <Grid
        container
        sx={{
          padding: "5%",
          marginY: "5%",
          boxShadow:
            " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          borderRadius: "7.5px",
        }}
      >
        <Grid item xs={3.8}>
          <Grid sx={topic}>Creating Date</Grid>
          <Grid sx={topic}>Case</Grid>
          <Grid sx={topic}>Jde Case ID</Grid>
          <Grid sx={topic}>Status</Grid>
        </Grid>
        <Grid item xs={0.4}>
          <Grid sx={topic}>:</Grid>
          <Grid sx={topic}>:</Grid>
          <Grid sx={topic}>:</Grid>
          <Grid sx={topic}>:</Grid>
        </Grid>
        <Grid item xs={7.8}>
          <Grid sx={span}>
            {info?.AHCREATEDATETIME
              ? moment(info?.AHCREATEDATETIME).format("DD-MM-YYYY hh:mm a")
              : ""}
          </Grid>
          <Grid sx={span}>{info?.AHISSUE ? info?.AHISSUE : ""}</Grid>
          <Grid sx={span}>
            {info?.AHJDECASEID ? info?.AHJDECASEID : "Not attached!"}
          </Grid>
          <Grid sx={statusColor}>{status}</Grid>
        </Grid>
      </Grid>

      {members?.length > 0 && (
        <Grid
          sx={{
            padding: "5%",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            borderRadius: "7.5px",
          }}
        >
          <Grid sx={topic}>Members</Grid>
          <Grid sx={{ marginTop: "5%" }}>
            {members?.map((member) => (
              <Grid
                key={member.ABLINEID}
                container
                sx={{
                  marginBottom: "2%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={1.5}>
                  <Avatar alt="./avatar.jpg" src={member.ABTEXT1} />
                </Grid>
                <Grid item xs={0.2}></Grid>
                <Grid item xs={10.3} sx={span} style={{ fontWeight: "bold" }}>
                  {member.ABFULLNAME}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {history?.length > 0 && (
        <Grid sx={{ marginTop: "5%" }}>
          <Grid sx={{ color: "black", fontWeight: "bold", fontSize: "large" }}>
            Ticket's Timeline
          </Grid>
          <TimelineComponent history={history} />
        </Grid>
      )}
    </Box>
  );
};

export default ConversationJoin;
