import { configureStore } from "@reduxjs/toolkit";
import menuReducer from "./reducers/userMenuReducer";
import ticketsReducer from "./reducers/ticketsReducer";
import HRReducer from "./reducers/HRReducer";

const store = configureStore({
  reducer: {
    menu: menuReducer,
    ticket: ticketsReducer,
    hr: HRReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["menu/setSocket", "menu/setClient"],
        ignoredPaths: ["menu.socket", "menu.client"],
      },
    }),
});

export default store;
