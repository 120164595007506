import { Box, Grid } from "@mui/material";
import React from "react";
import { HRJson } from "./HRJson";
import BigBoxItems from "../components/BoxItems/BigBoxItems";

const HumanResoursesComponent = () => {
  return (
    <Box margin={"3%"}>
      {HRJson.map((data, index) => (
        <Box marginY={"3%"} key={index}>
          <Grid
            sx={{
              backgroundColor: "#064987",
              color: "white",
              height: "30px",
              borderRadius: "7.5px",
              marginBottom: "2%",
              paddingLeft: "3%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {data.boxName}
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "left",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {data.littleBoxes.map((lBox, index) => (
              <Box key={index} sx={{ width: "20%", marginRight: "5%" }}>
                <BigBoxItems lBox={lBox} />
              </Box>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default HumanResoursesComponent;
