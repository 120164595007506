import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style/loginFrm.css";

import AuthService from "../../../Services/http/auth.service";
import {
  setUserInfo,
  setUserMenu,
  setUserAll,
  setUserData,
  setCurrPageDesc,
  setUser,
  setSocket,
} from "../../../Services/redux/reducers/userMenuReducer";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";

import { jwtToken } from "./../../../Services/GlobalVar/commonVar";
import Cookies from "universal-cookie";
import SocketIO from "../../../Services/SocketIO";

function LoginForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const setUser = props.setUser;
  const [httpError, setHttpError] = useState("");
  const cookies = new Cookies();

  // Handling button to submit login info
  const handleSubmit = (event) => {
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    AuthService.login(uname.value, pass.value).then(
      (response) => {
        if (response?.data?.reply === "userdata") {
          jwtToken[0] = response?.data?.userdata?.token;
          const socket = SocketIO(response?.data?.userdata?.token);
          var y = response;
          console.log("Y : ");
          console.log(y);
          localStorage.setItem("user", JSON.stringify(y));

          var _userMenu = response?.data?.userdata?.usermenu;
          var _userInfo = response?.data?.userdata?.userinfo;
          var _userAll = {
            userid: response?.data?.userid,
            token: response?.data?.userdata?.token,
            ABFULLNAME: response?.data?.userdata?.userinfo?.ABFULLNAME,
          };

          cookies.set("_userMenu", _userMenu, {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          cookies.set("_userData", y?.data, {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          cookies.set("_userAll", _userAll, {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          cookies.set("_userInfo", _userInfo, {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          cookies.set("_currpagedesc", _userMenu?.[0]?.AIDESC, {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          cookies.set("_user", true, {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          cookies.set("_location", "/main", {
            path: "/",
            expires: new Date(Date.now() + 2592000),
          });
          // cookies.set("_socket", socket, {
          //   path: "/",
          //   expires: new Date(Date.now() + 2592000),
          // });

          dispatch(setUserMenu(_userMenu));
          dispatch(setUserData(y?.data));
          dispatch(setUserAll(_userAll));
          dispatch(setUserInfo(_userInfo));
          dispatch(setCurrPageDesc(_userMenu?.[0]?.AIDESC));
          dispatch(setUser(true));
          dispatch(setSocket(socket));
        } else if (response?.data?.reply === "usererror") {
          setHttpError("Invalid username or password.");
        }
      },
      (error) => {
        console.log("error login");
        console.log(error);
      }
    );
  };

  // Handling button to forget password page
  const handleForget = () => {
    navigate("/forgetPassword");
  };

  // JSX code for login form interface
  const renderForm = (
    <div className="formDiv">
      <form className="form">
        <div>
          <img alt="" className="logo001" src="./logmrad.png" />
        </div>
        <div className="formDiv001">
          <div className="title">Sign In</div>
          {httpError.length > 0 && <div className="error">{httpError}</div>}
          <div className="input-container">
            <label>Username </label>
            <input type="text" name="uname" required />
          </div>
          <div className="input-container">
            <label>Password </label>
            <input type="password" name="pass" required />
          </div>
          <div className="input-container">
            <button className="forget-button" onClick={handleForget}>
              Forget Password?
            </button>
          </div>
          <div className="button-container">
            <button className="submit-button" onClick={handleSubmit}>
              Sign In
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <Box widht="100%" height="100vh">
      {/* <div className="app">{renderForm}</div> */}

      {/* {localStorage.getItem("user") ? ( */}
      <div className="app">{renderForm}</div>
      {/* ) : (
        <div></div>
      )} */}
    </Box>
  );
}

export default LoginForm;
