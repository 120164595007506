import {
  Box,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControl,
  Grid,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import TourIcon from "@mui/icons-material/Tour";
import { OBS } from "../../MeterReading/SelectOptions/OBS";
import { Status } from "../../MeterReading/SelectOptions/Status";

const FilterFields = (props) => {
  const filter = props.filter;
  const type = props.type;
  const error = props.error;
  const handleChange = props.handleChange;
  const divisionData = props.divisionData;
  const handlePeriod = props.handlePeriod;
  const handleYear = props.handleYear;

  return (
    <Box>
      <Box
        sx={{
          "& > :not(style)": { width: "100%" },
        }}
        noValidate
      >
        <Grid container>
          <Grid item xs={4.25}>
            <TextField
              fullWidth
              id="search"
              value={filter.search}
              onChange={(e) => handleChange(e.target.value, "search")}
              label="Search"
              variant="outlined"
              autoComplete="off"
              placeholder="Enter Search Text.."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1 / 3}></Grid>
          <Grid item xs={2.25}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Division ID</InputLabel>
              <Select
                label="Division ID"
                width="100%"
                value={filter.divisionID}
                onChange={(e) => handleChange(e.target.value, "divisionID")}
              >
                {divisionData?.map((data) => (
                  <MenuItem key={data.AGVALUE} value={data.AGVALUE}>
                    {data.AGVALUE} - {data.UVDESC}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1 / 3}></Grid>
          <Grid item xs={2.25}>
            <TextField
              fullWidth
              id="period"
              label="Period"
              variant="outlined"
              autoComplete="off"
              value={filter.period}
              placeholder="Enter Emission Period.."
              onChange={(e) => handlePeriod(e.target.value)}
              helperText={error.period}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1 / 3}></Grid>
          <Grid item xs={2.25}>
            <TextField
              fullWidth
              id="year"
              label="Year"
              variant="outlined"
              value={filter.year}
              helperText={error.year}
              autoComplete="off"
              onChange={(e) => handleYear(e.target.value)}
              placeholder="Enter Emission Year.."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarMonthIcon sx={{ color: "#064987" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginY={"1%"}>
        <Grid container>
          <Grid item xs={type === "mr" ? 4.25 : 7.7} container>
            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="crid"
                label="CR ID"
                value={filter.crID}
                variant="outlined"
                autoComplete="off"
                placeholder="Enter CR ID.."
                onChange={(e) => handleChange(e.target.value, "crID")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PermContactCalendarIcon sx={{ color: "#064987" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5.5}>
              <TextField
                fullWidth
                id="round"
                label="Round"
                variant="outlined"
                onChange={(e) => handleChange(e.target.value, "round")}
                value={filter.round}
                autoComplete="off"
                placeholder="Enter Desired Round.."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TourIcon sx={{ color: "#064987" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={type === "mr" ? 1 / 3 : 0.6}></Grid>
          <Grid item xs={type === "mr" ? 2.25 : 3.7}>
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                width="100%"
                value={filter.status}
                onChange={(e) => handleChange(e.target.value, "status")}
              >
                {Status.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1 / 3}></Grid>

          {type === "mr" && (
            <Grid item xs={14.5 / 3} container>
              <Grid item xs={5.6}>
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <InputLabel>OBS</InputLabel>
                  <Select
                    label="OBS"
                    width="100%"
                    value={filter.obs}
                    onChange={(e) => handleChange(e.target.value, "obs")}
                  >
                    {OBS.map((item) => (
                      <MenuItem key={item.code} value={item.code}>
                        {item.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={0.8}></Grid>
              <Grid item xs={5.6}>
                <FormControl
                  sx={{
                    width: "100%",
                  }}
                >
                  <InputLabel>EDITIX</InputLabel>
                  <Select
                    label="EDITIX"
                    width="100%"
                    value={filter.editix}
                    onChange={(e) => handleChange(e.target.value, "editix")}
                  >
                    <MenuItem key={"All"} value={"All"}>
                      All
                    </MenuItem>
                    <MenuItem key={"Y"} value={"Y"}>
                      Yes
                    </MenuItem>
                    <MenuItem key={"N"} value={"N"}>
                      No
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default FilterFields;
