import React from "react";
import { Box, Grid } from "@mui/material";
import Table from "../../table/Table";
import { Ring } from "@uiball/loaders";

const BodyUsers = (props) => {
  const usersData = props.usersData;
  const isLoading = props.isLoading;
  const httpError = props.httpError;
  const usersDataColumns = props.usersDataColumns;
  const _usersData = props._usersData;

  return (
    <Box>
      <Grid>
        {!isLoading && !httpError && usersData?.length > 0 && (
          <Table columns={usersDataColumns} _newData={_usersData} />
        )}
        {!isLoading && !httpError && usersData?.length <= 0 && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: "4%",
              color: "#064987",
              fontWeight: "bold",
              fontSize: "1.2em",
            }}
          >
            No Records To Display!
          </Grid>
        )}
        {isLoading && (
          <Grid
            sx={{ display: "flex", justifyContent: "center", marginY: "4%" }}
          >
            <Ring size={40} lineWeight={5} speed={2} color="#064987" />
          </Grid>
        )}
        {!isLoading && httpError && (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              marginY: "4%",
              color: "#064987",
            }}
          >
            {httpError}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BodyUsers;
