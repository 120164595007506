import React from "react";
import { Box, Grid } from "@mui/material";
import BasicTabs from "../../../../components/Tabs";
import StandardImageList from "../../../../components/ImageList";

const ConversationMedia = ({ conversationMessages }) => {
  // filter conversation messages to only media
  const media = conversationMessages?.filter(
    (msg) => msg?.message?.type === "media"
  );

  const tabSx = {
    display: "flex",
    ".css-heg063-MuiTabs-flexContainer": {
      justifyContent: "space-around !important",
    },
    ".css-1grhalw-MuiButtonBase-root-MuiTab-root": {
      color: "#064987",
    },
  };

  // filter media to type images
  const images = media?.filter(
    (media) => media?.message?.media?.type === "image"
  );
  // filter media to type video
  const videos = media?.filter(
    (media) => media?.message?.media?.type === "video"
  );
  // filter media to type application
  const documents = media?.filter(
    (media) => media?.message?.media?.type === "application"
  );
  // for the tabs buttons
  const tabs = [
    {
      label: "Images",
      value: "image",
    },
    {
      label: "Videos",
      value: "video",
    },
    {
      label: "Documents",
      value: "application",
    },
  ];
  // to get each item in each tab
  const items = [
    {
      content: <StandardImageList data={images} />,
      empty: images?.length <= 0 ? "No images in this ticket.." : "",
    },
    {
      content: <StandardImageList data={videos} />,
      empty: videos?.length <= 0 ? "No videos in this ticket.." : "",
    },
    {
      content: <StandardImageList data={documents} />,
      empty: documents?.length <= 0 ? "No documents in this ticket.." : "",
    },
  ];

  return (
    // <Box>
    <Grid container>
      <Grid
        sx={{
          width: "100%",
          height: "100%",
          ".css-1r9lqan": { color: "rgb(6, 73, 135) !important" },
        }}
      >
        <BasicTabs tabs={tabs} tabSx={tabSx} items={items} />
      </Grid>
    </Grid>
    // </Box>
  );
};

export default ConversationMedia;
