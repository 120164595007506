import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";

const FilterAdminsComponent = (props) => {
  const getData = props.getData;
  const setOpen = props.setOpen;
  const [filter, setFilter] = useState({
    id: "",
    name: "",
  });

  const handleChange = (e, item) => {
    setFilter({
      ...filter,
      [item]: e,
    });
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="id"
            value={filter.id}
            onChange={(e) => handleChange(e.target.value, "id")}
            label="ID"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter ID.."
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            id="name"
            value={filter.name}
            onChange={(e) => handleChange(e.target.value, "name")}
            label="Name"
            variant="outlined"
            autoComplete="off"
            placeholder="Enter Name.."
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => {
              getData({
                id: filter.id,
                search: filter.name,
              });
            }}
            disabled={filter.id === "" && filter.name === ""}
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={1.5}>
          <Button
            sx={{
              backgroundColor: "#064987",
              color: "white",
              borderRadius: "12px",
              width: "100%",
            }}
            onClick={() => setOpen(true)}
          >
            Address Book
          </Button>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Box>
  );
};

export default FilterAdminsComponent;
