import { useState } from "react";

const ReceiptComponent = (props) => {
  const details = props.details;
  const styles = {
    page: {
      marginLeft: "5rem",
      marginRight: "5rem",
      "page-break-after": "always",
    },

    columnLayout: {
      //   display: "flex",
      //   justifyContent: "space-between",
      margin: "3rem 0 5rem 0",
      gap: "2rem",
      color: "black",
    },

    layout: {
      display: "flex",
      justifyContent: "space-between",
      margin: "3rem 0 5rem 0",
      gap: "2rem",
      color: "black",
      fontSize: "10px",
    },

    titles: {
      fontWeight: "bold",
      fontSize: "1rem",
      textAlign: "left",
    },

    column: {
      textAlign: "left",
      fontSize: "2em",
    },
    logo: {
      textAlign: "center",
    },

    spacer2: {
      height: "2rem",
    },

    fullWidth: {
      width: "40%",
    },

    marginb0: {
      marginBottom: 0,
      textAlign: "center",
    },
    between: {
      display: "flex",
      justifyContent: "space-between",
      fontSize: "10px",
    },
    p: {
      textAlign: "center",
    },
  };

  function getDate() {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
    return `${date}/${month}/${year}`;
  }
  const [currentDate, setCurrentDate] = useState(getDate());

  return (
    <div style={styles.columnLayout}>
      <div style={styles.column}>
        <div style={styles.logo}>
          <img style={styles.fullWidth} src="./mrad.png" />
        </div>
        <div style={styles.between}>
          <span style={styles.titles}>Date</span>
          {currentDate}
        </div>
        <div style={styles.between}>
          <span style={styles.titles}>Paid from</span>
          {details.fullName}
        </div>
        <div style={styles.between}>
          <span style={styles.titles}>The sum of</span>
          200 000 L.L.
        </div>
        <div style={styles.between}>
          <span style={styles.titles}>On settlement of</span>
          User fees
        </div>
      </div>
      <div style={styles.layout}>
        <div style={styles.column}>
          <h5 style={styles.marginb0}>Received By</h5>
          <p style={styles.p}>--------------------------------</p>
        </div>

        <div style={styles.column}>
          <h5 style={styles.marginb0}>Custodian</h5>
          <p style={styles.p}>--------------------------------</p>
        </div>
      </div>
    </div>
  );
};

export default ReceiptComponent;
