import React, { useState } from "react";
import NoConversations from "../../components/conversation/no-conversations/NoConversations";
import ConversationList from "../../components/conversation/conversation-list/ConversationList";
import ChatTitle from "../../components/chat-title/ChatTitle";
import MessageList from "../message/MessageList";
import ChatForm from "../../components/chat-form/ChatForm";

import "./ChatShell.scss";
import { useSelector } from "react-redux";
import moment from "moment";
import MediaPreview from "../../components/chat-form/FileUpload";
import MQTT from "../../../../../../Services/MQTT";
import AlertDialog from "../../../components/AlertDialog";
import ConversationFilter from "../../components/conversation/conversation-search/ConversationSearch";
import { Button, Divider, Drawer, List, Toolbar } from "@mui/material";
import ChatDrawer from "../../components/chat-drawer";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import TemporaryDrawer from "../../../components/RightBar";

const ChatShell = ({
  userAll,
  client,
  messageList,
  messagesUpdate,
  selectedConversation,
  joined,
  onConversationItem,
  setIsChatting,
}) => {
  // Get conversation messages from redux
  const conversationMessages = useSelector(
    (state) => state.ticket.conversationMessages
  );

  const [drawerOpen, setDrawerOpen] = useState(false);

  // State to open preview media pop up
  const [openUpload, setOpenUpload] = useState(false);

  // Conversation Content if there isn't messages or ticket selected
  let conversationContent = (
    <>
      <NoConversations />
    </>
  );

  // Get all messages inside a ticket
  if (Object.keys(selectedConversation)?.length > 0) {
    conversationContent = (
      <>
        <MessageList
          userAll={userAll}
          conversationMessages={conversationMessages}
        />
      </>
    );
  }

  // To resize ticket list and conversation content
  const resizable = function (resizer) {
    const direction = resizer.getAttribute("data-direction") || "horizontal";
    const prevSibling = resizer.previousElementSibling;
    const nextSibling = resizer.nextElementSibling;

    // The current position of mouse
    let x = 0;
    let y = 0;
    let prevSiblingHeight = 0;
    let prevSiblingWidth = 0;

    // Handle the mousedown event
    // that's triggered when user drags the resizer
    const mouseDownHandler = function (e) {
      // Get the current mouse position
      x = e.clientX;
      y = e.clientY;
      const rect = prevSibling.getBoundingClientRect();
      prevSiblingHeight = rect.height;
      prevSiblingWidth = rect.width;

      // Attach the listeners to document
      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler);
    };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - x;
      const dy = e.clientY - y;

      switch (direction) {
        case "vertical":
          const h =
            ((prevSiblingHeight + dy) * 100) /
            resizer.parentNode.getBoundingClientRect().height;
          prevSibling.style.height = h + "%";
          break;
        case "horizontal":
        default:
          const w =
            ((prevSiblingWidth + dx) * 100) /
            resizer.parentNode.getBoundingClientRect().width;
          prevSibling.style.width = w + "%";
          break;
      }

      const cursor = direction === "horizontal" ? "col-resize" : "row-resize";
      resizer.style.cursor = cursor;
      document.body.style.cursor = cursor;

      prevSibling.style.userSelect = "none";
      prevSibling.style.pointerEvents = "none";

      nextSibling.style.userSelect = "none";
      nextSibling.style.pointerEvents = "none";
    };

    const mouseUpHandler = function () {
      resizer.style.removeProperty("cursor");
      document.body.style.removeProperty("cursor");

      prevSibling.style.removeProperty("user-select");
      prevSibling.style.removeProperty("pointer-events");

      nextSibling.style.removeProperty("user-select");
      nextSibling.style.removeProperty("pointer-events");

      // Remove the handlers of mousemove and mouseup
      document.removeEventListener("mousemove", mouseMoveHandler);
      document.removeEventListener("mouseup", mouseUpHandler);
    };

    // Attach the handler
    resizer.addEventListener("mousedown", mouseDownHandler);
  };

  // Query all resizers
  document.querySelectorAll(".resizer").forEach(function (ele) {
    resizable(ele);
  });

  const date = new Date();

  // State for the sended message
  const [sendedMessage, setSendedMessage] = useState({
    ticketid: "",
    sender: {
      senderName: userAll?.ABFULLNAME,
      avatar: "link",
      senderId: userAll.userid,
    },
    message: {
      status: "sent",
      messageDay: moment(date).format("YYYY-MM-DD"),
      messageTime: moment(date).format("hh:mm:ss"),
      messageId: Math.random(),
      type: "",
      text: "",
      media: {
        extension: "",
        type: "",
        buffer: [],
        name: "",
      },
    },
  });

  // Function to send a message
  const sendMessage = () => {
    // publish new message if writing a text
    if (sendedMessage?.message?.type === "text") {
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "newmsg",
            userid: userAll?.userid,
            ticketid: selectedConversation.AHLINEID,
            msg: sendedMessage,
          }),
        },
        client
      );
      // publish ticket media if sending media
    } else {
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "ticketmedia",
            userid: userAll?.userid,
            ticketid: selectedConversation.AHLINEID,
            msg: sendedMessage,
          }),
        },
        client
      );
      setOpenUpload(false);
    }
    // set empty state
    setSendedMessage({
      ticketid: "",
      ...sendedMessage,
      message: {
        ...sendedMessage.message,
        type: "",
        text: "",
        media: {
          extension: "",
          type: "",
          buffer: [],
          name: "",
        },
      },
    });
  };

  // Empty the state after sending a message
  const handleClose = () => {
    setSendedMessage({
      ticketid: "",
      ...sendedMessage,
      message: {
        ...sendedMessage.message,
        type: "",
        text: "",
        media: {
          extension: "",
          type: "",
          buffer: [],
          name: "",
        },
      },
    });
    setOpenUpload(false);
  };
  // toogling the drawer and publish ticket details on open
  const toggleDrawer = (open, setState) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (open === true) {
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "ticketdetails",
            userid: userAll?.userid,
            ticketid: selectedConversation?.AHLINEID,
          }),
        },
        client
      );
    }

    setState(open);
    setDrawerOpen(open);
  };

  return (
    <div id="chat-container">
      {/* <div id="chat-container1">
        <div id="chat-container11">
          <ConversationFilter conversations={conversationMessages} />
          <ConversationList
            userAll={userAll}
            conversations={messageList}
            selectedConversation={selectedConversation}
            onConversationItem={onConversationItem}
          />
        </div>
      </div> */}
      {/* <div className="resizer" data-direction="horizontal"></div> */}
      {Object.keys(selectedConversation)?.length > 0 ? (
        <div id="chat-container2">
          {/*for the Name bar in the chat */}
          <ChatTitle
            selectedConversation={selectedConversation}
            joined={joined}
            userAll={userAll}
            messagesUpdate={messagesUpdate}
            client={client}
            conversationMessages={conversationMessages}
            setIsChatting={setIsChatting}
          />
          {/* for the messages */}
          {conversationContent}
          {/*for the typing and send a message / media */}
          {/* {joined?.includes(selectedConversation.AHLINEID) && ( */}
          {selectedConversation?.AHASSIGNED === "Y" &&
            selectedConversation?.AHASSIGNEE === userAll?.userid &&
            selectedConversation?.AHSTATUS !== "C" && (
              <ChatForm
                selectedConversation={selectedConversation}
                setOpenUpload={setOpenUpload}
                sendedMessage={sendedMessage}
                setSendedMessage={setSendedMessage}
                sendMessage={sendMessage}
              />
            )}
          {/* Media preview */}
          {openUpload && sendedMessage?.message?.media?.buffer?.length > 0 && (
            <AlertDialog
              open={openUpload}
              handleClose={handleClose}
              buttonTitle={"Send"}
              buttonClick={sendMessage}
              content={<MediaPreview sendedMessage={sendedMessage} />}
            />
          )}
        </div>
      ) : (
        // if there is no conversation
        <div id="chat-container2">{conversationContent}</div>
      )}
      <div
        id="chat-container1"
        style={{
          width: drawerOpen ? 400 : "0%",
          transition: "1s",
        }}
      >
        {/* drawer for chat information */}
        <TemporaryDrawer
          button={
            <KeyboardDoubleArrowLeftIcon
              sx={{ height: "100%", width: "100%", left: 5 }}
            />
          }
          buttonSx={{
            backgroundColor: "#064987",
            color: "white",
            right: "10px",
            minWidth: "35px",
            boxShadow:
              " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          type={"info"}
          toggleDrawer={toggleDrawer}
          drawerOpen={drawerOpen}
          content={
            <ChatDrawer
              selectedConversation={selectedConversation}
              client={client}
              userAll={userAll}
              joined={joined}
              conversationMessages={conversationMessages}
              setDrawerOpen={setDrawerOpen}
              toggleDrawer={toggleDrawer}
            />
          }
        />
      </div>
    </div>
  );
};

export default ChatShell;
