import * as React from "react";
import {
  Grid,
  ImageList,
  ImageListItem,
  Link,
  Button,
  Tooltip,
} from "@mui/material";

export default function StandardImageList({ data }) {
  return (
    <Grid component={"span"} sx={{ height: "100%" }}>
      <ImageList
        sx={{ width: "100%", height: "100%", cursor: "pointer" }}
        cols={2}
        rowHeight={200}
        component={"span"}
      >
        {data.map((data) => {
          const item = data?.message;
          const sender = data?.sender;
          return (
            <Button
              key={item?.media.link}
              href={item?.media.link}
              target="_blank"
            >
              <ImageListItem component={"span"} sx={{ height: "100%" }}>
                <Grid
                  sx={{ fontSize: "10px", height: "10%" }}
                  component={"span"}
                >
                  {item?.messageDay + "-" + item?.messageTime}
                </Grid>
                <Grid sx={{ height: "80%" }} component={"span"}>
                  {item?.media?.type === "image" && (
                    <img
                      srcSet={`${item?.media.link}`}
                      src={`${item?.media.link}`}
                      alt={item?.media.name}
                      loading="lazy"
                      height={"100%"}
                      style={{ width: "100%" }}
                    />
                  )}
                  {item?.media?.type === "video" && (
                    <video width="100%" height="100%" controls>
                      <source src={item?.media.link} type="video/mp4" />
                    </video>
                  )}
                  {item?.media?.type === "application" && (
                    <Link
                      href={item?.media.link}
                      underline="none"
                      target="_blank"
                      sx={{ height: "80%" }}
                      component={"span"}
                    >
                      <Grid
                        container
                        component={"span"}
                        sx={{
                          padding: "3% 1% 0% 1%",
                          cursor: "pointer",
                          height: "100%",
                          width: "100%",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                          component={"span"}
                        >
                          {item?.media?.extension === "pdf" && (
                            <img src="./pdf.png" style={{ width: "45%" }} />
                          )}
                          {item?.media?.extension === "docx" && (
                            <img src="./doc.png" style={{ width: "45%" }} />
                          )}
                          {item?.media?.extension === "xls" && (
                            <img
                              src="./xls-file.png"
                              style={{ width: "45%" }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            fontSize: "small",
                          }}
                          component={"span"}
                        >
                          {item?.media?.name}
                        </Grid>
                      </Grid>
                    </Link>
                  )}
                </Grid>
                <Grid sx={{ fontSize: "10px" }} component={"span"}>
                  {sender?.senderName}
                </Grid>
              </ImageListItem>
            </Button>
          );
        })}
      </ImageList>
    </Grid>
  );
}
