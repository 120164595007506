export const SuperJson = [
  {
    boxName: "Super Admin",
    littleBoxes: [
      {
        boxLink: "/main/superadmin01/superadmin01a",
        boxTitle: "Pages",
        boxDescription: "Add / Edit / Delete Pages!",
        boxIcon: "AutoStories",
      },
      {
        boxLink: "/main/superadmin01/superadmin01b",
        boxTitle: "Users",
        boxDescription: "Add / Edit / Delete Users!",
        boxIcon: "Person",
      },
      {
        boxLink: "/main/superadmin01/superadmin01c",
        boxTitle: "Security",
        boxDescription: "Add / Edit / Delete Security!",
        boxIcon: "Security",
      },
    ],
  },
];
