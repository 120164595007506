export const BoxJson = [
  {
    boxName: "Meter Reading",
    littleBoxes: [
      {
        boxLink: "/main/spvlvmrbc01/spvlvmrbc01a",
        boxTitle: "Operation",
        boxDescription: "Operation for meter reading",
        boxIcon: "Memory",
      },
      {
        boxLink: "/main/spvlvmrbc01/spvlvmrbc01d",
        boxTitle: "Dashboard",
        boxDescription: "Dashboard for meter reading",
        boxIcon: "Dashboard",
      },
    ],
  },
  {
    boxName: "Bill Collection",
    littleBoxes: [
      {
        boxLink: "/main/spvlvmrbc01/spvlvmrbc01b",
        boxTitle: "Operation",
        boxDescription: "Operation for bill collection",
        boxIcon: "Memory",
      },
      {
        boxLink: "/main/spvlvmrbc01/spvlvmrbc01c",
        boxTitle: "Dashboard",
        boxDescription: "Dashboard for bill collection",
        boxIcon: "Dashboard",
      },
    ],
  },
  {
    boxName: "Controls",
    littleBoxes: [
      {
        boxLink: "/main/spvlvmrbc01/spvlvmrbc01e",
        boxTitle: "Operation",
        boxDescription: "Operation for monitors",
        boxIcon: "Memory",
      },
    ],
  },
];
