import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";

export default function TemporaryDrawer({
  button,
  content,
  toggleDrawer,
  drawerOpen,
  type,
  buttonSx,
}) {
  // state to open the drawer
  const [state, setState] = React.useState(false);

  // added for the drawer in ticket chat
  React.useEffect(() => {
    if (drawerOpen === false) setState(false);
  }, [drawerOpen]);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 400,
        height: "100%",
      }}
      role="presentation"
    >
      {content}
    </Box>
  );

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <React.Fragment key={"right"}>
        <Button sx={buttonSx} onClick={toggleDrawer(true, setState)}>
          {button}
        </Button>
        <Drawer
          anchor={"right"}
          open={state}
          component={"span"}
          onClose={toggleDrawer(false, setState)}
          variant={type === "info" && "persistent"}
          sx={{
            ".css-1vxvp5a-MuiPaper-root-MuiDrawer-paper,.css-1bfesyr-MuiPaper-root-MuiDrawer-paper,.css-j1h40f":
              {
                // height: "77%",
                height: "95%",
                // top: "11.8%",
                top: "4.8%",
                backgroundColor: "#d3d2d2",
              },
            ".css-10ug5gj": {
              backgroundColor: "#d3d2d2",
            },
            ".css-1bfesyr-MuiPaper-root-MuiDrawer-paper": {
              backgroundColor: "#d3d2d2",
            },
          }}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
