import {
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import moment from "moment";
import dayjs from "dayjs";

const FilterTicketsComponent = ({
  getData,
  filterTickets,
  setFilterTickets,
}) => {
  const assigned = [
    {
      description: "All",
      value: "",
    },
    {
      description: "Yes",
      value: "Y",
    },
    {
      description: "No",
      value: "N",
    },
  ];

  const status = [
    {
      description: "All",
      value: "",
    },
    {
      description: "New",
      value: "N",
    },
    {
      description: "Under Process",
      value: "U",
    },
    {
      description: "Closed",
      value: "C",
    },
  ];

  const handleChange = (e, item) => {
    setFilterTickets({
      ...filterTickets,
      [item]: e,
    });
  };

  return (
    <Box
      sx={{
        padding: "1%",
        backgroundColor: "#e2e2e2",
        paddingX: "5%",
      }}
      boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    >
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid container sx={{ marginBottom: "1%" }}>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              id="AHLINEID"
              label="Ticket ID"
              size="small"
              value={filterTickets?.AHLINEID}
              onChange={(e) => handleChange(e.target.value, "AHLINEID")}
            />
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              id="AHUSERID"
              label="User ID"
              size="small"
              value={filterTickets?.AHUSERID}
              onChange={(e) => handleChange(e.target.value, "AHUSERID")}
            />
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              id="AHJDECASEID"
              label="JDE Case ID"
              size="small"
              value={filterTickets?.AHJDECASEID}
              onChange={(e) => handleChange(e.target.value, "AHJDECASEID")}
            />
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Is Assigned</InputLabel>
              <Select
                label="Is Assigned"
                width="100%"
                value={filterTickets?.AHASSIGNED}
                onChange={(e) => handleChange(e.target.value, "AHASSIGNED")}
              >
                {assigned.map((assigned) => (
                  <MenuItem key={assigned.value} value={assigned.value}>
                    {assigned.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              id="AHASSIGNEE"
              label="Assignee"
              size="small"
              value={filterTickets?.AHASSIGNEE}
              onChange={(e) => handleChange(e.target.value, "AHASSIGNEE")}
            />
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1} sx={{ display: "flex", alignItems: "center" }}>
            <FormControl
              sx={{
                width: "100%",
              }}
              size="small"
            >
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                width="100%"
                value={filterTickets?.AHSTATUS}
                onChange={(e) => handleChange(e.target.value, "AHSTATUS")}
              >
                {status.map((status) => (
                  <MenuItem key={status.value} value={status.value}>
                    {status.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                maxDate={dayjs(new Date())}
                slotProps={{
                  textField: {
                    size: "small",
                    id: "fromdate",
                    label: "From Date",
                  },
                }}
                format="YYYY-MM-DD"
                value={dayjs(filterTickets?.fromdate)}
                onChange={(e) =>
                  handleChange(moment(e?.$d).format("YYYY-MM-DD"), "fromdate")
                }
                sx={{
                  ".css-havevq-MuiSvgIcon-root,.css-11fo197": {
                    color: "black",
                  },
                  width: "100%",
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={1.5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                maxDate={dayjs(new Date())}
                minDate={dayjs(filterTickets?.fromdate)}
                slotProps={{
                  textField: { size: "small", id: "todate", label: "To Date" },
                }}
                sx={{
                  ".css-havevq-MuiSvgIcon-root, .css-11fo197": {
                    color: "black",
                  },
                  width: "100%",
                }}
                format="YYYY-MM-DD"
                value={dayjs(filterTickets?.todate)}
                onChange={(e) =>
                  handleChange(moment(e?.$d).format("YYYY-MM-DD"), "todate")
                }
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
          <Grid item xs={0.5} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              sx={{ backgroundColor: "#064987", width: "100%" }}
              onClick={() => getData()}
            >
              <SearchIcon sx={{ color: "white" }} />
            </Button>
          </Grid>
          <Grid item xs={2.5 / 10}></Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterTicketsComponent;
