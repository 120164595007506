import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import FilterHRComponent from "./Filter";
import { useDispatch, useSelector } from "react-redux";
import { setAllEmployees } from "../../../../../Services/redux/reducers/HRReducer";
import BodyHRComponent from "./Body";

// const initialBar = { data: [], error: undefined, loading: true };

const AttendanceComponent = () => {
  const dispatch = useDispatch();
  const socket = useSelector((state) => state.menu.socket);

  function getUsersList() {
    console.log(socket);
    socket.emit(
      "dspinternal:hr",
      {
        request: "attendanceUsersList",
      },
      (response) => {
        dispatch(setAllEmployees(response));
      }
    );
  }

  useEffect(() => {
    if (socket !== null) getUsersList();
  }, [socket]);

  return (
    <Box>
      {socket !== null && (
        <Grid>
          <FilterHRComponent socket={socket} />
          <BodyHRComponent />
        </Grid>
      )}
    </Box>
  );
};

export default AttendanceComponent;
