import { Button, Grid } from "@mui/material";
import React from "react";
import MQTT from "../../../../../../Services/MQTT";
import { useSelector } from "react-redux";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import VideocamIcon from "@mui/icons-material/Videocam";

const MessageNotification = ({ message, userAll, client }) => {
  const ticketheader = useSelector((state) => state.ticket.ticketheader);
  const onNotificationClick = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "ticketheader",
          userid: userAll?.userid,
          ticketid: message?.ticketid,
        }),
      },
      client
    );
  };

  const mediaType = message?.message?.media?.type;

  return (
    <Grid
      sx={{
        ".css-1exqwzz-MuiSnackbarContent-message,.css-1w0ym84": {
          width: "85%",
        },
        cursor: "pointer",
      }}
    >
      <div onClick={onNotificationClick}>
        <Grid container>
          <Grid item xs={2}>
            <img
              src={
                ticketheader?.ABTEXT1 ? ticketheader?.ABTEXT1 : "./avatar.jpg"
              }
              height={35}
              style={{ width: "30px", marginRight: "2%" }}
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={8.5}>
            <Grid sx={{ fontWeight: "bold" }}>
              {message?.ticketid} - {message?.sender?.senderName}
            </Grid>
            {message?.request !== "ticketmedia" ? (
              <Grid>{message?.message?.text}</Grid>
            ) : (
              <Grid sx={{ display: "inline" }}>
                {mediaType === "image" && (
                  <Grid sx={{ display: "flex", alignItems: "center" }}>
                    <CameraAltIcon sx={{ marginRight: "2%" }} />
                    Image
                  </Grid>
                )}
                {mediaType === "video" && (
                  <Grid sx={{ display: "flex", alignItems: "center" }}>
                    <VideocamIcon sx={{ marginRight: "2%" }} />
                    Video
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item xs={1}>
            {message?.message?.messageTime}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default MessageNotification;
