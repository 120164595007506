import { createSlice } from "@reduxjs/toolkit";
import { userLoginInfo } from "../../GlobalVar/commonVar";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const userMenuInitialState = {
  socket: cookies.get("_socket") ? cookies.get("_socket") : null,
  client: cookies.get("_client") ? cookies.get("_client") : null,
  user: cookies.get("_user") ? cookies.get("_user") : false,
  userMenu: cookies.get("_userMenu") ? cookies.get("_userMenu") : "-",
  userInfo: cookies.get("_userInfo") ? cookies.get("_userInfo") : "-",
  userData: cookies.get("_userData") ? cookies.get("_userData") : "-",
  userAll: cookies.get("_userAll") ? cookies.get("_userAll") : "-",
  currentPage: cookies.get("_currpagedesc")
    ? cookies.get("_currpagedesc")
    : "-",
  conversationMessages: [
    {
      ticketid: 0,
      sender: {
        senderName: "",
        avatar: "link",
        senderId: 3,
      },
      message: {
        status: "sent",
        messageDay: "2023-12-11",
        messageTime: "04:00:01",
        messageId: Math.random(),
        type: "text",
        text: "",
      },
    },
  ],
  selectedConversation: {},
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: userMenuInitialState,
  reducers: {
    setSocket: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.socket = action.payload;
    },
    setClient: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.client = action.payload;
    },
    setUser: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      if (action.payload === false) {
        state.user = action.payload;
        cookies.remove("_user");
        state.userMenu = [];
        cookies.remove("_userMenu");
        state.userData = [];
        cookies.remove("_userData");
        state.userAll = [];
        cookies.remove("_userAll");
        state.userInfo = [];
        cookies.remove("_userInfo");
        state.currentPage = [];
        cookies.remove("_currpagedesc");
        cookies.set("_location", "/main", {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
        if (state.socket) {
          state.socket.disconnect();
        }
        if (state.client) {
          state.client.end();
        }
        state.socket = null;
        cookies.remove("_socket");
      } else {
        state.user = action.payload;
      }
    },
    setUserMenu: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userMenu = action.payload;
    },
    setUserInfo: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userInfo = action.payload;
    },
    setUserAll: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userAll = action.payload;
    },
    setUserData: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userData = userLoginInfo[0];
    },
    setCurrPageDesc: (state, action) => {
      state.currentPage = action.payload;
    },
    setConversationHistory: (state, action) => {
      state.conversationMessages = action.payload;
    },
    setConversationNewMessages: (state, action) => {
      state.conversationMessages.push(action.payload);
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSocket,
  setClient,
  setUser,
  setUserMenu,
  setUserInfo,
  setUserData,
  setCurrPageDesc,
  setUserAll,
  setConversationHistory,
  setConversationNewMessages,
  setSelectedConversation,
} = menuSlice.actions;

export default menuSlice.reducer;
