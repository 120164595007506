import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import MQTT from "../../../../../../../Services/MQTT";
import moment from "moment";
import { useStopwatch } from "react-timer-hook";

const BreakContent = ({ client, onBreakDetails }) => {
  const userAll = useSelector((state) => state.menu.userAll);
  const breakdetails = useSelector((state) => state.ticket.breakdetails);
  const [breakType, setBreakType] = useState("breakout");
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
    stop,
  } = useStopwatch({ autoStart: true });

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const startdate = moment(breakdetails?.BEBREAKIN).format("hh:mm:ss A");
  const [duration, setDuration] = useState("");

  const onBreak = (type) => {
    setBreakType(type);
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "agentbreak",
          userid: userAll?.userid,
          agentbreak: {
            type: type,
          },
        }),
      },
      client
    );
  };

  useEffect(() => {
    var now = moment(new Date()).format("DD/MM/YYYY HH:mm:ss");
    var then = moment(breakdetails?.BEBREAKIN).format("DD/MM/YYYY HH:mm:ss");

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(then, "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);
    var s = -1 * Math.floor(d.asHours()) + moment.utc(ms).format(":mm:ss");

    setDuration(s);
  }, [new Date()]);

  return (
    <Grid>
      <Grid container>
        <Grid
          item
          xs={5}
          sx={{
            ".css-1l21c86-MuiButtonBase-root-MuiButton-root:hover": {
              backgroundColor: "#7cd78c",
            },
            ".css-j1430l-MuiButtonBase-root-MuiButton-root:hover": {
              backgroundColor: "#ed5a5a !important",
            },
          }}
        >
          <Button
            sx={{ backgroundColor: "#7cd78c", width: "100%" }}
            onClick={() => {
              onBreak("breakin");
              onBreakDetails();
              start();
            }}
            disabled={breakdetails?.BEBREAKSTAT === "Y"}
          >
            Start
          </Button>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <Button
            sx={{
              backgroundColor: "#ed5a5a !important",
              width: "100%",
            }}
            onClick={() => {
              onBreak("breakout");
              onBreakDetails();
              pause();
            }}
            disabled={breakdetails?.BEBREAKSTAT === "N"}
          >
            End
          </Button>
        </Grid>
      </Grid>
      {breakdetails?.BEBREAKSTAT === "Y" && (
        <Grid
          sx={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
          container
        >
          <Grid item xs={4} sx={{ color: "black", fontWeight: "bold" }}>
            Start:
          </Grid>
          <Grid item xs={8} sx={{ color: "black" }}>
            {startdate}
          </Grid>
          <Grid item xs={4} sx={{ color: "black", fontWeight: "bold" }}>
            Duration:
          </Grid>
          <Grid item xs={8} sx={{ color: "black" }}>
            {duration}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default BreakContent;
