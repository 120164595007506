import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

// get today's date
var today = new Date();
// get the date 2 weeks before
today?.setDate(today.getDate() - 14);

const initialBar = { data: [], error: undefined, loading: true };

const HRInitialState = {
  allEmployees: [],
  filterAttendance: {
    check: "all",
    break: "all",
    businessUnit: "",
    department: "",
    position: "",
    employees: [],
    fromdate: moment(today).format("YYYY-MM-DD"),
    todate: moment(new Date()).format("YYYY-MM-DD"),
  },
  attendanceData: initialBar,
  attendanceBreakData: initialBar,
  attendanceDaysData: initialBar,
  attendanceDetails: [],
};

export const HRSlice = createSlice({
  name: "HR",
  initialState: HRInitialState,
  reducers: {
    setAllEmployees: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.allEmployees = action.payload;
    },
    setFilterAttendance: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.filterAttendance = action.payload;
    },
    setAttendanceData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceData = action.payload;
    },
    setAttendanceBreakData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceBreakData = action.payload;
    },
    setAttendanceDaysData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceDaysData = action.payload;
    },
    setAttendanceDetails: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.attendanceDetails = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAllEmployees,
  setFilterAttendance,
  setAttendanceDetails,
  setAttendanceData,
  setAttendanceBreakData,
  setAttendanceDaysData,
} = HRSlice.actions;

export default HRSlice.reducer;
