import React from "react";
import Message from "../../components/message/Message";
import "./MessageList.scss";
import { Grid } from "@mui/material";
import moment from "moment";

const MessageList = ({ conversationMessages, userAll }) => {
  // if there messages, get it from conversation messages
  const messages =
    conversationMessages.length > 0 ? conversationMessages : null;
  let messageItems = null;

  if (messages && messages?.length > 0) {
    // map all the dates from messages, then get all the unique dates to filter the messages as dates
    const messagesDate = messages?.map(
      (message) => message?.message?.messageDay
    );
    let uniqueNumbers = [...new Set(messagesDate)];
    messageItems = uniqueNumbers
      ?.slice(0)
      ?.reverse()
      ?.map((date, index) => {
        const daily = messages?.filter(
          (byDate) => byDate?.message?.messageDay === date
        );
        return (
          <div key={index}>
            <Grid
              sx={{ display: "flex", justifyContent: "center", marginY: "3%" }}
            >
              <Grid
                sx={{
                  backgroundColor: "#7f97b3",
                  textAlign: "center",
                  color: "white",
                  borderRadius: "7.5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "30px",
                  width: "30%",
                }}
              >
                {moment(date).format("DD MMMM YYYY")}
              </Grid>
              {/* <Grid item xs={5}></Grid> */}
            </Grid>
            {daily.map((message, index) => {
              return (
                <div
                  key={message?.message?.messageId}
                  id={message?.message?.messageId}
                >
                  <Message userAll={userAll} message={message} />
                </div>
              );
            })}
          </div>
        );
      });
  }

  return <div id="chat-message-list">{messageItems}</div>;
};

export default MessageList;
