import * as React from "react";

import "./ChatTitle.scss";
import { Grid, Button } from "@mui/material";
import MQTT from "../../../../../../Services/MQTT";
import { setAssignedToMe } from "../../../../../../Services/redux/reducers/ticketsReducer";
import { useDispatch } from "react-redux";
import TitleDrawers from "./Drawers";
import SearchTitle from "./Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

const ChatTitle = ({
  selectedConversation,
  joined,
  client,
  userAll,
  conversationMessages,
  setIsChatting,
}) => {
  let chatTitleContents = null;
  const dispatch = useDispatch();

  const scrollToMessage = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Publish assign to me
  const joinConversation = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "ticketassign",
          userid: userAll?.userid,
          ticketid: selectedConversation?.AHLINEID,
        }),
      },
      client
    );
  };

  // Remove the ticket from the joined list then unsubscribe ticket
  const leaveConversation = () => {
    const removeItem = () => {
      dispatch(
        setAssignedToMe(
          joined?.filter((item) => item !== selectedConversation.AHLINEID)
        )
      );
    };
    MQTT.mqttUnSub(
      {
        topic: `dspenduser/v1/tickets/chat/${selectedConversation.AHLINEID}`,
        qos: 0,
      },
      client
    );
    removeItem();
  };

  // to open the drawer on click and publish ticket details if its details
  const toggleDrawer = (open, setState) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (open === true) {
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "ticketdetails",
            userid: userAll?.userid,
            ticketid: selectedConversation?.AHLINEID,
          }),
        },
        client
      );
    }

    setState(open);
  };

  // to open the drawer on click and publish ticket review if its review
  const toggleReviewDrawer = (open, setState) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (open === true)
      MQTT.mqttPublish(
        {
          topic: "dspenduser/v1/ccagent/tickets",
          qos: 0,
          payload: JSON.stringify({
            request: "ticketreview",
            userid: userAll?.userid,
            ticketid: selectedConversation?.AHLINEID,
          }),
        },
        client
      );

    setState(open);
  };

  if (selectedConversation) {
    chatTitleContents = (
      <>
        <Grid container sx={{ display: "flex", alignItems: "center" }}>
          <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={() =>
                setIsChatting({
                  boolean: false,
                  state: {},
                })
              }
            >
              <ArrowBackIcon />
            </Button>
            {/* <Link
              style={{ marginLeft: "4%", marginRight: "4%" }}
              to={"/main/chatickets01/chatickets01a"}
            ></Link> */}
            <img
              src={
                selectedConversation?.ABTEXT1
                  ? selectedConversation?.ABTEXT1
                  : "./avatar.jpg"
              }
              height={50}
              style={{ width: "50px", marginRight: "2%" }}
            />{" "}
            <span>{selectedConversation?.ABFULLNAME} -</span>
            <span>{selectedConversation?.AHLINEID}</span>
            {/* <span>{selectedConversation?.ABFULLNAME}</span> */}
          </Grid>
          <Grid item xs={3.5} sx={{ height: "50%" }}>
            <SearchTitle
              conversationMessages={conversationMessages}
              scrollToMessage={scrollToMessage}
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          {/* <Grid item xs={4}>
            <TitleDrawers
              toggleDrawer={toggleDrawer}
              conversationMessages={conversationMessages}
              client={client}
              userAll={userAll}
              selectedConversation={selectedConversation}
              joinConversation={joinConversation}
              leaveConversation={leaveConversation}
              joined={joined}
              toggleReviewDrawer={toggleReviewDrawer}
            />
          </Grid> */}
        </Grid>
      </>
    );
  }

  return <div id="chat-title">{chatTitleContents}</div>;
};

export default ChatTitle;
