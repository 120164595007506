import React from "react";
import "../Message.scss";
import { Box, Grid } from "@mui/material";
import MediaImageType from "./MediaTypes/MediaImage";
import MediaApplicationType from "./MediaTypes/MediaApplication";

// all media such as images, videos, applications, voice notes...
const TypeMedia = ({ message }) => {
  return (
    <div
      className="message-media"
      style={{
        backgroundColor:
          message?.message?.media?.type === "application"
            ? "#d1d1d1"
            : "transparent",
        borderRadius:
          message?.message?.media?.type === "application"
            ? "7.5px 7.5px 0px 7.5px"
            : "none",
        width: "60%",
      }}
    >
      <Box>
        <Grid>
          {message?.message?.media?.type === "image" && (
            <MediaImageType message={message} />
          )}
          {message?.message?.media?.type === "video" && (
            <video
              width="100%"
              height="300"
              style={{
                width: "100%",
                borderRadius: "14px 14px 14px 0px",
              }}
              controls
            >
              <source src={message?.message?.media?.link} type="video/mp4" />
            </video>
          )}
          {message?.message?.media?.type === "voicenote" && (
            <audio
              controls
              width="100%"
              height="300"
              style={{
                width: "100%",
                borderRadius: "14px 14px 14px 0px",
                backgroundColor: "#eee",
                border: "1px solid #cccccc",
              }}
            >
              <source
                src={message?.message?.media?.link}
                type="audio/mpeg"
                style={{ backgroundColor: "#eee" }}
              />
            </audio>
          )}
          {message?.message?.media?.type === "application" && (
            <MediaApplicationType message={message} />
          )}
        </Grid>
      </Box>
    </div>
  );
};

export default TypeMedia;
