export const HRJson = [
  {
    boxName: "HR",
    littleBoxes: [
      {
        boxLink: "/main/humanresources01/humanresources01a",
        boxTitle: "Attendence",
        boxDescription: "Add / Edit / Delete Pages!",
        boxIcon: "AutoStories",
      },
    ],
  },
];
