import { createSlice } from "@reduxjs/toolkit";

const ticketsInitialState = {
  conversationMessages: [
    {
      ticketid: 0,
      sender: {
        senderName: "",
        avatar: "link",
        senderId: 3,
      },
      message: {
        status: "sent",
        messageDay: "2023-12-11",
        messageTime: "04:00:01",
        messageId: Math.random(),
        type: "text",
        text: "",
      },
    },
  ],
  selectedConversation: {},
  unreadedMessages: [],
  messageList: [],
  filter: [{ groupName: "Type", name: "Pending", value: "N" }],
  ticketdetails: [],
  ticketreview: [],
  assignedToMe: [],
  searchInMsgs: "",
  msgSearched: "",
  breakdetails: {},
  checkCase: {},
  assignedTicketsList: [],
  ticketheader: {},
  notificationmessages: {},
};

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState: ticketsInitialState,
  reducers: {
    setConversationHistory: (state, action) => {
      state.conversationMessages = action.payload;
    },
    setConversationNewMessages: (state, action) => {
      state.conversationMessages.push(action.payload);
    },
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    setCurrentUnreadedMessages: (state, action) => {
      state.unreadedMessages = action.payload;
    },
    setNewUnreadedMessages: (state, action) => {
      state.unreadedMessages.push(action.payload);
    },
    setMessageList: (state, action) => {
      state.messageList = action.payload;
    },
    setNewMessageList: (state, action) => {
      state.messageList.push(action.payload);
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setPushFilter: (state, action) => {
      state.filter.push(action.payload);
    },
    setTicketdetails: (state, action) => {
      state.ticketdetails = action.payload;
    },
    setTicketReview: (state, action) => {
      state.ticketreview = action.payload;
    },
    setAssignedToMe: (state, action) => {
      state.assignedToMe = action.payload;
    },
    setSearchInMsgs: (state, action) => {
      state.searchInMsgs = action.payload;
    },
    setMsgSearched: (state, action) => {
      state.msgSearched = action.payload;
    },
    setBreakDetails: (state, action) => {
      state.breakdetails = action.payload;
    },
    setCheckCase: (state, action) => {
      state.checkCase = action.payload;
    },
    setAssignedTicketsList: (state, action) => {
      state.assignedTicketsList = action.payload;
    },
    setTicketHeader: (state, action) => {
      state.ticketheader = action.payload;
    },
    setNotificationMessages: (state, action) => {
      state.notificationmessages = action.payload;
    },
    setRemoveNotificationMessages: (state, action) => {
      state.notificationmessages.splice(action.payload, 1);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setConversationHistory,
  setConversationNewMessages,
  setSelectedConversation,
  setCurrentUnreadedMessages,
  setNewUnreadedMessages,
  setFilter,
  setMessageList,
  setNewMessageList,
  setTicketdetails,
  setTicketReview,
  setAssignedToMe,
  setPushFilter,
  setSearchInMsgs,
  setMsgSearched,
  setBreakDetails,
  setCheckCase,
  setAssignedTicketsList,
  setTicketHeader,
  setNotificationMessages,
  setRemoveNotificationMessages,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
