import React from "react";
import { Box, Grid, TextField, InputAdornment, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  setMsgSearched,
  setSearchInMsgs,
} from "../../../../../../../Services/redux/reducers/ticketsReducer";

const SearchTitle = ({ conversationMessages, scrollToMessage }) => {
  const [filterSearch, setFilterSearch] = React.useState("");
  const [filterNb, setFilterNb] = React.useState(0);
  const dispatch = useDispatch();

  const searchMsg = useSelector((state) => state.ticket.searchInMsgs);

  const handleSearchChange = (e) => {
    setFilterNb(0);
    dispatch(setSearchInMsgs(e));
    const filter = conversationMessages?.filter((msg) =>
      msg?.message?.text?.startsWith(`${e}`)
    );
    setFilterSearch(filter);
    scrollToMessage(filter?.[0]?.message?.messageId);
    dispatch(setMsgSearched(filter?.[0]?.message?.messageId));
  };

  const onSearch = () => {
    setFilterNb(0);
    scrollToMessage(filterSearch?.[0]?.message?.messageId);
    dispatch(setMsgSearched(filterSearch?.[0]?.message?.messageId));
  };

  const onUp = () => {
    setFilterNb(filterNb + 1);
    scrollToMessage(filterSearch?.[filterNb + 1]?.message?.messageId);
    dispatch(setMsgSearched(filterSearch?.[filterNb + 1]?.message?.messageId));
  };

  const onDown = () => {
    setFilterNb(filterNb - 1);
    scrollToMessage(filterSearch?.[filterNb - 1]?.message?.messageId);
    dispatch(setMsgSearched(filterSearch?.[filterNb - 1]?.message?.messageId));
  };

  return (
    <Box>
      <Grid>
        <TextField
          sx={{
            'input[type="text"], input[type="password"]': {
              height: "18px",
            },
            ".css-8tv48o-MuiInputBase-root-MuiOutlinedInput-root": {
              paddingRight: "0px",
            },
            ".css-1vhgub1-MuiInputAdornment-root": { marginLeft: "0px" },
            ".css-1g42net-MuiInputBase-root-MuiOutlinedInput-root": {
              paddingX: "0px",
              paddingY: "2%",
            },
            ".css-19fhcs8": {
              paddingLeft: "0px",
            },
          }}
          autoComplete="off"
          value={searchMsg ? searchMsg : ""}
          onChange={(e) => handleSearchChange(e.target.value)}
          fullWidth
          id="searchmsg"
          label="Search"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={onSearch}
                  sx={{ width: "auto", minWidth: "0px" }}
                >
                  <SearchIcon sx={{ color: "#064987" }} />
                </Button>
              </InputAdornment>
            ),
            startAdornment: searchMsg !== "" && (
              <InputAdornment
                position="end"
                sx={{
                  height: "20px",
                  marginLeft: "0px",
                }}
              >
                <Grid container sx={{ paddingY: "2%" }}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "auto",
                      minWidth: "0px",
                      ".css-185ybjn-MuiButtonBase-root-MuiButton-root": {
                        padding: "0px !important",
                      },
                      textAlign: "center",
                      ".css-i1o4cs": {
                        padding: "0px !important",
                      },
                      ".css-1steuxk.Mui-disabled": {
                        padding: "0px !important",
                      },
                    }}
                  >
                    <Button
                      onClick={onDown}
                      sx={{
                        width: "auto",
                        minWidth: "0px",
                      }}
                      disabled={filterNb === 0 && true}
                    >
                      <ArrowDropUpIcon sx={{ color: "#064987" }} />
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ color: "black", textAlign: "center" }}
                  >
                    {filterSearch?.length === 0 ? filterNb : filterNb + 1} /{" "}
                    {filterSearch?.length}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      width: "auto",
                      minWidth: "0px",
                      ".css-185ybjn-MuiButtonBase-root-MuiButton-root": {
                        padding: "0px !important",
                      },
                      textAlign: "center",
                      ".css-1steuxk": {
                        padding: "0px !important",
                      },
                    }}
                  >
                    <Button
                      onClick={onUp}
                      sx={{
                        width: "auto",
                        minWidth: "0px",
                      }}
                      disabled={
                        filterNb + 1 === filterSearch?.length ||
                        (filterSearch?.length === 0 && true)
                      }
                    >
                      <ArrowDropDownIcon sx={{ color: "#064987" }} />
                    </Button>
                  </Grid>
                </Grid>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Box>
  );
};

export default SearchTitle;
