import { Box, Grid } from "@mui/material";
import React from "react";
import BasicPopover from "../../../components/Popover";
import BreakContent from "./Break";
import MQTT from "../../../../../../Services/MQTT";
import { useSelector } from "react-redux";

const BreakEndShiftComponent = ({ client }) => {
  const userAll = useSelector((state) => state.menu.userAll);

  const onBreakDetails = () => {
    MQTT.mqttPublish(
      {
        topic: "dspenduser/v1/ccagent/tickets",
        qos: 0,
        payload: JSON.stringify({
          request: "agentbreak",
          userid: userAll?.userid,
          agentbreak: {
            type: "breakdetails",
          },
        }),
      },
      client
    );
  };

  return (
    <Box
      sx={{
        marginTop: "2%",
      }}
    >
      <Grid container>
        <Grid item xs={8}></Grid>
        <Grid
          item
          xs={1.5}
          sx={{
            textAlign: "center",
            backgroundColor: "#064987",
            color: "white",
          }}
        >
          <BasicPopover
            buttonTitle="Break"
            onButtonClick={onBreakDetails}
            content={
              <BreakContent client={client} onBreakDetails={onBreakDetails} />
            }
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid
          item
          xs={1.5}
          sx={{
            textAlign: "center",
            backgroundColor: "#064987",
            color: "white",
          }}
        >
          <BasicPopover buttonTitle="Shift" content={<BreakContent />} />
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
    </Box>
  );
};

export default BreakEndShiftComponent;
