import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterAdminsPages from "./Filter";
import allData from "../../../../../Services/Data";
import BodyAdmins from "../Common/Body";
import AlertDialog from "../../components/AlertDialog";
import AddPageContent from "./AddPage";
import { setUser } from "../../../../../Services/redux/reducers/userMenuReducer";
import CustomizedSnackbars from "../../components/NotificationAlert";

const SuperPagesComponent = () => {
  const userAll = useSelector((state) => state.menu.userAll);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState(false);
  const [pagesData, setPagesData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [openDetails, setOpenDetails] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [beSure, setBeSure] = useState(false);
  const [isDelete, setIsDelete] = useState("");
  const dispatch = useDispatch();
  const [snackBar, setSnackBar] = useState({
    open: false,
    message: "",
    type: "",
  });
  const [addPage, setAddPage] = useState({
    text: "",
    desc: "",
    value: "",
  });
  const [editDetails, setEditDetails] = useState({
    lineid: rowData.id,
    text: rowData.text,
    status: rowData.status === "Active" ? "A" : "N",
    desc: rowData.description,
    value: rowData.value,
  });
  const [filter, setFilter] = useState({
    search: "",
  });

  function getData(x, req, type) {
    if (type === "pagesdetails") {
      setIsLoading(true);
    }
    allData
      .getSuperData(
        `${userAll?.userid}`,
        `${userAll?.token}`,
        req,
        type,
        x,
        "admindsp/v1/adminuser"
      )
      .then(
        (response) => {
          if (type === "pagesdetails") {
            setPagesData(response?.data?.pagesdata);
            setIsLoading(false);
          } else if (type === "pageadd") {
            setSnackBar({
              open: true,
              message: "Page Added Successfully!",
              type: "success",
            });
            setBeSure(false);
            getData(filter, "pagesdata", "pagesdetails");
            setOpenAdd(false);
            setAddPage({
              text: "",
              desc: "",
              value: "",
            });
          } else if (type === "pageupdate") {
            setSnackBar({
              open: true,
              message: "Page Updated Successfully!",
              type: "success",
            });
            setBeSure(false);
            getData(filter, "pagesdata", "pagesdetails");
            setOpenDetails(false);
          } else if (type === "pagedelete") {
            setSnackBar({
              open: true,
              message: "Page Deleted Successfully!",
              type: "success",
            });
            setBeSure(false);
            getData(filter, "pagesdata", "pagesdetails");
            setIsDelete("");
            setOpenDetails(false);
          }
        },
        (error) => {
          if (error?.response?.status === 401) {
            dispatch(setUser(false));
          }
          setIsLoading(false);
          setHttpError(error);
          setSnackBar({
            open: true,
            message: "An Error Occured!",
            type: "error",
          });
        }
      );
  }

  var i;
  var _pagesData = [];

  const onButtonClick = (e, row) => {
    setOpenDetails(true);
    setRowData(row);
    //   getData({ jdeid: row.id }, "userdata", "userdetails");
    setEditDetails({
      lineid: row.id,
      text: row.text,
      status: row.status === "Active" ? "A" : "N",
      desc: row.description,
      value: row.value,
    });
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
    setBeSure(false);
  };

  const handleCloseBeSure = () => {
    setBeSure(false);
  };

  const pagesDataColumns = [
    {
      field: "action",
      headerName: "Details",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
            borderRadius: "0px",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
        >
          Details
        </Button>
      ),
    },
    { field: "id", headerName: "ID", width: 100 },
    { field: "type", headerName: "Type", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "text", headerName: "Text", width: 200 },
    { field: "value", headerName: "Value", width: 200 },
    { field: "status", headerName: "Status", width: 200 },
  ];

  for (i = 0; i < pagesData?.length; i++) {
    _pagesData[i] = {
      id: pagesData?.[i]?.AILINEID,
      type: pagesData?.[i]?.AITYPE,
      description: pagesData?.[i]?.AIDESC,
      text: pagesData?.[i]?.AITEXT,
      value: pagesData?.[i]?.AIVALUE,
      status: pagesData?.[i]?.AISTATUS === "A" ? "Active" : "Inactive",
    };
  }

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({
      open: false,
      message: "",
      type: "",
    });
  };

  return (
    <Box>
      <FilterAdminsPages
        getData={(x) => {
          setDetailsData([]);
          setOpenDetails(false);
          getData(x, "pagesdata", "pagesdetails");
        }}
        setOpenAdd={setOpenAdd}
        filter={filter}
        setFilter={setFilter}
      />
      <BodyAdmins
        data={pagesData}
        isLoading={isLoading}
        httpError={httpError}
        adminsDataColumns={pagesDataColumns}
        _adminsData={_pagesData}
        open={openDetails}
        setOpen={setOpenDetails}
        rowData={rowData}
        detailsData={detailsData}
        getData={getData}
        admins={"pages"}
        editDetails={editDetails}
        setEditDetails={setEditDetails}
        setBeSure={setBeSure}
        isDelete={setIsDelete}
      />
      {openAdd && (
        <AlertDialog
          open={openAdd}
          handleClose={handleCloseAdd}
          title={"ADD NEW PAGE"}
          content={
            <AddPageContent
              addPage={addPage}
              setAddPage={setAddPage}
              setBeSure={setBeSure}
            />
          }
          buttonTitle={""}
        />
      )}
      {snackBar.open && (
        <CustomizedSnackbars
          open={snackBar.open}
          handleClose={handleCloseSnackBar}
          severity={snackBar.type}
          message={snackBar.message}
        />
      )}
      {beSure && (
        <AlertDialog
          open={beSure}
          handleClose={handleCloseBeSure}
          title={"Are You Sure?"}
          content={""}
          buttonTitle={"Yes"}
          buttonClick={() =>
            getData(
              (openAdd && addPage) ||
                (openDetails && isDelete === "" && editDetails) ||
                (isDelete !== "" && { lineid: isDelete }),
              "pagesdata",
              (openAdd && "pageadd") ||
                (openDetails && isDelete === "" && "pageupdate") ||
                (isDelete !== "" && "pagedelete")
            )
          }
        />
      )}
    </Box>
  );
};

export default SuperPagesComponent;
