import React from "react";
import AlertDialog from "../../components/AlertDialog";
import "react-phone-input-2/lib/style.css";
import AddContent from "./AddContent";
import { useState } from "react";
import { Box } from "@mui/material";
import CustomizedSnackbars from "../../components/NotificationAlert";
import ReceiptComponent from "./Receipt";
import { useRef } from "react";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import allData from "../../../../../Services/Data";

const AddUserComponent = (props) => {
  const open = props.open;
  const setOpen = props.setOpen;
  const setDetails = props.setDetails;
  const setErrorMessage = props.setErrorMessage;
  const details = props.details;
  const errorMessage = props.errorMessage;
  const userType = props.userType;

  const [openSure, setOpenSure] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState(false);
  const [url, setURL] = useState({
    id: "",
    bill: "",
  });

  const handleClose = () => {
    setOpen(false);
    setDetails({
      title: "",
      fullName: "",
      phoneCode: "",
      phoneNumber: "",
      address1: "",
      address2: "",
      email: "",
      idNumber: "",
      idURL: null,
      billURL: null,
      fees: false,
    });
    setErrorMessage({
      title: "",
      fullName: "",
      phoneCode: "",
      phoneNumber: "",
      address1: "",
      email: "",
      idNumber: "",
      idURL: "",
      billURL: "",
      feesURL: "",
    });
  };
  const handleCloseSure = () => {
    setOpenSure(false);
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
    setOpenErrorSnackBar(false);
  };

  function handleChange(e, key, mess, regex, s, skey) {
    setDetails({
      ...details,
      [key]: e,
      [skey]: s,
    });
    if (regex) {
      setErrorMessage({
        ...errorMessage,
        [key]: "",
        [skey]: "",
      });
    } else {
      setErrorMessage({
        ...errorMessage,
        [key]: "Valid " + mess + " is required!",
      });
    }
  }

  const handleURL = (event, key1, key2) => {
    if (event.target.files && event.target.files[0]) {
      setDetails({
        ...details,
        [key1]: event.target.files[0],
      });
      setURL({
        ...url,
        [key2]: event.target.files[0]?.name,
      });
    }
  };

  const handlingDetails = {
    handleChange,
    handleURL,
  };

  const buttonDisabled =
    errorMessage.title !== "" ||
    errorMessage.fullName !== "" ||
    errorMessage.email !== "" ||
    errorMessage.phoneCode !== "" ||
    errorMessage.phoneNumber !== "" ||
    errorMessage.address1 !== "" ||
    errorMessage.idNumber !== "" ||
    errorMessage.username !== "" ||
    details.title === "" ||
    details.fullName === "" ||
    details.email === "" ||
    details.phoneCode === "" ||
    details.phoneNumber === "" ||
    details.address1 === "" ||
    details.idNumber === "" ||
    details.username === "" ||
    details.idURL === null ||
    details.billURL === null ||
    details.fees === false;

  const reportTemplateRef = useRef(null);

  const exportPDF = () => {
    let element = <ReceiptComponent details={details} />;
    const doc = new jsPDF("p", "pt", "letter");
    doc.setFont("Inter-Regular", "normal");
    doc.html(
      ReactDOMServer.renderToString(
        <div style={{ width: "600px" }}>{element}</div>
      ),
      {
        callback: function (doc) {
          doc.save("sample.pdf");
        },
      }
    );
  };

  const sureClicked = () => {
    let formData = new FormData();
    formData.append("request", "adduser");
    formData.append("token", props.userAll?.token);
    formData.append("userid", props.userAll?.userid);
    formData.append("title", details.title);
    formData.append("phonecode", details.phoneCode);
    formData.append("phoneprefix", "70");
    formData.append("phonenbr", details.phoneNumber);
    formData.append("address1", details.address1);
    formData.append("address2", details.address2);
    formData.append("email", details.email);
    formData.append("fullname", details.fullName);
    formData.append("identity", details.idNumber);
    formData.append("username", details.username);
    formData.append("id", details.idURL, details.idURL.name);
    formData.append("bill", details.billURL, details.billURL.name);

    allData.sendAddUser(formData).then(
      (response) => {
        exportPDF();
        setOpenSnackBar(true);
      },
      (error) => {
        setOpenErrorSnackBar(true);
      }
    );
    setOpen(false);
    setOpenSure(false);
    handleClose();
  };

  return (
    <Box>
      <AlertDialog
        open={open}
        handleClose={handleClose}
        title={"Add New User"}
        content={
          <AddContent
            setDetails={setDetails}
            details={details}
            handlingDetails={handlingDetails}
            url={url}
            errorMessage={errorMessage}
            userType={userType}
          />
        }
        buttonTitle={"ADD"}
        details={details}
        buttonClick={() => setOpenSure(true)}
        buttonDisabled={buttonDisabled}
      />
      <AlertDialog
        open={openSure}
        handleClose={handleCloseSure}
        title={"Are You Sure?"}
        content={
          <div ref={reportTemplateRef}>
            <ReceiptComponent details={details} />
          </div>
        }
        buttonTitle={"Yes"}
        buttonClick={sureClicked}
      />
      <CustomizedSnackbars
        open={openSnackBar}
        handleClose={handleCloseSnackBar}
        severity={"success"}
        message={"User added successfully!"}
      />
      <CustomizedSnackbars
        open={openErrorSnackBar}
        handleClose={handleCloseSnackBar}
        severity={"error"}
        message={"Something went wrong!"}
      />
    </Box>
  );
};

export default AddUserComponent;
