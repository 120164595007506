import * as React from "react";
import { closeSnackbar, useSnackbar } from "notistack";
import MessageNotification from "../../chatTickets/ticketList/messageNotification";
import CloseIcon from "@mui/icons-material/Close";

export default function SimpleSnackbar({ msg, userAll, client }) {
  const { enqueueSnackbar } = useSnackbar();

  const action = (snackbarId) => (
    <>
      <button
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
        }}
      >
        <CloseIcon />
      </button>
    </>
  );

  React.useEffect(() => {
    enqueueSnackbar(
      <MessageNotification message={msg} userAll={userAll} client={client} />,
      {
        action,
      }
    );
  }, [msg]);

  return <React.Fragment></React.Fragment>;
}
