import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import "./forgetPassword.css";

const ForgetPasswordComponent = () => {
  // foregt password component interface
  return (
    <div className="back">
      <Grid container>
        <Grid item xs={12} className="imgGrid">
          <img alt="" src="/reglogo.png" />
        </Grid>
        <Grid container item xs={12} marginTop={"-140px"}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} sx={{ width: "100%" }}>
            <TextField fullWidth id="email" label="Email" />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Grid container item xs={12} marginTop={"-230px"}>
          <Grid item xs={5}></Grid>
          <Grid item xs={2} sx={{ width: "100%" }}>
            <Button className="send-button">Send Password</Button>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgetPasswordComponent;
