import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import ChatShell from "../containers/shell/ChatShell";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";

import MQTT from "../../../../../Services/MQTT";
import { MQTT_URL } from "../../../../../Services/GlobalVar/commonVar";
import useMqttConnect from "../../../../../Services/MQTT/MQTTConnect";

const ChatComponent = ({ onConversationItem, client, setIsChatting }) => {
  const dispatch = useDispatch();
  const userAll = useSelector((state) => state.menu.userAll);

  // Get message list (tickets)
  const messageList = useSelector((state) => state.ticket.messageList);
  const messageListRef = useRef();
  messageListRef.current = messageList;

  // Get Selected Conversation (selected ticket)
  const selectedConversation = useSelector(
    (state) => state.ticket.selectedConversation
  );
  const selectedConversationRef = useRef();
  selectedConversationRef.current = selectedConversation;

  // Get Unreaded Messages (unreaded messages for each ticket)
  const unreadedMessages = useSelector(
    (state) => state.ticket.unreadedMessages
  );
  const unreadedMessagesRef = useRef();
  unreadedMessagesRef.current = unreadedMessages;

  // Get Conversation messages
  const conversationMessages = useSelector(
    (state) => state.ticket.conversationMessages
  );
  const conversationMessagesRef = useRef();
  conversationMessagesRef.current = conversationMessages;

  // Get ticket details
  const ticketdetails = useSelector((state) => state.ticket.ticketdetails);
  const ticketdetailsRef = useRef();
  ticketdetailsRef.current = ticketdetails;

  // Get tickets assigned to me
  const assignedToMe = useSelector((state) => state.ticket.assignedToMe);
  const assignedToMeRef = useRef();
  assignedToMeRef.current = assignedToMe;

  const mqttConnect = useMqttConnect();

  // Connecting & Subscribe on first open page
  useEffect(() => {
    // if (client) {
    //   client.setMaxListeners(100);
    //   client.on("connect", () => {
    //     MQTT.mqttSub(`${userAll?.userid}/dspenduser/v1/tickets`, client);
    //     MQTT.mqttSub(`dspenduser/v1/tickets/ccagent`, client);
    //     // ticketlist();
    //   });
    //   client.on("error", (err) => {
    //     console.error("Connection error: ", err);
    //     client.end();
    //   });
    //   client.on("reconnect", () => {
    //     console.log("Reconnecting");
    //   });
    // } else {
    //   MQTT.mqttConnect(
    //     MQTT_URL,
    //     {
    //       clientId: `CCAGENT_${userAll?.userid}_${uuid()}`,
    //       username: "test",
    //       password: userAll?.token,
    //     },
    //     setClient
    //   );
    // }
    MQTT.MqttConnection(client, userAll, null, null, mqttConnect);
    return () => {};
  }, [client, dispatch]);

  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ChatShell
          userAll={userAll}
          client={client}
          messageList={messageList}
          selectedConversation={selectedConversationRef.current}
          joined={assignedToMeRef.current}
          onConversationItem={onConversationItem}
          setIsChatting={setIsChatting}
        />
      </Box>
    </React.Fragment>
  );
};

export default ChatComponent;
