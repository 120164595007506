import * as React from "react";
import { Box, IconButton, Grid, Menu, MenuItem, Fade } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import Header from "../header";
import { useDispatch } from "react-redux";
import { setUser } from "../../../../Services/redux/reducers/userMenuReducer";

const Topbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Logout = () => {
    dispatch(setUser(false));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleChange = (event) => {
  //   setdropDownValue(event.target.value);
  //   navigate("/main");
  // };
  // const [dropDownValue, setdropDownValue] = useState("Diagram");

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      backgroundColor="#064987"
      alignItems="center"
      padding="0.2%"
    >
      <Grid container>
        <Grid item xs={3} md={1} sx={{ height: "100%" }}>
          {/* <img alt="" className={styles.logo} src="/grayLogo.jpg" /> */}
        </Grid>
        <Grid
          item
          xs={8}
          md={8.8}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Header />
        </Grid>
        <Grid
          item
          xs={1}
          md={2.2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            margin="0.1%"
            width="100%"
            flexDirection="row"
            display="flex"
            justifyContent="right"
          >
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={Logout}>Logout</MenuItem>
            </Menu>
            <IconButton onClick={handleClick}>
              <PersonOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Topbar;
