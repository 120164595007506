import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import { TitlesJSON } from "../../subUsers/JSONFiles/titles";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddContent = (props) => {
  const handlingDetails = props.handlingDetails;
  const details = props.details;
  const setDetails = props.setDetails;
  const errorMessage = props.errorMessage;
  const url = props.url;
  const userType = props.userType;
  const usernameRegex = /^\S*$/;
  const fullnameRegex = /^[A-Za-z]+$/;
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const idRegex = /^[0-9\b]+$/;

  return (
    <Grid
      container
      sx={{
        marginTop: "1%",
        'input[type="text"], input[type="password"],.css-1dxv3pf-MuiGrid-root, .react-tel-input, .flag-dropdown,.css-1dxv3pf-MuiGrid-root, .react-tel-input, .form-control, label[data-shrink=false]+.MuiInputBase-formControl, .css-v3zyv7-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
          {
            backgroundColor: "#e5e5e5",
            borderRadius: "8px",
          },
        ".css-112ysrj-MuiButtonBase-root-MuiCheckbox-root.Mui-disabled": {
          color: "#757474",
        },
      }}
    >
      <Grid container>
        <Grid item xs={3.5}>
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            <InputLabel>Title</InputLabel>
            <Select
              label="Title"
              width="100%"
              value={details.title}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "title",
                  "Title",
                  e.target.value !== ""
                )
              }
            >
              {TitlesJSON.map((data) => (
                <MenuItem key={data.value} value={data.value}>
                  {data.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={8}>
          <TextField
            fullWidth
            id="fullname"
            label="Full Name"
            helperText={errorMessage.fullName}
            value={details.fullName}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "fullName",
                "Full Name",
                e.target.value !== "" || fullnameRegex.test(e.target.value)
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container marginY={"2%"}>
        <Grid
          item
          xs={5.75}
          sx={{
            ".react-tel-input .form-control": {
              width: "100%",
              height: "60px",
              border: "1px solid black",
            },
            ".react-tel-input .flag-dropdown": {
              border: "1px solid black !important",
            },
          }}
        >
          <PhoneInput
            country={details.phoneCode}
            value={details.phoneNumber}
            onChange={(e, f) => {
              handlingDetails.handleChange(
                e,
                "phoneNumber",
                "Phone Number",
                e !== "",
                f.dialCode,
                "phoneCode"
              );
            }}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid item xs={5.75}>
          <TextField
            fullWidth
            id="email"
            helperText={errorMessage.email}
            label="Email"
            value={details.email}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "email",
                "Email",
                e.target.value !== "" && emailRegex.test(e.target.value)
              )
            }
          />
        </Grid>
      </Grid>
      {userType === "customer" && (
        <Grid container>
          <Grid item xs={5.75}>
            <TextField
              fullWidth
              id="idnumber"
              label="ID Number"
              helperText={errorMessage.idNumber}
              value={details.idNumber}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "idNumber",
                  "ID Number",
                  (e.target.value !== "" || idRegex.test(e.target.value)) &&
                    (e.target.value?.length === 14 || e.target.value === "")
                )
              }
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={5.75}>
            <TextField
              fullWidth
              id="username"
              label="Username"
              helperText={errorMessage.username}
              value={details.username}
              onChange={(e) =>
                handlingDetails.handleChange(
                  e.target.value,
                  "username",
                  "Username",
                  e.target.value !== "" && usernameRegex.test(e.target.value)
                )
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid container marginY={"2%"}>
        <Grid item xs={userType === "customer" ? 5.75 : 11 / 3}>
          <TextField
            fullWidth
            id="address1"
            label="Address 1"
            helperText={errorMessage.address1}
            value={details.address1}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "address1",
                "Address 1",
                e.target.value !== ""
              )
            }
          />
        </Grid>
        <Grid item xs={userType === "customer" ? 0.5 : 1 / 2}></Grid>
        <Grid item xs={userType === "customer" ? 5.75 : 11 / 3}>
          <TextField
            fullWidth
            id="address2"
            label="Address 2 (Optional)"
            value={details.address2}
            onChange={(e) =>
              handlingDetails.handleChange(
                e.target.value,
                "address2",
                "Address 2",
                e.target.value !== ""
              )
            }
          />
        </Grid>
        {userType === "employee" && (
          <Grid item container xs={11 / 3 + 1 / 2}>
            <Grid item xs={1.5}></Grid>
            <Grid item xs={10.5}>
              <TextField
                fullWidth
                id="idnumber"
                label="ID Number"
                helperText={errorMessage.idNumber}
                value={details.idNumber}
                onChange={(e) =>
                  handlingDetails.handleChange(
                    e.target.value,
                    "idNumber",
                    "ID Number",
                    (e.target.value !== "" || idRegex.test(e.target.value)) &&
                      (e.target.value?.length === 14 || e.target.value === "")
                  )
                }
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {props.type !== "edit" && userType === "customer" && (
        <Grid width={"100%"}>
          <Grid
            sx={{
              color: "black",
              fontWeight: "bold",
              fontSize: "1em",
              textDecoration: "underline",
            }}
          >
            Attachments
          </Grid>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            color={"black"}
          >
            <Grid item xs={2}>
              <Checkbox disabled checked={details.idURL !== null} /> ID
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={3}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onChange={(e) => handlingDetails.handleURL(e, "idURL", "id")}
              >
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </Grid>
            {details.idURL !== null && (
              <Grid item xs={6.5} sx={{ color: "green" }}>
                {url.id}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            color={"black"}
          >
            <Grid item xs={2}>
              <Checkbox disabled checked={details.billURL !== null} /> Bill
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={3}>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                onChange={(e) =>
                  handlingDetails.handleURL(e, "billURL", "bill")
                }
              >
                Upload file
                <VisuallyHiddenInput type="file" />
              </Button>
            </Grid>
            {details.billURL !== null && (
              <Grid item xs={6.5} sx={{ color: "green" }}>
                {url.bill}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            sx={{ display: "flex", alignItems: "center" }}
            color={"black"}
          >
            <Grid
              item
              xs={6}
              sx={{
                ".css-112ysrj-MuiButtonBase-root-MuiCheckbox-root": {
                  color: "black",
                },
              }}
            >
              <Checkbox
                onChange={(e) =>
                  setDetails({ ...details, fees: !details.fees })
                }
              />{" "}
              Fees Paid
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AddContent;
