import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ECharts from "../../../components/ECharts";
import { useSelector } from "react-redux";
import AlertDialog from "../../../components/AlertDialog";
import LocationMap from "../../../components/LocationMap";
import moment from "moment";
import Table from "../../../table/Table";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

const BodyHRComponent = () => {
  const attendanceDetails = useSelector((state) => state.hr.attendanceDetails);
  const attendanceData = useSelector((state) => state.hr.attendanceData);
  const attendanceBreakData = useSelector(
    (state) => state.hr.attendanceBreakData
  );
  const attendanceDaysData = useSelector(
    (state) => state.hr.attendanceDaysData
  );
  const [openMap, setOpenMap] = useState({
    open: false,
    data: {},
  });
  const [shiftTable, setShiftTable] = useState(false);
  const [breakTable, setBreakTable] = useState(false);

  const onButtonClick = (e, params) => {
    setOpenMap({
      open: true,
      data: {
        lon: params?.lon,
        lat: params?.lat,
      },
    });
  };

  const attendanceDetailsColumns = [
    {
      field: "action",
      headerName: "Location",
      renderCell: (params) => (
        <Button
          sx={{
            backgroundColor: "#11c098",
            width: "200%",
            height: "100%",
            marginLeft: "-13%",
            marginRight: "-13%",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            color: "white",
          }}
          onClick={(e) => onButtonClick(e, params.row)}
        >
          Map
        </Button>
      ),
    },
    { field: "id1", headerName: "ID", width: 100 },
    { field: "id2", headerName: "ID 2", width: 100 },
    { field: "fullname", headerName: "Full Name", width: 150 },
    {
      field: "checktype",
      headerName: "Check Type",
      width: 100,
      renderCell: (params) => (
        <Grid>
          {params?.row?.checktype === "checkin" ? (
            <div style={{ color: "green" }}>Check In</div>
          ) : (
            <div style={{ color: "red" }}>Check Out</div>
          )}
        </Grid>
      ),
    },
    { field: "phonenbr", headerName: "Phone Number", width: 100 },
    { field: "budesc", headerName: "Business Unit", width: 100 },
    { field: "locdesc", headerName: "Location", width: 100 },
    { field: "dptdesc", headerName: "Department", width: 150 },
    { field: "postdesc", headerName: "Position", width: 100 },
    { field: "datetime", headerName: "Date & Time", width: 200 },
    { field: "date", headerName: "Date", width: 150 },
    { field: "time", headerName: "Time", width: 100 },
  ];

  var i;
  var _attendanceDetails = [];

  for (i = 0; i < attendanceDetails?.[0]?.length; i++) {
    _attendanceDetails[i] = {
      id: attendanceDetails?.[0]?.[i].BGLINEID,
      id1: attendanceDetails?.[0]?.[i].ABLINEID,
      id2: attendanceDetails?.[0]?.[i].ABID2,
      fullname: attendanceDetails?.[0]?.[i].ABFULLNAME,
      phonenbr: attendanceDetails?.[0]?.[i].ABPHONENBR,
      budesc: attendanceDetails?.[0]?.[i].BUDESC,
      locdesc: attendanceDetails?.[0]?.[i].LOCDESC,
      dptdesc: attendanceDetails?.[0]?.[i].DPTDESC,
      postdesc: attendanceDetails?.[0]?.[i].POSTDESC,
      checktype: attendanceDetails?.[0]?.[i].BGCHECKTYPE,
      datetime: moment(attendanceDetails?.[0]?.[i].BGDATETIME)?.format(
        "YYYY-MM-DD hh:mm:ss a"
      ),
      date: moment(attendanceDetails?.[0]?.[i].BGDATE)?.format("YYYY-MM-DD"),
      time: attendanceDetails?.[0]?.[i].BGTIME,
      lat: attendanceDetails?.[0]?.[i].BGLAT,
      lon: attendanceDetails?.[0]?.[i].BGLON,
    };
  }

  const attendanceBreakColumns = [
    { field: "id1", headerName: "ID", width: 100 },
    { field: "id2", headerName: "ID 2", width: 150 },
    { field: "fullname", headerName: "Full Name", width: 200 },
    { field: "phonenbr", headerName: "Phone Number", width: 200 },
    { field: "breakhrs", headerName: "Break Hours", width: 150 },
  ];

  var j;
  var _attendanceBreakData = [];

  for (j = 0; j < attendanceDetails?.[3]?.length; j++) {
    _attendanceBreakData[j] = {
      id: Math.random(),
      id1: attendanceDetails?.[3]?.[j].ABLINEID,
      id2: attendanceDetails?.[3]?.[j].ABID2,
      fullname: attendanceDetails?.[3]?.[j].ABFULLNAME,
      phonenbr: attendanceDetails?.[3]?.[j].ABPHONENBR,
      breakhrs:
        attendanceDetails?.[3]?.[j].BREAKHOURS >= 0 &&
        attendanceDetails?.[3]?.[j].BREAKHOURS < 24
          ? attendanceDetails?.[3]?.[j].BREAKHOURS
          : "0.0000",
    };
  }

  const attendanceShiftColumns = [
    { field: "id1", headerName: "ID", width: 100 },
    { field: "id2", headerName: "ID 2", width: 150 },
    { field: "fullname", headerName: "Full Name", width: 200 },
    { field: "phonenbr", headerName: "Phone Number", width: 200 },
    { field: "shifthrs", headerName: "Shift Hours", width: 150 },
  ];

  var k;
  var _attendanceShiftData = [];

  for (k = 0; k < attendanceDetails?.[1]?.length; k++) {
    _attendanceShiftData[k] = {
      id: Math.random(),
      id1: attendanceDetails?.[1]?.[k].ABLINEID,
      id2: attendanceDetails?.[1]?.[k].ABID2,
      fullname: attendanceDetails?.[1]?.[k].ABFULLNAME,
      phonenbr: attendanceDetails?.[1]?.[k].ABPHONENBR,
      shifthrs:
        attendanceDetails?.[1]?.[k].SHIFTHOURS >= 0 &&
        attendanceDetails?.[1]?.[k].SHIFTHOURS < 24
          ? attendanceDetails?.[1]?.[k].SHIFTHOURS
          : "0.0000",
    };
  }

  const handleClose = () => {
    setOpenMap({
      open: false,
      data: {},
    });
  };

  const alertSx = {
    backgroundColor: "#bfbfbf !important",
    minWidth: "auto !important",
    maxWidth: "auto !important",
    width: "100%",
    height: "50%",
  };

  const sx = {
    display: "flex",
    justifyContent: "center",
  };

  const center = {
    lat: Number(openMap?.data?.lat),
    lng: Number(openMap?.data?.lon),
  };

  return (
    <Box>
      {attendanceDetails?.length > 0 && (
        <Grid sx={{ margin: "3% 5%" }}>
          <Table
            columns={attendanceDetailsColumns}
            _newData={_attendanceDetails}
          />
          <Grid
            container
            sx={{ marginY: "5%", padding: "3%" }}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          >
            <Grid item xs={11}>
              {!shiftTable ? (
                <ECharts
                  loading={attendanceData.loading}
                  options={attendanceData.data}
                  // getSpecificData={getSpecificData}
                  // type="status"
                />
              ) : (
                <Grid sx={{ fontWeight: "bold" }}>
                  <Grid sx={{ marginBottom: "2%", fontSize: "1.2rem" }}>
                    Attendance Hours Per Shift
                  </Grid>
                  <Table
                    columns={attendanceShiftColumns}
                    _newData={_attendanceShiftData}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={() => setShiftTable(!shiftTable)}
                sx={{ height: "15%" }}
              >
                {!shiftTable ? (
                  <ToggleOffIcon
                    sx={{ color: "#064987", width: "100%", height: "100%" }}
                  />
                ) : (
                  <ToggleOnIcon
                    sx={{ color: "#064987", width: "100%", height: "100%" }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ marginY: "5%", padding: "3%" }}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          >
            <Grid item xs={11}>
              {!breakTable ? (
                <ECharts
                  loading={attendanceBreakData.loading}
                  options={attendanceBreakData.data}
                  // getSpecificData={getSpecificData}
                  // type="status"
                />
              ) : (
                <Grid sx={{ fontWeight: "bold" }}>
                  <Grid sx={{ marginBottom: "2%", fontSize: "1.2rem" }}>
                    Attendance Hours Per Break
                  </Grid>
                  <Table
                    columns={attendanceBreakColumns}
                    _newData={_attendanceBreakData}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={1}>
              <Button
                onClick={() => setBreakTable(!breakTable)}
                sx={{ height: "15%" }}
              >
                {!breakTable ? (
                  <ToggleOffIcon
                    sx={{ color: "#064987", width: "100%", height: "100%" }}
                  />
                ) : (
                  <ToggleOnIcon
                    sx={{ color: "#064987", width: "100%", height: "100%" }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{ marginY: "5%", padding: "3%" }}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          >
            <ECharts
              loading={attendanceDaysData.loading}
              options={attendanceDaysData.data}
              // getSpecificData={getSpecificData}
              // type="status"
            />
          </Grid>
        </Grid>
      )}
      {openMap?.open && (
        <AlertDialog
          open={openMap?.open}
          title={"Client Location"}
          sx={sx}
          alertSx={alertSx}
          handleClose={handleClose}
          buttonTitle={""}
          content={
            <Grid
              container
              sx={{ cursor: "pointer", height: "300px", marginBottom: "4%" }}
            >
              <LocationMap center={center} height={"100%"} />
            </Grid>
          }
        />
      )}
    </Box>
  );
};

export default BodyHRComponent;
