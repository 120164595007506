import { Grid } from "@mui/material";
import React from "react";

const CheckAttachCaseDetails = ({ checkCase }) => {
  const caseInfo = [
    {
      desciption: "Status",
      value: checkCase?.ZACLST,
    },
    {
      desciption: "Address 1",
      value: checkCase?.ZAADD1,
    },
    {
      desciption: "Address 2",
      value: checkCase?.ZAADD2,
    },
    {
      desciption: "Issue",
      value: checkCase?.ZAISSUE,
    },
  ];
  return (
    <Grid container sx={{ marginTop: "3%" }}>
      {caseInfo?.map((info, i) => {
        return (
          <Grid container key={i}>
            <Grid item xs={3.8} sx={{ fontWeight: "600" }}>
              {info?.desciption}
            </Grid>
            <Grid item xs={0.4}>
              :
            </Grid>
            <Grid item xs={7.8} sx={{ color: "#064987" }}>
              {info?.value}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CheckAttachCaseDetails;
